import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import ReactDOM from 'react-dom'

const propTypes = {
	google: PropTypes.object.isRequired,
	map: PropTypes.object.isRequired,
	position: PropTypes.array,
	children: PropTypes.object,
	mapPaneName: PropTypes.string,
	getPixelPositionOffset: PropTypes.func,
	offset: PropTypes.object
}

const domHelper = {
	createContainerElement: function () {
		const containerElement = document.createElement('div')
		containerElement.style.position = 'absolute'
		containerElement.className = 'stopmark'
		return containerElement
	}
}

const statics = {
	FLOAT_PANE: "floatPane",
	MAP_PANE: "mapPane",
	MARKER_LAYER: "markerLayer",
	OVERLAY_LAYER: "overlayLayer",
	OVERLAY_MOUSE_TARGET: "overlayMouseTarget"
}

const defaultProps = {
	mapPaneName: statics.OVERLAY_MOUSE_TARGET,
	getPixelPositionOffset: function () { },
	offset: {
		x: 0,
		y: 0
	}
}

const getLayoutStylesByPosition = function (mapCanvasProjection, offset, props) {

	const {
    position,
		google
  } = props

	const point = mapCanvasProjection.fromLatLngToDivPixel(new props.google.maps.LatLng(position[1], position[0]))

	if (point) {
		const x = point.x, y = point.y
		return {
			left: x + offset.x + "px",
			top: y + offset.y + "px"
		}
	}

	return {
		left: "-9999px",
		top: "-9999px"
	}
}

const getLayoutStylesByBounds = function (mapCanvasProjection, offset, props) {

	const {
    google,
		bounds
  } = props

	let boundingBox = new google.maps.LatLngBounds()
	const latLngs = bounds.map(item => ({ lng: item[0], lat: item[1] }))
	latLngs.forEach(item => boundingBox = boundingBox.extend(item))

	let ne = mapCanvasProjection.fromLatLngToDivPixel(boundingBox.getNorthEast())
	let sw = mapCanvasProjection.fromLatLngToDivPixel(boundingBox.getSouthWest())

	if (ne && sw) {
		return {
			left: sw.x + offset.x + "px",
			top: ne.y + offset.y + "px",
			width: ne.x - sw.x - offset.x + "px",
			height: sw.y - ne.y - offset.y + "px"
		}
	}
	return {
		left: "-9999px",
		top: "-9999px"
	}
}

const getLayoutStyles = function (mapCanvasProjection, offset, props) {

	if (props.bounds)
		return getLayoutStylesByBounds(mapCanvasProjection, offset, props)
	if (props.position)
		return getLayoutStylesByPosition(mapCanvasProjection, offset, props)

	throw new Error('No bounds have been supplied')
}

const applyStyles = function (containerElement, layoutStyles) {
	containerElement.style.left = layoutStyles.left
	containerElement.style.top = layoutStyles.top
	containerElement.style.width = layoutStyles.width
	containerElement.style.height = layoutStyles.height
}

class MapOverlay extends Component {

	constructor(props) {
		super(props)

		this.onAdd = this.onAdd.bind(this)
		this.draw = this.draw.bind(this)
		this.onRemove = this.onRemove.bind(this)

		const {
      google,
			map
    } = this.props

		const overlayView = new google.maps.OverlayView()
		overlayView.onAdd = this.onAdd
		overlayView.draw = this.draw
		overlayView.onRemove = this.onRemove
		overlayView.setMap(map)
		this.$overlayView = overlayView
	}

	componentDidUpdate() {
		if (!this.$containerElement) {
			return
		}
		this.draw()
	}

	componentWillUnmount() {

		const overlayView = this.$overlayView
		if (overlayView) {
			overlayView.setMap(null)
			overlayView.onAdd = null
			overlayView.draw = null
			overlayView.onRemove = null
		}
	}

	onAdd() {
		this.$containerElement = domHelper.createContainerElement()
	}

	draw() {

		const {
      children,
			mapPaneName,
			google,
			getPixelPositionOffset,
			offset
    } = this.props

		const overlayView = this.$overlayView
		const mapPanes = overlayView.getPanes()
		const mapCanvasProjection = overlayView.getProjection()
		const containerElement = this.$containerElement

		const props = {
			children,
			getPixelPositionOffset: getPixelPositionOffset
		}

		mapPanes[mapPaneName].appendChild(containerElement)
		const child = React.Children.only(children)
		ReactDOM.render(child, containerElement, () => {
			const elementOffset = Object.assign({}, {
				x: 0,
				y: 0
			}, offset)
			const layoutStyles = getLayoutStyles(mapCanvasProjection, elementOffset, this.props)
			applyStyles(containerElement, layoutStyles)
		})
	}

	onRemove() {
		this.$containerElement && this.$containerElement.parentNode.removeChild(this.$containerElement)
		this.$containerElement = null
	}

	render() {
		return null
	}
}

MapOverlay.defaultProps = defaultProps
MapOverlay.propTypes = propTypes

export default MapOverlay