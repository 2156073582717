import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { emit, forOwn, some, find, get } from 'lodash'
import pubsub from 'sweet-pubsub'

const propTypes = {
    google: PropTypes.object.isRequired,
    map: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    showAddressSearch: PropTypes.bool,
    searchOptions: PropTypes.object,
    showMarker: PropTypes.bool.isRequired,
}

const defaultProps = {
    style: {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2
    },
    searchOptions: {
        componentRestrictions: { country: 'za' }
    }
}


// const eventMap = {
//     onClick: "click",
//     onMouseOut: "mouseout",
//     onMouseOver: "mouseover",
//     onContextMenu: "rightclick"
// }

class MapAddressSearch extends Component {



    componentDidMount() {
        const { showAddressSearch } = this.props
        if (showAddressSearch)
            this.renderSearchBox()
    }


    componentDidUpdate = (prevProps) => {
        if (prevProps.showAddressSearch !== this.props.showAddressSearch) {
            if (this.props.showAddressSearch) {
                this.renderSearchBox()
            } else {
                this.removeSearchBox()
            }
        }
    }

    componentWillUnmount = () => {

    }

    boxPosition = this.props.google.maps.ControlPosition.TOP_LEFT

    removeSearchBox = () => {
        const { map } = this.props
        const mapControls = map.controls[this.boxPosition].getArray()
        const control = find(mapControls, o => o.className === 'controls map_addressSearchInput')
        const markers = get(this.state, 'markers',[])
        if (control) {
            let index = mapControls.indexOf(control)
            markers.map(m => m.setMap(null))
            if (index > -1)
                map.controls[this.boxPosition].removeAt(index)
        }
    }

    renderSearchBox = () => {
        const { google, map, showAddressSearch, searchOptions, showMarker } = this.props
        // Create the search box and link it to the UI element.
        let input = document.createElement('input')
        input.className = 'controls map_addressSearchInput'
        input.type = 'text'
        input.placeholder = 'Search Places'

        let searchBox = new google.maps.places.Autocomplete(input, searchOptions)//SearchBox
        map.controls[this.boxPosition].push(input)

        let markers = []

        // Bias the SearchBox results towards current map's viewport.
        input.addEventListener('bounds_changed', () => {
            searchBox.setBounds(map.getBounds())
        })

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('place_changed', () => {// SearchBox uses places_changed
            let place = searchBox.getPlace()

            // if (places.length === 0) {
            //     return
            // }

            // Clear out the old markers.
            markers.forEach(function (marker) {
                marker.setMap(null)
            });
            markers = []

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds()//let bounds = new google.maps.LatLngBounds(); no, use existing
            // places.forEach(function (place) {
            if (!place.geometry) {
                return
            }
            let icon = {
                url: place.icon,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25)
            }

            // Create a marker for each place.
            showMarker && 
            markers.push(new google.maps.Marker({
                map: map,
                icon: icon,
                title: place.name,
                position: place.geometry.location
            }))
            // instead of dropping a marker, drop a direction point
            pubsub.emit('directionsPointAdded',[place.geometry.location.lng(), place.geometry.location.lat()] )

            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport)
            } else {
                bounds.extend(place.geometry.location)
            }
            // });
            map.fitBounds(bounds)

            this.setState({ markers })
        })
    }

    render() {
        return null
    }

}

MapAddressSearch.propTypes = propTypes

MapAddressSearch.defaultProps = defaultProps

export default MapAddressSearch