/*
Map popup menu item on right-click
*/

import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

class MapDirectionsMenuItem extends Component {

	static propTypes = {
		position: PropTypes.array,
		onClick: PropTypes.func.isRequired
	}

	onClick = () => {
		this.props.onClick(this.props.position)
	}

	render() {
		return (<div className="map_context-menu_button" key={'directions'} onClick={this.onClick}>Drop Point</div>)
	}
}

export default MapDirectionsMenuItem