import {
  sortBy,
  get,
  transform,
  camelCase,
  snakeCase,
  isObject,
  every,
  isArray,
  isNumber,
  map,
  defaultTo,
  isPlainObject,
  cloneDeep,
  isNil,
  isEmpty,
  has
} from 'lodash'

export const sortByAlphabet = (arr, path) =>
  sortBy(arr, item => (path ? get(item, path) : item).toLowerCase())

export const loadScript = (url, id, then) => {
  if (document.getElementById(id)) return
  const script = document.createElement('script')
  if (then) script.onload = then
  script.async = true
  script.src = url
  script.id = id
  document.getElementsByTagName('head')[0].appendChild(script)
}

export const transformPositionValues = (obj, keys) => {
  if (isNil(obj) || !isObject(obj)) return obj

  const initialValue = isArray(obj) ? [] : {}

  return transform(
    obj,
    (res, value, key) => {
      if (keys.find(k => k === key) && isTrackmaticLngLat(value)) {
        res[key] = toGoogleLatLng(value)
      } else if (isPlainObject(value)) {
        res[key] = transformPositionValues(value, keys)
      } else if (isArray(value)) {
        res[key] = []

        value.forEach((x, i) => {
          res[key][i] = transformPositionValues(x, keys)
        })
      } else {
        res[key] = value
      }
    },
    initialValue
  )
}

export const mapFactorsOf = (array, mapper, factor) => {
  const ret = []
  array.forEach((item, index) => {
    if (index % factor === 0) ret.push(mapper(item, ret.length))
  })
  return ret
}

export const isGoogleLatLngLiteralArray = arr => {
  return isArray(arr) && every(arr, isGoogleLatLngLiteral)
}

export const isGoogleLatLngLiteral = obj => {
  return isObject(obj) && has(obj, 'lat') && has(obj, 'lng') && isNumber(obj.lat + obj.lng)
}

export const isValidLatLngCoordinate = obj => {
  return isGoogleLatLngLiteral(obj) && obj.lat !== 0 && obj.lng !== 0
}

export const toGoogleLatLng = coords => {
  if (!coords || coords.length !== 2) return null
  return { lat: coords[1], lng: coords[0] }
}
export const toTrackmaticLngLat = ({ lat, lng }) => [lng, lat]

export const isTrackmaticLngLat = obj => {
  return isArray(obj) && obj.length === 2 && obj[0] !== 0 && obj[1] !== 0
}

// if path is an array try get values on the object until one is found otherwise return the default value
export const getDefault = (obj, path, defaultValue) => {
  if (isArray(path)) {
    let temp
    while (path.length && !temp) {
      temp = get(obj, path.shift(), undefined)
    }
    return cloneDeep(temp || defaultValue)
  }
  return cloneDeep(defaultTo(get(obj, path, undefined), defaultValue))
}

export const objectToArray = (obj, sortField) => {
  if (isNil(obj)) return []

  if (sortField) return sortBy(Object.values(obj), sortField)
  else return Object.values(obj)
}

export const arrayToObject = (arr, indexBy, transformFunc) => {
  if (isNil(arr) || isEmpty(arr) || !isArray(arr) || isEmpty(indexBy)) return {}

  return arr.reduce((res, curr, i) => {
    const index = curr[indexBy]
    if (index) {
      res[index] = transform ? transformFunc(curr) : curr
      return res
    }
    console.error(new Error(`${indexBy} does not exist in array at position [${i}]`)) //eslint-disable-line
    return res
  }, {})
}

export const transformObjectKeys = (obj, transformFunc) => {
  if (!isObject(obj) || isArray(obj)) return obj
  return transform(
    obj,
    (result, value, key) => {
      const camelKey = transformFunc(key)
      result[camelKey] = value
      if (isArray(result[camelKey])) {
        result[camelKey] = map(result[camelKey], x => transformObjectKeys(x, transformFunc))
      } else if (isObject(result[camelKey])) {
        result[camelKey] = transformObjectKeys(result[camelKey], transformFunc)
      }
    },
    {}
  )
}

export const toCamelCaseKeys = obj => transformObjectKeys(obj, camelCase)
export const toSnakeCaseKeys = obj => transformObjectKeys(obj, snakeCase)

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
