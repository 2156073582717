import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'shared/higher-order-components/Modal'
import Intl from 'shared/higher-order-components/Intl'
import { Button } from 'shared/components'
import cx from 'classnames'
import st from './WarningDialog.module.scss'

const propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  copy: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  context: PropTypes.any,
  render: PropTypes.any,
  heading: PropTypes.string
}

class WarningDialog extends Component {
  constructor(props) {
    super(props)
    this.confirm = this.confirm.bind(this)
  }

  confirm() {
    const { onConfirm, closeModal, context } = this.props
    onConfirm(context)
    closeModal()
  }

  render() {
    const { message, heading, closeModal, copy, render } = this.props
    return (
      <>
        <i className={cx('i-exclamation-triangle', st.warningDialog_icon)} />
        <div className={st.warningDialog_title}>{copy.get('warning')}</div>
        {heading && <h3 className={st.warningDialog_heading}>{heading}</h3>}
        <div className={st.warningDialog_message}>{message}</div>
        {render && <div className={cx('row', st.warningDialog_message)}>{render()}</div>}
        <Button size="large" type="secondary" onClick={closeModal}>
          {copy.shared('cancel')}
        </Button>
        <Button size="large" type="danger" onClick={this.confirm}>
          {copy.get('confirm')}
        </Button>
      </>
    )
  }
}

WarningDialog.propTypes = propTypes

WarningDialog = Modal({ modalClassName: st.warningDialog })(WarningDialog)

WarningDialog = Intl({ get: 'shared.warningDialog' })(WarningDialog)

export { WarningDialog }
