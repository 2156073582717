import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListPage from 'shared/higher-order-components/ListPage'
import Intl from 'shared/higher-order-components/Intl'
import { Button, Table, Checkbox } from 'shared/components'
import Form, { isRequired, serverMessage } from 'shared/higher-order-components/Form'
import ModalContainer from 'shared/higher-order-components/ModalContainer'
import docImage from 'shared/assets/file-alt-regular.png'

class DocumentsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.$unassigned = true
    this.closeModal = this.closeModal.bind(this)
    this.closeModalAndClear = this.closeModalAndClear.bind(this)
  }

  openModal = () => {
    this.setIsOpen(true)
  }

  closeModal = () => {
    this.setIsOpen(false)
  }

  closeModalAndClear = () => {
    this.setState({ isOpen: false })
  }

  setIsOpen = isOpen => {
    this.setState({ isOpen: isOpen })
  }

  render() {
    const { copy, fields } = this.props
    const { isOpen } = this.state

    const headers = [
      { width: 4 },
      { text: copy.get('Document') },
      { text: copy.get('Reference') },
      { text: copy.get('Type'), width: 10 },
      { width: 4 },
      { width: 4 }
    ]

    const rows = [
      [
        {
          // className: 'is-single-icon',
          content: <Checkbox {...fields.downloadCheck} />
        },
        { text: 'Document A' },
        { text: 'Makro' },
        { text: 'PDF' },
        {
          className: 'is-single-icon',
          content: <i className="i-print" />
        },
        {
          className: 'is-single-icon',
          content: <i className="i-mail_outline" />
        }
      ]
    ]

    return (
      <>
        <i className="i-attach_file" onClick={() => this.openModal()} />
        <ModalContainer
          contentLabel={copy.get('Documents Archive for L20180418-2')}
          isOpen={isOpen}
        >
          <div className="form" style={{ width: '70%' }}>
            <img
              src={docImage}
              alt="Doc"
              style={{ display: 'block', margin: 'auto auto 30px auto' }}
            />

            <div className="form_heading align-center">
              {copy.get('Documents Archive for L20180418-2')}
            </div>
            <div className="form_sub-heading" />

            <Table headers={headers} rows={rows} />

            <Button className="form_cancel" size="large" type="secondary" onClick={this.closeModal}>
              Cancel
            </Button>

            <Button
              className="form_cancel"
              style={{ float: 'right' }}
              size="large"
              type="secondary"
              onClick={() => console.info('done')}
            >
              Download All
            </Button>

            <Button
              className="form_cancel"
              style={{ float: 'right', marginRight: ' 20px' }}
              size="large"
              type="secondary"
              onClick={() => console.info('done')}
            >
              Select All for Email
            </Button>
          </div>
        </ModalContainer>
      </>
    )
  }
}

DocumentsModal.propTypes = {
  copy: PropTypes.object.isRequired
}

DocumentsModal = Form({
  fields: ({ copy }) => ({
    name: [],
    surname: [],
    idNum: [],
    mobile: [],
    email: [],
    officeNum: [],
    downloadCheck: []
  })
})(DocumentsModal)

DocumentsModal = ListPage()(DocumentsModal)

export default Intl({
  get: 'portal.bookings'
})(DocumentsModal)
