import React from 'react'
import PropTypes from 'prop-types'
import Intl from 'shared/higher-order-components/Intl'
import { PageNav } from 'shared/components'

const Nav = ({ copy, orgId, customerId }) => {
  return (
    <PageNav
      links={[
        {
          to: `/portal/${orgId}/customers`,
          text: `${copy.get(`Customers`)}`
        },
        {
          to: `/portal/${orgId}/customers-contacts/${customerId}/contacts`,
          text: `${copy.get(`contacts`)}`
        }
      ]}
    />
  )
}

Nav.propTypes = {
  copy: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired
}

export default Intl({ get: 'portal.customers.nav' })(Nav)
