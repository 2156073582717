/* eslint react/no-multi-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isArray } from 'lodash'
// import st from './Tabs.module.scss'

const propTypes = {
  active: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  controlled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func
}

export class Tabs extends Component {
  constructor(props) {
    super(props)
    this.state = { active: props.active }
    this.triggerChange = this.triggerChange.bind(this)
  }

  componentDidMount() {
    this.triggerChange()
  }

  componentWillReceiveProps({ active }) {
    const { active: Active } = this.props
    if (active !== Active) this.setState({ active })
  }

  componentDidUpdate() {
    this.triggerChange()
  }

  // eslint-disable-next-line react/sort-comp
  triggerChange() {
    const { active } = this.state
    const { onChange } = this.props
    if (onChange) {
      if (active !== this.prevActive) onChange(active)
      this.prevActive = active
    }
  }

  onTabClick({ props: { activeKey, onClick } }) {
    const { controlled } = this.props
    const { activeKey: ActiveKey } = this.state

    if (onClick) onClick()
    if (!controlled && activeKey !== ActiveKey) {
      this.setState({ active: activeKey })
    }
  }

  render() {
    const { className } = this.props
    let { children } = this.props
    const { active } = this.state

    if (!isArray(children)) children = [children]
    const activeTab = children.find(tab => tab.props.activeKey === active)

    return (
      <div className={classnames('tabs', className)}>
        <div className="tabs_links">
          {children
            .filter(c => c.props.visible)
            .map((tab, i) => (
              <div
                key={`tab-${i}`}
                onClick={this.onTabClick.bind(this, tab)}
                className={classnames('tabs_link', {
                  'tabs_link-active': tab.props.activeKey === active,
                  'tabs_link-inactive': tab.props.activeKey !== active
                })}
              >
                {tab.props.title}
              </div>
            ))}
        </div>
        <div
          key={activeTab.props.activeKey}
          className={classnames('tabs_content', `tabs_content-${activeTab.props.activeKey}`)}
        >
          {activeTab || null}
        </div>
      </div>
    )
  }
}

Tabs.propTypes = propTypes

const Tab = ({ children }) => (isArray(children) ? <span>{children}</span> : children)

Tab.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
}

export { Tab }
