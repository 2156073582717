import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { OverlayView } from 'react-google-maps'
import { shallowEqual } from 'recompose'
import { get, some } from 'lodash'

const getPixelPositionOffsetTop = (width, height) => ({
	x: -(width / 2),
	y: -(height) - 20,
})

const getPixelPositionOffsetBottom = (width, height) => ({
	x: -(width / 2),
	y: (height / 2),
})

export default class MapTooltip extends Component {

	static defaultProps = {
		tooltipPosition: 'top'
	}

	static propTypes = {
		visible: PropTypes.bool,
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		tooltipPosition: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'default']),
		position: PropTypes.shape({
			lat: PropTypes.number,
			lng: PropTypes.number,
		}).isRequired
	}

	shouldComponentUpdate(nextProps) {
		return this.props.visible || nextProps.visible
	}

	getTooltipPosition = (width, height) => {
		const { tooltipPosition } = this.props
		if (tooltipPosition === 'top') {
			return getPixelPositionOffsetTop(width, height)
		} else if (tooltipPosition === 'bottom') {
			return getPixelPositionOffsetBottom(width, height)
		} else if (tooltipPosition === 'default') {
			return undefined
		}
	}

	render() {
		const {
			title,
			visible,
			position,
			children,
			tooltipPosition
		 } = this.props

		if (!visible) return null

		return (
			<OverlayView
				position={position}
				getPixelPositionOffset={this.getTooltipPosition}
				mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
			>
				<div
					className={`map_tooltip-${tooltipPosition}`}>
					{title && <div className="map_tooltip-title">{title}</div>}
					{children}
				</div>
			</OverlayView>
		)
	}
}
