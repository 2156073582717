import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import shallowCompare from 'react-addons-shallow-compare'
import { connect } from 'react-redux'
import { flatten, omit, debounce, get } from 'lodash'
import ReactResizeDetector from 'react-resize-detector'
import GoogleMapsApiLoader from 'shared/higher-order-components/GoogleMapsApiLoader'
import * as mapStyles from 'shared/lib/maps/styles'
import MapContextMenu from './MapContextMenu'
import { Input, Select, Button, Box, BoxPanel, BoxContent } from 'shared/components'

const mapDispatchToProps = {}

class Map extends Component {
  static propTypes = {
    google: PropTypes.object.isRequired,
    setMap: PropTypes.func,
    autoFocus: PropTypes.bool,
    children: PropTypes.node,
    controls: PropTypes.element,
    propertiesPanel: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
  }

  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      items: [],
      contextMenuItems: [],
      contextSpecificMenuItems: []
    }
    this.register = this.register.bind(this)
    this.deregister = this.deregister.bind(this)
    this.fitBounds = this.fitBounds.bind(this)
    this.autoFocus = debounce(this.autoFocus, 500)
  }

  componentDidMount() {
    const { google, setMap, autoFocus } = this.props
    this.map = new google.maps.Map(this.$mapCont, {
      center: new google.maps.LatLng(-28.60287, 24.832767),
      zoom: 4,
      styles: mapStyles.grey,
      mapTypeControl: false,
      fullscreenControl: false
    })

    if (setMap) {
      setMap(this)
    }

    // Work around to ensure that map auto focuses correct, works 99.98% of the time
    google.maps.event.addListenerOnce(this.map, 'resize', () => {
      this.setState(state => {
        if (autoFocus) {
          this.autoFocus()
        }
        return state
      })
    })

    google.maps.event.addListenerOnce(this.map, 'load', this.resize)
    google.maps.event.addListenerOnce(this.map, 'idle', this.resize)
    google.maps.event.addListener(this.map, 'rightclick', this.showContextMenu)
    google.maps.event.addListener(this.map, 'click', this.hideContextMenu)
  }

  componentDidUpdate = () => {
    const { autoFocus } = this.props
    if (autoFocus) {
      this.autoFocus()
    }
  }

  componentWillUnmount() {
    this.state.items.forEach(item => item.destroy && item.destroy())
  }

  showContextMenu = (e, contextSpecificMenuItems = []) => {
    this.setState(prevState => {
      return {
        contextSpecificMenuItems,
        contextMenuPosition: [e.latLng.lng(), e.latLng.lat()]
      }
    })
  }

  canShowContextMenu = () =>
    this.isReady() &&
    (this.state.contextSpecificMenuItems.length > 0 || this.state.contextMenuItems.length > 0)

  hideContextMenu = () => {
    this.state.contextMenuPosition &&
      this.setState({ contextMenuPosition: null, contextSpecificMenuItems: [] })
  }

  autoFocus = () => {
    this.fitBounds(flatten(this.state.items.map(item => item.getPositions())))
  }

  resize = () => {
    const { google } = this.props
    google.maps.event.trigger(this.map, 'resize')
  }

  fitBounds(latLngs, maxZoom = 18) {
    if (!latLngs.length) {
      return
    }

    const { google } = this.props
    let bounds = new google.maps.LatLngBounds()
    latLngs.forEach(item => (bounds = bounds.extend({ lng: item[0], lat: item[1] })))
    this.map.fitBounds(bounds)

    // Checks the zoom level and zooms out if it is
    // greater than the max zoom level
    if (this.map.zoom > maxZoom) {
      this.map.setZoom(maxZoom)
    }
  }

  register(item) {
    const index = this.state.items.indexOf(item)
    index === -1 &&
      this.setState(state => {
        return { items: [...state.items, item] }
      })
  }

  deregister(item) {
    const index = this.state.items.indexOf(item)
    index !== -1 &&
      this.setState(state => {
        return { items: state.items.filter(x => x !== item) }
      })
  }

  isReady = () => {
    const { children } = this.props
    return this.map && children
  }

  renderChildren(children) {
    return children && this.isReady()
      ? React.Children.map(children, child => {
          return child
            ? React.cloneElement(child, {
                ...omit(this.props, ['children']),
                map: this.map,
                register: this.register,
                deregister: this.deregister,
                fitBounds: this.fitBounds,
                mapItems: this.state.items,
                showPanel: this.showPanel,
                hidePanel: this.hidePanel,
                registerContextMenuItem: this.registerContextMenuItem,
                deregisterContextMenuItem: this.deregisterContextMenuItem,
                showContextMenu: this.showContextMenu,
                hideContextMenu: this.hideContextMenu,
                contextMenuPosition: this.state.contextMenuPosition,
                autoFocus: this.autoFocus
              })
            : null
        })
      : null
  }

  showPanel = (children, callback) => {
    this.setState(
      {
        panel: children
      },
      () => {
        callback && callback()
        this.resize()
      }
    )
  }

  hidePanel = callback => {
    this.setState(
      {
        panel: null
      },
      () => {
        callback && callback()
        this.resize()
      }
    )
  }

  registerContextMenuItem = item => {
    this.setState(prevState => ({
      contextMenuItems: [...prevState.contextMenuItems, item]
    }))
  }

  deregisterContextMenuItem = item => {
    this.setState(state => ({
      contextMenuItems: state.contextMenuItems.filter(x => !(x === item || x.key === item))
    }))
  }

  render() {
    const { contextMenuPosition } = this.state

    return (
      <Box mode="column">
        <BoxPanel stretch style={{ overflow: 'hidden' }}>
          <Box mode="row">
            {this.props.propertiesPanel ? (
              <BoxPanel style={{ flex: '0.4 1', transform: 'translateZ(0)' }}>
                <div
                  style={{
                    overflowY: 'auto',
                    height: '100%'
                  }}
                >
                  {this.renderChildren(this.props.propertiesPanel)}
                </div>
              </BoxPanel>
            ) : null}
            <BoxPanel style={{ flex: '1 1', position: 'relative' }}>
              <div className="map-canvas">
                {this.renderChildren(this.props.children)}
                {this.canShowContextMenu() ? (
                  <MapContextMenu
                    position={contextMenuPosition}
                    google={this.props.google}
                    map={this.map}
                  >
                    {this.renderChildren([
                      ...this.state.contextSpecificMenuItems,
                      ...this.state.contextMenuItems
                    ])}
                  </MapContextMenu>
                ) : null}
                <ReactResizeDetector handleWidth handleHeight onResize={this.resize} />
                <div className="map-container" ref={el => (this.$mapCont = el)} />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%'
                }}
              >
                {this.renderChildren(this.props.controls) || null}
              </div>
            </BoxPanel>
            <BoxPanel style={{ flex: '0 1', transform: 'translateZ(0)' }}>
              <div
                style={{
                  overflowY: 'auto',
                  height: '100%'
                }}
              >
                {this.renderChildren(this.state.panel)}
              </div>
            </BoxPanel>
          </Box>
        </BoxPanel>
      </Box>
    )
  }
}

Map = connect(
  null,
  mapDispatchToProps
)(Map)

export default GoogleMapsApiLoader(Map)
