import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Intl from 'shared/higher-order-components/Intl'
import Form, { serverMessage } from 'shared/higher-order-components/Form'
import { Page, FormSubmit, Input, Button, ValidationList } from 'shared/components'
import Nav from './Nav'

import * as actions from '../../state/users/userActions'

class UsersForm extends Component {
  componentDidMount() {
    const { formType, orgId, userId, getUserByEntity } = this.props
    if (formType === 'edit') getUserByEntity(orgId, userId)
  }

  render() {
    const { orgId, userId, formType, fields, messages, submit, formStatus, copy } = this.props
    return (
      <Page title="Users" navContent={<Nav {...this.props} />}>
        <div className="form form-small">
          <div className="form_heading">{copy.get(`form.${formType}User`)}</div>
          <div className="form_sub-heading" />
          <ValidationList messages={messages} />

          <div className="row" style={{ display: 'none' }}>
            <div className="col-12">
              <Input
                disabled
                iconLeft="person"
                {...fields.username}
                label={copy.get('form.username')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <Input iconLeft="person" {...fields.first_name} label={copy.get('form.fName')} />
            </div>

            <div className="col-6">
              <Input iconLeft="person" {...fields.last_name} label={copy.get('form.lName')} />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <Input iconLeft="mail_outline" {...fields.email} label={copy.get('form.email')} />
            </div>

            <div className="col-6">
              <Input
                {...fields.mobile_number}
                iconLeft="mobile"
                label={copy.get('form.mobile')}
                regex={/^\d+$/}
                maxLength={11}
              />
            </div>
          </div>

          <div className="row">
            {formType === 'edit' && (
              <div className="col-6">
                <Link to={`/portal/${orgId}/change-password/${userId}`}>
                  <Button
                    style={{ background: '#dfe0e0' }}
                    className="form_cancel"
                    size="large"
                    type="secondary"
                  >
                    {copy.get('Reset Password')}
                  </Button>
                </Link>
              </div>
            )}
            <div className="col-6">
              <Input
                iconLeft="fingerprint"
                {...fields.identity_number}
                label={copy.get('form.identity')}
              />
            </div>
          </div>

          <Link to={`/portal/${orgId}/users`}>
            <Button className="form_cancel" size="large" type="secondary">
              {copy.get('cancel')}
            </Button>
          </Link>

          <FormSubmit
            formType={formType}
            formStatus={formStatus}
            onClick={submit}
            icons=""
            text={{
              clean: copy.get('Save'),
              touched: copy.get('Save'),
              working: copy.get('Saving')
            }}
            style={{ width: '50%', textAlign: 'center' }}
          />
        </div>
      </Page>
    )
  }
}

UsersForm.propTypes = {
  formType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  formItem: PropTypes.object.isRequired,
  formLoaded: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

UsersForm = Form({
  fields: () => ({
    username: [],
    first_name: [serverMessage('names.first')],
    last_name: [serverMessage('names.last')],
    email: [],
    mobile_number: [],
    identity_number: [serverMessage('identity_number')]
  }),

  mapPropsToFields: ({ formType, formItem: { names, numbers, ...rest } }) =>
    formType === 'edit'
      ? {
          ...rest,
          first_name: names && names.first, // !Check elvis syntax babel (names?.first)
          last_name: names && names.last,
          mobile_number: numbers && numbers.mobile
        }
      : false,

  isFormReady: ({ props }) => props.formLoaded || props.formType === 'create',

  submit({ props, fields }) {
    if (props.formType === 'create') {
      props.addUserByEntity(props.orgId, fields)
    } else {
      props.updateUserByEntity(props.orgId, props.userId, fields)
    }
  }
})(UsersForm)

const mapStateToProps = ({ shared }, { params, match }) => ({
  ...params,
  ...shared.form,
  ...match.params
})

const mapDispatchToProps = actions

UsersForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersForm)

export default Intl({
  get: 'portal.users'
})(UsersForm)
