import api, { handleError, handleSuccess } from 'shared/api/index'
import { push } from 'connected-react-router'

const path = 'link/suppliers'

export const fetchSettings = entityId => dispatch => {
  api.get(`${path}/${entityId}/init`).then(({ body: { data } }) => {
    dispatch({
      type: 'shared/form/FETCHED_ITEM',
      item: data
    })
  }, handleError)
}

export const updateSettings = (entityId, settings) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.put(`${path}/${entityId}/update/settings`, settings).then(() => {
    handleSuccess('Settings updated successfully')
    dispatch(push(`/portal/${entityId}`))
  }, handleError)
}
