import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ListPage from 'shared/higher-order-components/ListPage'
import Intl from 'shared/higher-order-components/Intl'

import { Page, Table, Input, Button } from 'shared/components'

class CarriersList extends PureComponent {
  render() {
    const { copy } = this.props
    const headers = [
      { text: copy.get('carrier.id') },
      { text: copy.get('carrier.name') },
      { text: copy.get('carrier.regNum') },
      { text: copy.get('carrier.dateadded') },
      { text: copy.get('carrier.status') },
      { width: 2 },
      { width: 2 }
    ]

    const rows = [
      [
        { text: 'BN842447' },
        { text: 'carrier name' },
        { text: '12345' },
        { text: '03/05/2018 15:22' },
        { text: 'Active' },
        {
          className: 'is-single-icon',
          content: <i className="i-edit" />
        },
        {
          className: 'is-single-icon',
          content: <i className="i-edit" />
        }
      ]
    ]

    const renderSearchOpt = () => {
      return (
        <>
          <Input
            reff={el => (this.$termInput = el)}
            styleType="box"
            name="filter"
            placeholder={copy.get('carrier.term')}
            defaultValue=""
            style={{ width: 180 }}
          />

          <Button
            className="listTable_top-bar-filter"
            onClick={() => console.info('clicked Filter')}
            style={{ marginRight: '0px', width: 140 }}
          >
            {copy.get('carrier.search')}
          </Button>
          <Link to="/portal/12345/carriers/create">
            <Button className="listTable_top-bar-create" iconLeft="plus">
              {copy.get('carrier.addNewCarrier')}
            </Button>
          </Link>
        </>
      )
    }
    const fetching = () => true

    return (
      <Page title="Carriers">
        <span className="listTable">
          <div className="listTable_top-bar">{renderSearchOpt()}</div>
          <Table headers={headers} rows={rows} isLoading={fetching()} />
        </span>
      </Page>
    )
  }
}

CarriersList.propTypes = {
  copy: PropTypes.object.isRequired
}

CarriersList = ListPage()(CarriersList)

const mapDispatchToProps = null

CarriersList = connect(
  null,
  mapDispatchToProps
)(CarriersList)

export default Intl({
  get: 'portal.bookings'
})(CarriersList)
