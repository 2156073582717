import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Intl from 'shared/higher-order-components/Intl'
import Form, { isRequired, serverMessage } from 'shared/higher-order-components/Form'

import {
  Page,
  FormSubmit,
  Input,
  Select,
  Button,
  WarningDialog,
  ValidationList,
  Checkbox
} from 'shared/components'

let CarriersForm = ({ formType, fields, messages, submit, formStatus, copy }) => {
  return (
    <Page title="Carriers">
      <div className="form form-small">
        <div className="form_heading">Add New Carrier</div>
        <div className="form_sub-heading" />
        <ValidationList messages={messages} />
        <div className="row">
          <div className="col-6">
            <Input {...fields.carrierName} label={copy.get('form.name')} autoFocus />
          </div>

          <div className="col-6">
            <Input {...fields.carrierReg} label={copy.get('form.reg')} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input {...fields.carrierId} label={copy.get('form.id')} />
          </div>
          <div className="col-6">
            <Checkbox
              {...fields.suspendAccount}
              onChange={() => console.log('testing')}
              label={copy.get('form.suspend')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Select {...fields.status} value={[]} multi label={copy.get('form.status')} />
          </div>
          <div className="col-6">
            <Input {...fields.suspendedDate} label={copy.get('form.dateSuspended')} />
          </div>
          <div className="col-6">
            <Input {...fields.suspendedReason} label={copy.get('form.reason')} />
          </div>
        </div>
        <Link to="/">
          <Button className="form_cancel" size="large" type="secondary">
            {copy.get('cancel')}
          </Button>
        </Link>

        <FormSubmit
          formType={formType}
          formStatus={formStatus}
          onClick={submit}
          icons=""
          text={{
            clean: copy.get('addToCarriers'),
            touched: copy.get('addingCarriers'),
            working: copy.get('addToCarriers')
          }}
          style={{ width: '50%', textAlign: 'center' }}
        />
      </div>
    </Page>
  )
}

CarriersForm.propTypes = {
  formType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  facilityId: PropTypes.string.isRequired,
  bayId: PropTypes.string,
  formItem: PropTypes.object.isRequired,
  formLoaded: PropTypes.bool.isRequired,
  businessUnits: PropTypes.object.isRequired,
  commodities: PropTypes.object.isRequired,
  fetchBay: PropTypes.func.isRequired,
  createBay: PropTypes.func.isRequired,
  updateBay: PropTypes.func.isRequired,
  deleteBay: PropTypes.func.isRequired,
  fetchBusinessUnits: PropTypes.func.isRequired,
  fetchCommodities: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

CarriersForm = Form({
  fields: ({ copy }) => ({
    carrierName: [],
    carrierReg: [],
    carrierId: [],
    suspendedDate: [],
    suspendedReason: [],
    suspendAccount: [],
    status: []
  }),

  mapPropsToFields: props => (props.formType === 'edit' ? props.formItem : false),

  isFormReady: ({ props }) => props.formLoaded || props.formType === 'create',

  submit({ props, fields }) {
    if (props.formType === 'create') {
      props.createBay(props.orgId, props.facilityId, fields)
    } else {
      props.updateBay(props.orgId, props.facilityId, props.bayId, fields)
    }
  }
})(CarriersForm)

const mapStateToProps = ({ shared }, { params }) => ({
  ...params,
  ...shared.form
})

const mapDispatchToProps = null

CarriersForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarriersForm)

export default Intl({
  get: 'portal.carrier'
})(CarriersForm)
