import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import st from './ItemPicker.module.scss'

const propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  position: PropTypes.object,
  trigger: PropTypes.node.isRequired,
  items: PropTypes.array.isRequired,
  onPick: PropTypes.func.isRequired,
  dontCloseOnPick: PropTypes.bool
}

class ItemPicker extends Component {
  constructor() {
    super()
    this.state = { isOpen: false }
    this.toggle = this.toggle.bind(this)
    this.onOutsideClick = this.onOutsideClick.bind(this)
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onOutsideClick)
  }

  onOutsideClick(e) {
    if (!this.$node.contains(e.target)) this.toggle()
  }

  toggle() {
    const { isOpen } = this.state
    const action = isOpen ? 'remove' : 'add'
    document[`${action}EventListener`]('mouseup', this.onOutsideClick)
    this.setState({ isOpen: !isOpen })
  }

  render() {
    const {
      className,
      height = 'auto',
      width = 250,
      position = { left: 0, top: '100%' },
      trigger,
      items,
      onPick = () => {},
      dontCloseOnPick
    } = this.props

    const { isOpen } = this.state

    return (
      <div className={classnames(st.itemPicker, className)} ref={el => (this.$node = el)}>
        <span onClick={this.toggle}>{trigger}</span>
        {isOpen ? (
          <div className={st['itemPicker_dropdown-cont']} style={{ width, ...position }}>
            <div className={st.itemPicker_dropdown} style={{ maxHeight: height }}>
              {items.map((item, i) => (
                <div
                  className={st.itemPicker_item}
                  key={`itemPicker${i}`}
                  onClick={() => {
                    onPick(item.value, item.label, item)
                    if (!dontCloseOnPick) this.toggle()
                  }}
                >
                  {item.label}
                  <i style={{ color: 'green' }} className="i-plus float-right" />
                </div>
              ))}
              {!items.length ? (
                <div className={st['itemPicker_empty-label']}>No items to display</div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

ItemPicker.propTypes = propTypes

export { ItemPicker }
