import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

const propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  dataTip: PropTypes.string,
  children: PropTypes.node,
  tooltipProps: PropTypes.object,
  onClick: PropTypes.func,
  rowId: PropTypes.string.isRequired
}

const TableActionIcon = ({ rowId, tooltipProps, dataTip, children, icon, color, onClick }) => {
  return (
    <span>
      <i
        style={{ color }}
        data-tip={dataTip}
        data-for={rowId}
        className={`i-${icon}`}
        onClick={onClick}
      />
      <ReactTooltip id={rowId} effect="float" delayShow={550} {...tooltipProps}>
        {children}
      </ReactTooltip>
    </span>
  )
}

TableActionIcon.propTypes = propTypes
TableActionIcon.defaultProps = {
  tooltipProps: {},
  onClick: () => {}
}

export { TableActionIcon }
