import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Intl from 'shared/higher-order-components/Intl'
import { Input, Checkbox, ValidationList, FormSubmit } from 'shared/components'
import Form, { isRequired, serverMessage } from 'shared/higher-order-components/Form'
import imageLogo from 'shared/assets/logo-light.svg'
import { submit } from 'account/state/LoginActions'

let Login = ({ fields, submit, messages, formStatus, copy }) => {
  return (
    <div className="splash center-cont" style={{ height: '100%' }}>
      <div className="account__login splash-box center">
        <div className="splash-logo-holder">
          <img className="splash-logo" src={imageLogo} alt="trackmatic" />
          <div className="splash-heading">{copy.get('heading')}</div>
        </div>
        <ValidationList messages={messages} icon="i-exclamation" customIconClass="splash-round" />
        <Input
          {...fields.username}
          id="username"
          styleType="line"
          iconLeft="mail_outline"
          placeholder={copy.get('username')}
          autoFocus
        />

        <Input
          {...fields.password}
          styleType="line"
          iconLeft="fingerprint"
          placeholder={copy.get('password')}
          type="password"
        />

        <div className="cf" style={{ padding: '5px 0 20px' }}>
          <Checkbox label={copy.get('rememberMe')} className="float-left" />
          <Link className="float-right" style={{ paddingTop: '7px' }} to="/reset-request">
            {copy.get('trouble')}
          </Link>
        </div>
        <FormSubmit
          kind="primary"
          size="large"
          formStatus={formStatus}
          text={{
            clean: copy.get('signIn'),
            touched: copy.get('signIn'),
            working: copy.get('signingIn')
          }}
          icons=""
          onClick={submit}
          style={{ width: '100%', textAlign: 'center' }}
        />
      </div>
    </div>
  )
}

Login.propTypes = {
  fields: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

Login = Form({
  fields: ({ copy }) => ({
    username: [serverMessage('credentials.username'), isRequired(copy.get('username'))],
    password: [serverMessage('credentials.password'), isRequired(copy.get('password'))]
  }),
  mapPropsToFields: ({ username }) => {
    return { username }
  }
})(Login)

const mapStateToProps = ({ shared }) => ({
  ...shared.form
})
const mapDispatchToProps = { onSubmit: submit }

Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

export default Intl({ get: 'account.auth' })(Login)
