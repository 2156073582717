
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose, toClass, pure, shouldUpdate } from 'recompose'

const MapControlItem = ({ isActive, onClick, title, icon, customClass }) => (
	<i
		className={classnames('map_controls-item', `${customClass}` , `i-${icon}`, {
			'is-active': isActive
		})}
		title={title}
		onClick={onClick}
	/>
)

MapControlItem.propTypes = {
	isActive: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	icon: PropTypes.string.isRequired,
	title: PropTypes.string,
	customClass: PropTypes.string
}

MapControlItem.defaultProps = {
	isActive: false,
	onClick: () => false,
}

export default compose(
	toClass,
	pure
)(MapControlItem)

