import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Intl from 'shared/higher-order-components/Intl'
import Form from 'shared/higher-order-components/Form'
import { Page, PageNav, Checkbox, FormSubmit } from 'shared/components'

let LinkedFacilitiesList = ({ orgId, userId, fields, submit, formStatus, formType, copy }) => {
  const theNav = () => (
    <PageNav
      links={[
        {
          to: `/portal/${orgId}/users/`,
          text: `${copy.get(`Users`)}`
        },
        {
          to: `/portal/${orgId}/linked/edit/${userId}/linked-customer/`,
          text: `${copy.get(`Linked Customers`)}`
        },
        {
          to: `/portal/${orgId}/linked/edit/${userId}/linked-facilities/`,
          text: `${copy.get(`Notifications`)}`
        }
      ]}
    />
  )
  return (
    <Page title="Linked Facilities and Notifications" navContent={theNav()}>
      <div className="form form-medium">
        <div className="form_heading form_element">{copy.get('Notifications')}</div>

        <div className="row">
          <div className="col-4">
            <Checkbox {...fields.sms} label={copy.get('SMS')} />
          </div>
          <div className="col-4">
            <Checkbox {...fields.arrivedAtFacility} label={copy.get('arrivedAtFacility')} />
          </div>
          <div className="col-4">
            <Checkbox {...fields.ExceedBookingDelayed} label={copy.get('ExceedBookingDelayed')} />
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <Checkbox {...fields.email} label={copy.get('email')} />
          </div>
          <div className="col-4">
            <Checkbox {...fields.departedFacility} label={copy.get('departedFacility')} />
          </div>
        </div>
        <FormSubmit
          formType={formType}
          formStatus={formStatus}
          onClick={submit}
          icons=""
          text={{
            clean: copy.get('Save'),
            touched: copy.get('Save'),
            working: copy.get('Saving')
          }}
        />
      </div>
    </Page>
  )
}

LinkedFacilitiesList.propTypes = {
  orgId: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

LinkedFacilitiesList = Form({
  fields: ({ copy }) => ({
    sms: [],
    arrivedAtFacility: [],
    ExceedBookingDelayed: [],
    email: [],
    departedFacility: []
  }),

  mapPropsToFields: props => (props.formType === 'edit' ? props.formItem : false),

  /** TEMP HACK */
  isFormReady: ({ props }) => true || props.formLoaded,

  submit({ props, fields }) {
    // updateFacilitiesNotifications()
  }
})(LinkedFacilitiesList)

const mapStateToProps = ({ shared }, { params, match }) => ({
  ...params,
  ...shared.form,
  ...match.params
})

const mapDispatchToProps = null

LinkedFacilitiesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkedFacilitiesList)

export default Intl({
  get: 'portal.linkedFacilities'
})(LinkedFacilitiesList)
