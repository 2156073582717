const getNavItems = orgId =>
  !orgId
    ? []
    : [
        {
          link: `/portal/${orgId}/bookings`,
          title: 'Bookings',
          key: 'link-suppliers',
          sub: [
            {
              link: `/portal/${orgId}/bookings`,
              title: 'View',
              icon: ''
            }
          ],
          icon: ''
        },
        // {
        //   link: `/portal/${orgId}/transactions`,
        //   title: 'Transactions',
        //   key: 'link-suppliers',
        //   icon: ''
        // },
        {
          link: `/portal/${orgId}/customers`,
          title: 'Customers',
          key: 'link-suppliers',
          sub: [
            {
              link: `/portal/${orgId}/customers`,
              title: 'View',
              icon: ''
            }
          ],
          icon: ''
        },
        // {
        //   link: `/portal/${orgId}/carriers`,
        //   title: 'Carriers',
        //   key: 'link-suppliers',
        //   sub: [
        //     {
        //       link: `/portal/${orgId}/carriers`,
        //       title: 'View',
        //       icon: ''
        //     },
        //     {
        //       link: `/portal/${orgId}/carriers/create`,
        //       title: 'Add',
        //       icon: ''
        //     }
        //   ],
        //   icon: ''
        // },
        {
          link: `/portal/${orgId}/users`,
          title: 'Users',
          key: 'link-suppliers',
          sub: [
            {
              link: `/portal/${orgId}/users`,
              title: 'View',
              icon: ''
            },
            {
              link: `/portal/${orgId}/users/create`,
              title: 'Add',
              icon: ''
            }
          ],
          icon: ''
        },
        {
          link: `/portal/${orgId}/info`,
          title: 'Company Configuration',
          key: 'link-suppliers',
          sub: [
            {
              link: `/portal/${orgId}/info`,
              title: 'Company Info',
              icon: ''
            },
            {
              link: `/portal/${orgId}/users`,
              title: 'Users',
              icon: ''
            }
          ],
          icon: ''
        }
      ]

export default getNavItems
