import React from 'react'
import { Box } from 'shared/components'

const ResetPassword = () => {
  return (
    <Box>
      <>Hi there from ResetPassword</>
    </Box>
  )
}
export default ResetPassword
