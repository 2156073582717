import api, { handleError } from 'shared/api/index'
import { updateQuery } from 'shared/state/actions'
import { paginateQuery } from 'shared/api/utils'
import { toClient } from './resource'

const path = 'link/suppliers'

const fetchedItems = (items, pagination) => {
  return {
    type: 'shared/list/FETCHED_ITEMS',
    items,
    pagination
  }
}

export const searchCustomersByEntity = (entityId, query = {}) => dispatch => {
  query = dispatch(updateQuery(query))
  dispatch({ type: 'shared/list/FETCHING' })

  api.get(`${path}/${entityId}/customers`, paginateQuery(query)).then(
    ({ data }) => {
      const items = data.map(item => toClient(item))
      dispatch(fetchedItems(items, data.pagination))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const getCustomersByEntityAndId = (entityId, customerId, query = {}) => dispatch => {
  query = dispatch(updateQuery(query))
  dispatch({ type: 'shared/list/FETCHING' })

  api.get(`${path}/${entityId}/customers/${customerId}`, paginateQuery(query)).then(
    ({ body }) => {
      const items = [body].map(item => toClient(item))

      dispatch(fetchedItems(items, body.pagination))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}
