/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react'
import pubsub from 'sweet-pubsub'
import CSSTransition from 'react-addons-css-transition-group'
import { reject, reduce, isArray } from 'lodash'
import { Button } from 'shared/components'
import cx from 'classnames'

class Toasts extends Component {
  constructor(props) {
    super(props)
    this.id = 1
    this.topMargin = 135
    this.transitionDuration = 250
    this.state = { toasts: [] }
    this.setAndCalcOffsets = this.setAndCalcOffsets.bind(this)
    this.addToast = this.addToast.bind(this)
    this.removeToast = this.removeToast.bind(this)
  }

  componentDidMount() {
    pubsub.on('toast', this.addToast)
  }

  componentWillUnmount() {
    pubsub.off('toast', this.addToast)
  }

  setAndCalcOffsets() {
    const toasts = this.cont.querySelectorAll('.toast')
    return reduce(
      toasts,
      (sum, el) => {
        el.style.top = `${sum}px`
        return sum + el.offsetHeight + 20
      },
      this.topMargin
    )
  }

  addToast({
    type = 'primary',
    title,
    message,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    autoHide = true,
    duration = 5000,
    removeOthers = false
  }) {
    const { toasts } = this.state
    this.id += 1
    const $toasts = removeOthers ? [] : toasts
    const offset = removeOthers ? this.topMargin : this.setAndCalcOffsets()
    const { id } = this

    this.setState({
      toasts: [
        ...$toasts,
        {
          id,
          type,
          title,
          message,
          confirmText,
          cancelText,
          onConfirm,
          onCancel,
          offset
        }
      ]
    })

    if (autoHide) {
      setTimeout(() => this.removeToast(id), duration)
    }
  }

  removeToast(id) {
    const { toasts } = this.state
    this.setState({
      toasts: reject(toasts, { id })
    })
    setTimeout(() => this.setAndCalcOffsets(), this.transitionDuration + 100)
  }

  confirm({ id, onConfirm }) {
    if (onConfirm) onConfirm()
    this.removeToast(id)
  }

  cancel({ id, onCancel }) {
    if (onCancel) onCancel()
    this.removeToast(id)
  }

  render() {
    const { toasts } = this.state
    return (
      <div ref={el => (this.cont = el)}>
        <CSSTransition
          transitionName="toast"
          transitionEnterTimeout={this.transitionDuration}
          transitionLeaveTimeout={this.transitionDuration}
        >
          {toasts.map(toast => (
            <div
              key={toast.id}
              className={cx('toast', `is-${toast.type}`)}
              style={{ top: toast.offset }}
            >
              <i className="i-close toast_close" onClick={this.removeToast.bind(this, toast.id)} />
              {toast.title ? <div className="toast_title">{toast.title}</div> : null}
              {toast.message ? (
                <div className="toast_message">
                  {isArray(toast.message)
                    ? toast.message.map(msg => <div key={msg}>{msg}</div>)
                    : toast.message}
                </div>
              ) : null}
              <div className="cf">
                {toast.cancelText ? (
                  <Button
                    size="small"
                    type="secondary"
                    className="toast_cancel"
                    onClick={this.cancel.bind(this, toast)}
                  >
                    {toast.cancelText}
                  </Button>
                ) : null}
                {toast.confirmText ? (
                  <Button
                    size="small"
                    type={toast.type}
                    className="toast_confirm"
                    onClick={this.confirm.bind(this, toast)}
                  >
                    {toast.confirmText}
                  </Button>
                ) : null}
              </div>
            </div>
          ))}
        </CSSTransition>
      </div>
    )
  }
}

export default Toasts
