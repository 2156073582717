import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from 'shared/components'
import Intl from 'shared/higher-order-components/Intl'
import imageLogo from 'shared/assets/logo-dark.svg'

const propTypes = {
  text: PropTypes.string,
  withoutLogin: PropTypes.bool,
  withOrganisations: PropTypes.bool,
  copy: PropTypes.object.isRequired
}

let SplashNav = ({ text, withoutLogin, withOrganisations, copy }) => (
  <span>
    <img className="splash-logo" src={imageLogo} alt="trackmatic" />
    <div className="splash-right">
      <span>{text || null}</span>
      {!withoutLogin ? (
        <Link to="/login">
          <Button>{copy.get('signIn')}</Button>
        </Link>
      ) : null}
      {withOrganisations ? (
        <Link to="/organisations">
          <Button>{copy.get('seeAllOrgs')}</Button>
        </Link>
      ) : null}
    </div>
  </span>
)

SplashNav.propTypes = propTypes

SplashNav = Intl({ get: 'shared.splashNav' })(SplashNav)

export { SplashNav }
