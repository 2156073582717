import pubsub from 'sweet-pubsub'
import { round, get } from 'lodash'
import { copy } from 'shared/lib/intl'

export const decimalToDegMinSec = coord => {
  coord = Math.abs(coord)
  const degrees = Math.floor(coord)
  const minutes = Math.floor((coord - degrees) * 60)
  const seconds = round(((coord - degrees) * 60 - minutes) * 60, 1)
  return `${degrees}°${minutes}'${seconds}''`
}
export const formatLat = coord => decimalToDegMinSec(coord) + (coord < 0 ? 'S' : 'N')
export const formatLng = coord => decimalToDegMinSec(coord) + (coord < 0 ? 'W' : 'E')

export const formatLatLng = coords => {
  return `${formatLat(coords[1])}/${formatLng(coords[0])}`
}

export const DegMinSecToDecimal = (deg, min, sec, orient) => {
  const value = round(+deg + +min / 60 + +sec / 3600, 6)
  return orient === 'N' || orient === 'E' ? value : -value
}

export const formatAddress = addressData => {
  if (!addressData) {
    return ''
  }

  const getProp = prop => addressData[prop] || ''

  const addressString = [
    `${getProp('street_no')} ${getProp('street')}`.trim(),
    getProp('suburb'),
    getProp('city'),
    getProp('postal_code')
  ]
    .filter(i => i)
    .join(', ')

  return addressString
}

export const getAddressFromGooglePlace = place => {
  const getProp = type =>
    (get(place, 'address_components', []).find(o => o.types.includes(type)) || {}).long_name || ''

  return {
    unit_no: '',
    building_name: '',
    street_no: getProp('street_number'),
    sub_division_number: '',
    street: getProp('route'),
    suburb: getProp('sublocality'),
    city: getProp('locality'),
    province: getProp('administrative_area_level_1'),
    postal_code: getProp('postal_code'),
    country: getProp('country'),
    coords: [round(place.geometry.location.lng(), 6), round(place.geometry.location.lat(), 6)],
    location: place.geometry.location.toJSON()
  }
}

export const getAddressFromGooglePlaces = places => {
  return places.map((item, index) => {
    const address = getAddressFromGooglePlace(item)
    return {
      address,
      value: index,
      label: item.formatted_address
    }
  })
}

let geocoder
export const geocode = (google, options, then) => {
  if (!geocoder) geocoder = new google.maps.Geocoder()
  geocoder.geocode(options, (results, status) => {
    if (status === 'OK' && results[0]) {
      then(getAddressFromGooglePlace(results[0]))
    } else {
      pubsub.emit('toast', {
        type: 'danger',
        title: copy('shared.geo.geocodeFailed')
      })
    }
  })
}

let geocoderaddress
export const geocodeaddress = (google, options, then) => {
  if (!geocoderaddress) geocoderaddress = new google.maps.Geocoder()
  geocoderaddress.geocode(options, (results, status) => {
    if (status === 'OK' && results.length > 0) {
      then(getAddressFromGooglePlaces(results))
    }
  })
}

export const latLngToPixels = (google, map, latLng) => {
  const projection = map.getProjection()
  const topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast())
  const bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest())
  const scale = 2 ** map.getZoom()
  const worldPoint = projection.fromLatLngToPoint(latLng)
  return new google.maps.Point(
    (worldPoint.x - bottomLeft.x) * scale,
    (worldPoint.y - topRight.y) * scale
  )
}

export const pixelsToLatLng = (google, map, point) => {
  const projection = map.getProjection()
  const topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast())
  const bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest())
  const scale = 2 ** map.getZoom()
  const worldPoint = new google.maps.Point(
    point.x / scale + bottomLeft.x,
    point.y / scale + topRight.y
  )
  return map.getProjection().fromPointToLatLng(worldPoint)
}

export const isValidCoordinate = coordinates => {
  return coordinates && coordinates.length === 2 && (coordinates[0] !== 0 || coordinates[1] !== 0)
}
