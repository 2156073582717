import enShared from 'shared/copy/en'
import enAccount from 'account/copy/en'
import enPortal from 'modules/portal/copy/en'

export default {
  en: {
    shared: enShared,
    account: enAccount,
    portal: enPortal
  }
}
