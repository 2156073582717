import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const mapStateToProps = ({ app: { page } }) => {
  return { page: page }
}

class PageTitle extends Component {
  componentDidMount = () => {
    document.title = this.props.page.title
  }

  componentDidUpdate = () => {
    document.title = this.props.page.title
  }

  render() {
    return <span>{this.props.children}</span>
  }
}

PageTitle.propTypes = {
  children: PropTypes.node,
  page: PropTypes.object.isRequired
}

PageTitle = connect(mapStateToProps)(PageTitle)

export { PageTitle }
