import React from 'react'
import { Page } from 'shared/components'
import clientErrorImg from 'shared/assets/404.png'

const NoMemberships = () => {
  return (
    <span>
      <Page title="No Memberships Found">
        {/* Start Container */}
        <div className="redirect_container">
          <div className="redirect_container_content">
            <div className="redirect_container_content_title">
              No memberships have been found for your account
            </div>
            <div className="redirect_container_content_icon">
              <img className="redirect_img" src={clientErrorImg} alt="404" />
            </div>
            <div className="redirect_container_content_footertxt">
              <div className="error-code">Error: No Memberships Found</div>
              <div className="redirect-txt">
                There are no memberships or pending invitations to any organisations for your
                account. Please contact support for assistance.
              </div>
            </div>
          </div>
        </div>
      </Page>
    </span>
  )
}

export default NoMemberships
