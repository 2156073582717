/* global google */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isEqual, cloneDeep, get } from 'lodash'
import { Marker, InfoWindow } from 'react-google-maps'
import { toGoogleLatLng, toTrackmaticLngLat } from 'shared/lib/utils'
import { geocode } from 'shared/lib/geo'
import colors from 'shared/static/colors'
import BaseMap from 'shared/components/Map/BaseMap'
import { animations } from 'shared/components/Map/MapConstants'
import { stat } from 'fs'

const MARKER_SVG_PATH =
  'M0-77.834C-14.635-77.834-26.5-65.969-26.5-51.334-26.5-33.459 0-.166 0-.166S26.5-33.5 26.5-51.334C26.5-65.969 14.635-77.834 0-77.834ZM0-41.027C-5.691-41.027-10.306-45.64-10.306-51.334-10.306-57.025-5.692-61.639 0-61.639S10.306-57.026 10.306-51.334C10.306-45.641 5.691-41.027 0-41.027Z'

const propTypes = {
  addressData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onIdle: PropTypes.func.isRequired,
  onMapLoadingComplete: PropTypes.func.isRequired,
  center: PropTypes.object,
  zoom: PropTypes.number
}

const getInitialState = ({ zoom = 4, position, center, addressData }) => ({
  center,
  position,
  isNotDragging: true,
  centerMove: true,
  zoom: zoom,
  visible: true,
  showInfo: false,
  addressData: addressData || {
    unit_no: '',
    building_name: '',
    street_no: '',
    sub_division_number: '',
    street: '',
    suburb: '',
    city: '',
    province: '',
    postal_code: '',
    country: '',
    coords: [],
    location: {}
  }
})

class AvtMap extends Component {
  static propTypes = propTypes

  constructor(props) {
    super(props)
    const state = getInitialState({
      location: props.addressData.location,
      addressData: props.addressData
    })
    this.state = state
  }

  componentDidUpdate(prevProps, prevState) {
    const { isNotDragging } = this.state
    const centerChanged = !isEqual(this.state.center, prevState.center)
    if (centerChanged && isNotDragging) {
      this.$map.panTo(this.state.position)
    }
  }

  componentWillUnmount() {
    this.setState(getInitialState({}))
  }

  getBounds = () => {
    return this.$map.getBounds()
  }

  setMap = map => {
    this.$map = map
    setTimeout(() => {
      //need this to prevent race condition
      this.props.onMapLoadingComplete()
    })
  }

  geocodeAddress = () => {
    const { onChange } = this.props
    onChange(cloneDeep(this.state.addressData))
    this.setState({ showInfo: false })
  }

  showInfo = () => {
    this.setState({ showInfo: true })
  }

  hideInfo = () => {
    this.setState({ showInfo: false, isNotDragging: false })
  }

  onCenterChanged = e => {
    const { isNotDragging, centerMove } = this.state
    if (isNotDragging && centerMove) {
      this.$map.panTo(this.state.center)
    }
  }

  markerPositionChanged = ({ latLng }) => {
    const position = latLng.toJSON()
    const center = latLng.toJSON()

    const options = {
      location: {
        lat: parseFloat(latLng.lat()),
        lng: parseFloat(latLng.lng())
      }
    }

    geocode(google, options, addressData => {
      addressData.location = options.location
      addressData.coords = [options.location.lng, options.location.lat]
      this.setState({ addressData, showInfo: true })
    })

    this.setState({
      position
    })
  }

  set = ({ center, position, addressData, zoom }) => {
    this.setState(state => ({
      addressData: addressData || state.addressData,
      showInfo: true,
      position: position || state.position,
      center: center || state.center,
      zoom: zoom || state.zoom
    }))
  }

  onDragging = () => {
    this.setState({ isNotDragging: false, centerMove: false })
  }

  onDragEnd = () => {
    const mapBounds = this.getBounds()
    if (mapBounds.contains(new google.maps.LatLng(this.state.position))) {
      this.showInfo()
    }
    this.setState({ isNotDragging: true })
  }

  onZoomChanged = () => {
    const mapBounds = this.getBounds()
    if (mapBounds.contains(new google.maps.LatLng(this.state.center))) {
      this.setState({ zoom: this.$map.getZoom(), center: '' })
    }
  }

  render() {
    const { zoom, center, onIdle, visible, position, showInfo, addressData } = this.state

    return (
      <BaseMap
        onMapLoad={this.setMap}
        onDrag={this.onDragging}
        onDragStart={this.hideInfo}
        onDragEnd={this.onDragEnd}
        setMarker={this.setMarker}
        onDblClick={this.markerPositionChanged}
        onZoomChanged={this.onZoomChanged}
        onCenterChanged={this.onCenterChanged}
        onIdle={this.props.onIdle}
        center={center}
        zoom={zoom}
      >
        {visible && (
          <Marker
            ref={m => (this.$marker = m)}
            onDragStart={this.hideInfo}
            onClick={this.showInfo}
            draggable
            animation={animations.DROP}
            options={{
              icon: {
                path: MARKER_SVG_PATH,
                fillColor: colors.blue,
                strokeColor: colors.blue,
                fillOpacity: 1,
                scale: 0.4
              }
            }}
            onDragEnd={this.markerPositionChanged}
            position={position}
          >
            {showInfo && (
              <InfoWindow onCloseClick={this.hideInfo}>
                <div>
                  <p>{addressData.street_no}</p>
                  <p>{addressData.street}</p>
                  <p>{addressData.city}</p>
                  {
                    <span
                      style={{ color: 'blue', cursor: 'pointer', paddingTop: 20 }}
                      onClick={this.geocodeAddress}
                    >
                      Use this address...
                    </span>
                  }
                </div>
              </InfoWindow>
            )}
          </Marker>
        )}
      </BaseMap>
    )
  }
}

export default AvtMap
