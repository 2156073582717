import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ListPage from 'shared/higher-order-components/ListPage'
import Intl from 'shared/higher-order-components/Intl'

import { Page, Table, Input, Select, Button, WarningDialog, Pagination } from 'shared/components'
import * as actions from '../../state/users/userActions'

class UsersList extends PureComponent {
  componentDidMount() {
    const { searchUsersByEntity, orgId } = this.props
    searchUsersByEntity(orgId, { perPage: this.perPage })
  }

  get perPage() {
    return 25
  }

  render() {
    const {
      copy,
      fetching,
      listItems,
      blockUserByEntity,
      orgId,
      pagination,
      query,
      searchUsersByEntity
    } = this.props

    const headers = [
      { text: copy.get('form.username') },
      { text: copy.get('form.fullnames') },
      { text: copy.get('form.email') },
      { text: copy.get('form.mobile') },
      { text: copy.get('form.identity') },
      { width: 2 },
      { width: 2 }
    ]

    const rows = listItems.map(user => [
      { text: user.username },
      { text: user.names.full },
      { text: user.email },
      { text: user.numbers.mobile },
      { text: user.identity_number },
      {
        className: 'is-single-icon',
        content: (
          <WarningDialog
            heading={`Suspend ${user.names.full}\`s Access`}
            message={copy.get('suspendWarning')}
            onConfirm={() => blockUserByEntity(orgId, user.id)}
            modalTrigger={<i style={{ color: 'red' }} className="i-exclamation-circle" />}
          />
        )
      },
      {
        className: 'is-single-icon',
        content: (
          <Link {...this.props} to={`/portal/${orgId}/users/edit/${user.id}`}>
            <i className="i-edit" />
          </Link>
        )
      }
    ])

    const filter = () =>
      searchUsersByEntity(orgId, {
        perPage: this.perPage,
        term: this.$termInput.value || '',
        type: this.$statusTypeSelect.value || ''
      })

    const renderSearchOpt = () => {
      return (
        <>
          <Input
            reff={el => (this.$termInput = el)}
            styleType="box"
            name="filter"
            onEnter={filter}
            placeholder={copy.get('term')}
            defaultValue={query.term}
            style={{ width: 300 }}
          />

          <Select
            reff={el => (this.$statusTypeSelect = el)}
            styleType="box"
            placeholder={copy.get('AllTypes')}
            options={[
              {
                value: 'Accepted',
                label: copy.get('Accepted')
              },
              {
                value: 'Pending',
                label: copy.get('Pending')
              },
              {
                value: 'Cancelled',
                label: copy.get('Cancelled')
              }
            ]}
            defaultValue={query.type}
            style={{ width: 170 }}
          />

          <Button
            className="listTable_top-bar-filter"
            onClick={filter}
            style={{ marginRight: '0px', width: 140 }}
          >
            {copy.get('search')}
          </Button>
          <Link to={`/portal/${orgId}/users/create`}>
            <Button className="listTable_top-bar-create" iconLeft="plus">
              {copy.get('form.AddNewUser')}
            </Button>
          </Link>
        </>
      )
    }

    return (
      <Page title="Users">
        <span className="listTable">
          <div className="listTable_top-bar">{renderSearchOpt()}</div>
          <Table headers={headers} rows={rows} isLoading={fetching} />
          <Pagination
            update={page => searchUsersByEntity(orgId, { page })}
            page={query.page}
            total={pagination.total}
            perPage={this.perPage}
          />
        </span>
      </Page>
    )
  }
}

UsersList.propTypes = {
  copy: PropTypes.object.isRequired
}

UsersList = ListPage()(UsersList)

const mapStateToProps = ({ shared }, { params, location, match }) => ({
  query: location.search,
  ...params,
  ...shared.list,
  ...match.params
})
const mapDispatchToProps = actions

UsersList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList)

export default Intl({
  get: 'portal.users'
})(UsersList)
