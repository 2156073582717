import api, { handleError } from 'shared/api/index'
import { updateQuery } from 'shared/state/actions'
import { paginateQuery } from 'shared/api/utils'
import { toBooking, toReportParameters } from './resource'

const path = 'link/suppliers'

const fetchedItems = (items, pagination) => {
  return {
    type: 'shared/list/FETCHED_ITEMS',
    items,
    pagination
  }
}

export const searchBookingsByEntity = (entityId, query = {}) => dispatch => {
  query = dispatch(updateQuery(query))
  dispatch({ type: 'shared/list/FETCHING' })
  api.get(`${path}/${entityId}/bookings`, paginateQuery(query)).then(
    ({ body }) => {
      const items = body.data.map(item => toBooking(item.data))
      dispatch(fetchedItems(items, body.pagination))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const emailGatePass = (entityId, bookingId, email) => {
  return api
    .put(`${path}/${entityId}/bookings/${bookingId}/emailgatepass`, email)
    .then(({ body }) => {
      console.log('body', body)
      return body
    }, handleError)
}

export const printGatePass = (customerId, facilityId, bookingId) => dispatch => {
  api
    .post(
      `reporting/${customerId}/generate/from_supplier_portal?id=link-facilities-gate-pass/${facilityId}`,
      toReportParameters(facilityId, bookingId)
    )
    .then(({ data, body }) => {
      window.open(body.links[0].href)
    }, handleError)
}
