import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { formatAddress } from 'shared/lib/geo'
import Intl from 'shared/higher-order-components/Intl'
import { Input } from 'shared/components'
import AvtModal from './AvtModal'

const propTypes = {
  styleType: PropTypes.string,
  value: PropTypes.any,
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  alwaysReceiveProps: PropTypes.bool,
  copy: PropTypes.object.isRequired
}

class Avt extends Component {
  constructor(props) {
    super(props)
    this.state = { ...this.getStateFromProps(props), isOpen: false }
  }

  componentWillReceiveProps(props) {
    if (props.alwaysReceiveProps || !this.isTouched) {
      this.setState(this.getStateFromProps(props))
    }
  }

  componentWillUnmount() {
    this.setState({ addressData: {} })
  }

  openModal = () => {
    this.setIsOpen(true)
  }

  closeModal = () => {
    this.setIsOpen(false)
  }

  setIsOpen = isOpen => {
    this.setState(state => ({ isOpen }))
  }

  onFocus = () => {
    this.openModal()
    this.onFocus = () => {}
  }

  getStateFromProps = props => {
    const addressData = props.value || {}
    const inputValue = isEmpty(addressData) ? '' : formatAddress(addressData)
    return { addressData, inputValue }
  }

  onChange = (addressData, triggerChange) => {
    this.isTouched = true
    if (triggerChange) {
      this.setState({
        addressData,
        inputValue: formatAddress(addressData)
      })
      this.props.onChange(addressData)
    } else {
      this.setState({ addressData })
    }
  }

  render() {
    const { styleType, invalid, copy } = this.props
    const { addressData, inputValue, isOpen } = this.state
    return (
      <AvtModal
        isOpen={isOpen}
        onChange={this.onChange}
        shouldCloseOnOverlayClick={false}
        addressData={addressData}
        closeModal={this.closeModal}
        openModal={this.openModal}
      >
        <Input
          reff={el => (this.$input = el)}
          onClick={this.openModal}
          onFocus={this.onFocus}
          className="avtm_main-input"
          styleType={styleType}
          iconLeft="map-marker"
          label={copy.avt('address')}
          value={inputValue}
          invalid={invalid}
        />
      </AvtModal>
    )
  }
}

Avt.propTypes = propTypes

export default Intl({ avt: 'shared.avt' })(Avt)
