import api, { handleError } from 'shared/api/index'
import { updateQuery } from 'shared/state/actions'
import { paginateQuery } from 'shared/api/utils'
import { toClient, contactApi } from '../contacts/resource'
import { push } from 'connected-react-router'

const path = 'link/suppliers'

const fetchedItems = (items, pagination) => {
  return {
    type: 'shared/list/FETCHED_ITEMS',
    items,
    pagination
  }
}
export const getCustomerContact = (entityId, customerId, contactId) => dispatch => {
  api.get(`${path}/${entityId}/customers/${customerId}/contacts`).then(
    ({ body }) => {
      const items = body.data.map(item => {
        if (item.data.id === contactId) {
          dispatch({
            type: 'shared/form/FETCHED_ITEM',
            item: item.data
          })
        }
      })
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const getCustomerContactsByEntityAndId = (entityId, customerId, query = {}) => dispatch => {
  query = dispatch(updateQuery(query))
  dispatch({ type: 'shared/list/FETCHING' })
  api.get(`${path}/${entityId}/customers/${customerId}/contacts`, paginateQuery(query)).then(
    ({ body }) => {
      //the customers are not in an array when fetching a single record - wrapping it up to simplify and standardise the customer actions
      const items = [body].map(item => toClient(item))
      dispatch(fetchedItems(items, body.pagination))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const addCustomerContactByEntity = (entityId, customerId, contact) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api
    .post(`${path}/${entityId}/customers/${customerId}/contacts`, contactApi.contact(contact))
    .then(
      () => {
        dispatch(push(`/portal/${entityId}/customers-contacts/${customerId}/contacts`))
      },
      ({ errors }) => {
        dispatch({ type: 'shared/list/FETCH_ERROR' })
        handleError({ errors })
      }
    )
}

export const deleteCustomerContactByEntity = (
  entityId,
  customerId,
  contactId,
  contact
) => dispatch => {
  api.delete(`${path}/${entityId}/customers/${customerId}/contacts/${contactId}`).then(
    ({ body }) => {
      const items = [body].map(item => toClient(item))
      dispatch(fetchedItems(items, body.pagination))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const updateCustomerContactByEntity = (
  entityId,
  customerId,
  contactId,
  contact
) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })
  api
    .put(
      `${path}/${entityId}/customers/${customerId}/contacts/${contactId}`,
      contactApi.contact(contact)
    )
    .then(
      () => {
        dispatch(push(`/portal/${entityId}/customers-contacts/${customerId}/contacts`))
      },
      ({ data }) => {
        dispatch({
          type: 'shared/form/SUBMITTED',
          messages: data
        })
      }
    )
}
