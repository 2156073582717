import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import DefaultWhen from 'shared/higher-order-components/DefaultWhen'
import st from './NavItem.module.scss'

let NavItem = ({ to, className, text, activeClassName, clickable = true, position, children }) => {
  const cn = classnames({
    [className]: position === 'left',
    [`{${st['page_nav-right']}`]: position === 'right'
  })

  if (clickable) {
    return (
      <NavLink className={cn} activeClassName={activeClassName} to={to}>
        {children || text}
      </NavLink>
    )
  }

  return <div className={cn}>{children || text}</div>
}

NavItem.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  text: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']),
  clickable: PropTypes.bool
}
NavItem.defaultProps = {
  to: '',
  className: st['page_nav-item'],
  activeClassName: st['is-active'],
  clickable: true,
  position: 'left' 
}

NavItem = DefaultWhen({
  useDefaultWhen: props => {
    return props.visible === false
  },
  defaultValue: null
})(NavItem)

export { NavItem } 
