import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button, FormSubmit } from 'shared/components'

class SplitButtonSubmit extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.array,
    align: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      isVisible: false
    }
  }

  handleClick = () => {
    this.setState(state => ({
      isVisible: !state.isVisible
    }))
  }

  close = () => {
    this.setState({
      isVisible: false
    })
  }

  renderSplitButtonItems() {
    return this.props.children
      ? React.Children.map(this.props.children, child => {
          return React.cloneElement(child, { close: this.close })
        })
      : null
  }

  render() {
    const { label, align, submit } = this.props

    return (
      <div className={classnames('splitbutton', `splitbutton-${align}`)}>
        <div className="splitbutton-wrapper">
          <div className="splitbutton-container-button">
            <FormSubmit
              size="medium"
              {...this.props}
              text={{
                clean: this.props.label,
                touched: this.props.label,
                working: 'Fetching...'
              }}
              onClick={submit}
            />

            <Button
              {...this.props}
              onClick={this.handleClick}
              iconRight="chevron-down"
              style={{ paddingLeft: 2, paddingRight: 0, marginTop: 20, textAllign: 'left' }}
            >
              <span className="splitbutton-sronly" />
            </Button>
          </div>
          <div
            className={classnames(
              'splitbutton-container-items',
              `splitbutton-container-items-${align}`
            )}
          >
            {this.state.isVisible ? this.renderSplitButtonItems() : null}
          </div>
        </div>
      </div>
    )
  }
}

export { SplitButtonSubmit }
