import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Intl from 'shared/higher-order-components/Intl'
import { isNil, isUndefined, isArray, unionBy, cloneDeep, every } from 'lodash'
import st from './Select.module.scss'

const getUniqueOptions = (...arrays) => unionBy(...arrays, 'value')

class Select extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
      value: isUndefined(props.value)
        ? isUndefined(props.defaultValue)
          ? null
          : props.defaultValue
        : props.value,
      options: props.options || [],
      filteredOptions: props.options || []
    }
    this.onOutsideClick = this.onOutsideClick.bind(this)
    this.onSelectKeydown = this.onSelectKeydown.bind(this)
    this.activate = this.activate.bind(this)
    this.deactivate = this.deactivate.bind(this)
    this.select = this.select.bind(this)
    this.onInputKeyDown = this.onInputKeyDown.bind(this)
    this.onInputKeyUp = this.onInputKeyUp.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.onOptionMouseEnter = this.onOptionMouseEnter.bind(this)
    this.clear = this.clear.bind(this)
    this.addValue = this.addValue.bind(this)
    this.getLabel = this.getLabel.bind(this)
    this.getValue = this.getValue.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mouseup', this.onOutsideClick)
    this.setDefaultValue()
  }

  componentWillReceiveProps(props) {
    const { multi } = props
    const value = this.getValue()

    const fullValue = multi
      ? this.state.options.filter(x => value.includes(x.value))
      : this.state.options.filter(x => x.value === value)

    let options = getUniqueOptions(props.options, fullValue)

    this.setState({
      options: [...options],
      filteredOptions: [...options]
    })
  }

  componentDidUpdate() {
    if (this.focusSelectAfterRender) {
      this.focusSelectAfterRender = false
      this.$select.focus()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.onOutsideClick)
  }

  onOutsideClick(e) {
    if (!this.$select.contains(e.target)) this.deactivate()
  }

  onSelectKeydown(e) {
    if (!this.state.isActive && e.keyCode !== 27 && e.keyCode !== 9) {
      // escape, tab
      this.setState({ isActive: true })
    }
  }

  activate() {
    if (this.props.disabled) return
    if (this.state.isActive) {
      this.$input.focus()
    } else {
      this.setState({ isActive: true })
    }
  }

  deactivate() {
    if (!this.state.isActive) return
    this.setState({
      isActive: false,
      filteredOptions: this.state.options
    })
  }

  select(value) {
    const { multi, onChange, transformSelection = value => value } = this.props

    if (isUndefined(this.props.value)) {
      this.setState(transformSelection({ value }))
    }

    if (multi) {
      if (onChange) {
        const fullValues = value.map(val => ({
          ...this.state.options.find(opt => opt.value === val)
        }))
        onChange(transformSelection(value, fullValues), fullValues)
      }
    } else {
      if (onChange) {
        const fullValue = this.state.options.find(opt => opt.value === value)
        onChange(transformSelection(value), this.getLabel(value), fullValue)
      }
    }
  }

  onInputKeyUp(e) {
    const { onKeyup } = this.props
    if (onKeyup) {
      //strip non-alphanumerics
      e.target.value = e.target.value.replace(/\W+/g, ' ')
      onKeyup(e)
    }
  }

  onInputKeyDown(e) {
    this.focusSelectAfterRender = false
    const kc = e.keyCode
    const $active = this.$select.querySelector('.is-active')

    if (kc === 27) {
      // escape
      this.deactivate()
    } else if (kc === 13) {
      // enter
      if ($active) {
        const value = $active.getAttribute('data-value')
        this.focusSelectAfterRender = true
        this.addValue(value)
      } else if (this.props.adhoc && this.$input.value) {
        this.createValue(this.$input.value)
        this.focusSelectAfterRender = true
      }
    } else if (kc === 40 || kc === 38) {
      const { $options } = this
      const $optionsHeight = $options.getBoundingClientRect().height
      const $activeHeight = $active.getBoundingClientRect().height

      if (kc === 40) {
        // arrow down
        if ($options.lastElementChild === $active) {
          $active.classList.remove('is-active')
          $options.firstElementChild.classList.add('is-active')
          $options.scrollTop = 0
        } else if ($active) {
          $active.classList.remove('is-active')
          $active.nextElementSibling.classList.add('is-active')
          if ($active.offsetTop > $options.scrollTop + $optionsHeight / 1.4) {
            $options.scrollTop = $options.scrollTop + $activeHeight
          }
        }
      } else if (kc === 38) {
        // arrow up
        if ($options.firstElementChild === $active) {
          $active.classList.remove('is-active')
          $options.lastElementChild.classList.add('is-active')
          $options.scrollTop = $options.scrollHeight
        } else if ($active) {
          $active.classList.remove('is-active')
          $active.previousElementSibling.classList.add('is-active')
          if ($active.offsetTop < $options.scrollTop + $optionsHeight / 2.4) {
            $options.scrollTop = $options.scrollTop - $activeHeight
          }
        }
      }
    }
  }

  onInputChange(e) {
    let filteredOptions = this.$input
      ? this.state.options.filter(
          opt =>
            opt.label &&
            this.stringContainsAll(opt.label.toLowerCase(), this.$input.value.toLowerCase())
        )
      : this.state.filteredOptions
    this.setState({
      filteredOptions
    })
  }

  setDefaultValue() {
    // enter default value as if pasted
    if (this.props.defaultValue) {
      this.onInputKeyUp({ target: { value: this.props.defaultValue } })
    }
  }

  onOptionMouseEnter(e) {
    const $active = this.$select.querySelector('.is-active')
    if ($active) $active.classList.remove('is-active')
    e.target.classList.add('is-active')
  }

  clear() {
    if (this.props.disabled) return
    const $active = this.$select.querySelector('.is-active')
    if ($active) $active.classList.remove('is-active')
    if (this.input) this.$input.value = ''
    this.select(this.props.multi ? [] : null)
  }

  addValue(value) {
    this.deactivate()
    if (this.props.multi) {
      const currentValues = this.getValue()
      if (currentValues.includes(value)) return
      this.select([...currentValues, value])
    } else {
      this.select(value)
    }
  }

  createValue(value) {
    this.deactivate()

    if (this.props.multi) {
      const currentValues = this.getValue()
      if (currentValues.includes(value)) {
        return
      }
      const item = { value: value, label: value }
      // Adds the new item to the list of options
      this.setState(
        state => ({ options: [...state.options, item] }),
        () => {
          this.select([...currentValues, value])
        }
      )
    } else {
      this.select(value)
    }
  }

  getLabel(value) {
    const { options } = this.state
    return (options.find(opt => value === opt.value) || { label: '' }).label
  }

  getValue() {
    const { value = this.state.value } = this.props
    return this.props.multi ? (isArray(value) ? value : []) : value
  }

  resolveDomRef(value, el) {
    const { multi, reff } = this.props
    if (!el) return
    this.$select = el
    if (reff) {
      el.value = value
      if (multi) {
        el.valuesWithLabels = value.map(val => ({
          value: val,
          label: this.getLabel(val)
        }))
      } else {
        el.label = this.getLabel(value)
      }
      reff(el)
    }
  }

  renderSearchInput = () => {
    const { withSearch = true } = this.props
    return (
      <input
        className={classnames(st.select_input, { select_input_hidden: !withSearch })}
        type="text"
        placeholder="Search..."
        ref={el => (this.$input = el)}
        autoFocus
        onKeyDown={this.onInputKeyDown}
        onChange={this.onInputChange}
        onKeyUp={this.onInputKeyUp}
        disabled={this.props.disabled}
      />
    )
  }

  stringContainsAll = (string, searches) => {
    searches = searches.replace(/\W+/g, ' ')
    let arr = searches.split(' ').filter(opt => opt.length)
    let valid = every(arr, val => {
      return string.indexOf(val) > -1
    })
    return valid
  }

  render() {
    const {
      className,
      inputClassName,
      style,
      styleType = 'line',
      placeholder = '',
      label,
      labelClass,
      name,
      invalid,
      displayClass,
      multi,
      triangle = true,
      reff,
      copy,
      dropDirection = 'down',
      disabled
    } = this.props
    let { isActive, filteredOptions } = this.state

    const value = this.getValue()
    const valueIsEmpty = multi ? !value.length : isNil(value) || value === ''

    filteredOptions = this.$input
      ? this.state.options.filter(
          opt =>
            opt.label &&
            this.stringContainsAll(opt.label.toLowerCase(), this.$input.value.toLowerCase())
        )
      : filteredOptions

    if (multi) {
      filteredOptions = filteredOptions.filter(opt => !value.includes(opt.value))
    }

    return (
      <div
        className={classnames('form_element form_element-select', className, {
          'is-invalid': invalid
        })}
      >
        {label ? (
          <label className={classnames('form_label', st[labelClass])} onClick={this.activate}>
            {label}
          </label>
        ) : null}
        <div
          ref={this.resolveDomRef.bind(this, value)}
          id={`fe-${name}`}
          style={style}
          tabIndex={disabled ? undefined : '0'}
          onKeyDown={this.onSelectKeydown}
          className={classnames(st.select, st[`select_${styleType}`], inputClassName, {
            [`${st['is-invalid']}`]: invalid,
            [`${st['is-disabled']}`]: disabled
          })}
        >
          <div className={st['select_value-cont']} onClick={this.activate}>
            {!disabled && (
              <div
                className={classnames({
                  [`${st['select_triangle']}`]: triangle,
                  [`${st['select_triangle_hidden']}`]: !triangle
                })}
              />
            )}
            {!valueIsEmpty && !disabled ? (
              <i
                className={classnames(st['select_clear'], 'i-close')}
                style={{ display: triangle ? 'inherit' : 'none' }}
                onClick={this.clear}
              />
            ) : null}
            {!valueIsEmpty && !disabled ? (
              multi ? (
                <div className={st['select_value-multi']}>
                  {value.map(val => (
                    <div key={val} className={st['select_value-multi-item']}>
                      {this.getLabel(val)}
                      <i
                        className="i-close"
                        onClick={() => {
                          this.select([...this.getValue()].filter(v => v !== val))
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : this.getLabel(value) ? (
                <div className={displayClass || st['select_value-single']}>
                  {this.getLabel(value)}
                </div>
              ) : (
                <div className={displayClass || st['select_placeholder']}>{placeholder || ''}</div>
              )
            ) : (
              <div className={displayClass || st['select_placeholder']}>{placeholder || ''}</div>
            )}
          </div>
          {isActive ? (
            <div
              className={classnames({
                [`${st['select_dropdown']}`]: dropDirection === 'down',
                [`${st['select_dropup']}`]: dropDirection === 'up'
              })}
            >
              {dropDirection === 'down' && this.renderSearchInput()}
              <div className={st['select_options']} ref={el => (this.$options = el)}>
                {filteredOptions.map((opt, i) => (
                  <div
                    key={opt.value}
                    className={classnames(st['select_option'], {
                      [`${st['is-active']}`]: i === 0,
                      [`${st['is-selected']}`]: opt.value === value
                    })}
                    data-value={opt.value}
                    onMouseEnter={this.onOptionMouseEnter}
                    onClick={() => this.addValue(opt.value)}
                  >
                    {opt.label}
                  </div>
                ))}
                {!filteredOptions.length && this.$input && this.$input.value.length > 0 ? (
                  <div className={st['select_options-empty-label']}>
                    {this.props.adhoc
                      ? copy.shared('select.noResultsWithAdhocEnabled')
                      : copy.shared('select.noResults')}
                  </div>
                ) : null}
              </div>
              {dropDirection === 'up' && this.renderSearchInput()}
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

Select.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  style: PropTypes.object,
  styleType: PropTypes.string,
  value: PropTypes.node,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  dropDirection: PropTypes.string,
  //invalid: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  //multi: PropTypes.bool,
  //disabled: PropTypes.bool,
  reff: PropTypes.func,
  copy: PropTypes.object.isRequired,
  onKeyup: PropTypes.func,
  transformSelection: PropTypes.func,
  //dynamic: PropTypes.bool,
  //withSearch: PropTypes.bool,
  //triangle: PropTypes.bool,
  displayClass: PropTypes.object
  //adhoc: PropTypes.bool
}

Select = Intl({})(Select)

export { Select }
