import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchStoredUser } from 'shared/state/actions'
import Form, { isRequired } from 'shared/higher-order-components/Form'
import { Input, ValidationList, Button } from 'shared/components'
import { storeWidgetData, fetchPortal } from '../../state/wizard/WizardActions'

let Confirm = ({ fields, messages, copy, submit, fetchStoredUser, fetchPortal }) => {
  useEffect(() => {
    const { user } = fetchStoredUser()
    fetchPortal(user.master_entity.id)
  }, [])

  return (
    <div className="form form-widget text-left">
      <div className="form_heading">Before we get started…</div>
      <div className="form_sub-heading">{copy.get('confirm.verify')}</div>
      <ValidationList messages={messages} />
      <div className="row">
        <div className="col-6">
          <Input
            iconLeft="file-archive-o"
            {...fields.vat_no}
            label={copy.get('confirm.vatNum')}
            autoFocus
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Input
            iconLeft="file-archive-o"
            {...fields.confirmVatNum}
            label={copy.get('confirm.confirmVatNum')}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">{copy.get('confirm.note')}</div>
      </div>

      <Button
        onClick={submit}
        className="form_cancel"
        style={{ float: 'right' }}
        size="large"
        type="secondary"
      >
        {copy.get('confirm.numCorrect')}
      </Button>
    </div>
  )
}

Confirm.propTypes = {
  fields: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

Confirm = Form({
  fields: ({ copy }) => ({
    vat_no: [
      isRequired(copy.get('confirm.vatNum')),
      ({ fields }) =>
        (f => {
          if (f.vat_no.value && f.confirmVatNum.value) {
            if (f.vat_no.value === f.confirmVatNum.value) {
              return true
            }
            return false
          }
          return true
        })(fields)
          ? false
          : {
              message: copy.get('vatMustMatch'),
              preventSubmit: true
            }
    ],
    confirmVatNum: [isRequired(copy.get('confirm.confirmVatNum'))]
  }),

  mapPropsToFields: props => (props.portal ? props.portal : false),

  submit: ({ props, fields }) => {
    props.storeWidgetData(fields, false, 'details')
  }
})(Confirm)

const mapStateToProps = ({ account: { wizard }, app }) => ({ ...wizard, portal: app.portal })
const mapDispatchToProps = {
  storeWidgetData,
  fetchStoredUser,
  fetchPortal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirm)
