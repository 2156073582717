import React, { Component } from 'react'
import PropTypes from 'prop-types'
import pubsub from 'sweet-pubsub'
import { loadScript } from 'shared/lib/utils'

window.gmClientCb = () => pubsub.emit('gmClientLoaded')

export default Child => {

	const propTypes = {}

	class GoogleMapsAsyncLoader extends Component {

		constructor() {
			super()
			this.state = { google: window.google }
			this.setGoogle = this.setGoogle.bind(this)

			// loadScript(
			//   `https://maps.googleapis.com/maps/api/js?client=gme-trackmaticsolutions&libraries=drawing,geometry,places&callback=gmClientCb`,
			//   'googleMapsApiScript'
			// )
			pubsub.on('gmClientLoaded', this.setGoogle)
		}

		componentWillUnmount() {
			pubsub.off('gmClientLoaded', this.setGoogle)
		}

		setGoogle() {
			this.setState({ google: window.google })
		}

		render() {
			const { google } = this.state
			return google ? <Child {...this.props} google={google} /> : null
		}
	}

	GoogleMapsAsyncLoader.propTypes = propTypes

	return GoogleMapsAsyncLoader
}
