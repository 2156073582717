import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ListPage from 'shared/higher-order-components/ListPage'
import Intl from 'shared/higher-order-components/Intl'

import { Page, Table, Input, Select, Button, WarningDialog, OrderChevron } from 'shared/components'
import * as actions from '../../../state/customers/customerActions'

import Nav from '../Nav'

class FacilitiesList extends Component {
  componentDidMount() {
    this.search()
  }

  get perPage() {
    return 25
  }

  search(options = {}) {
    this.props.getCustomersByEntityAndId(this.props.orgId, this.props.customerId)
  }

  render() {
    const { copy, fetching, listItems, orgId, customerId } = this.props
    let facilities = []
    if (listItems.length) {
      facilities = listItems[0].facilities
    }
    const customerHeaders = [
      { text: copy.customers('list.vendorcde') },
      { text: copy.customers('list.customer') },
      { text: copy.customers('list.facilities') },
      { text: copy.customers('list.dateAdded') },
      { text: copy.customers('list.status') },
      { width: 2 }
    ]

    const facilityHeaders = [
      { text: copy.facilities('list.id') },
      { text: copy.facilities('list.name') },
      { width: 2 }
    ]

    const customerRows = listItems.map(customer => [
      { text: customer.id },
      { text: customer.name },
      { text: customer.facilities.length },
      { text: customer.created },
      { text: customer.portal_status },
      {
        className: 'is-single-icon',
        content: (
          <Link to={'/portal/' + orgId + '/customers/edit/' + customer.id}>
            <i className="i-edit" />
          </Link>
        )
      }
    ])
    const facilityRows = facilities.map(facility => [
      { text: facility.id },
      { text: facility.name },
      {
        className: 'is-single-icon',
        content: (
          <Link to={'/portal/' + orgId + '/customers/' + facility.id + '/facilities/edit/'}>
            <i className="i-edit" />
          </Link>
        )
      }
    ])

    return (
      <Page title="CustomerFacilities" navContent={<Nav {...this.props} />}>
        <span className="listTable">
          <Table
            headers={customerHeaders}
            title={copy.customers('tablename')}
            rows={customerRows}
            isLoading={fetching}
          />
        </span>
        <span className="listTable">
          <Table
            headers={facilityHeaders}
            title={copy.facilities('tablename')}
            rows={facilityRows}
            isLoading={fetching}
          />
        </span>
      </Page>
    )
  }
}

FacilitiesList.propTypes = {
  copy: PropTypes.object.isRequired
}

FacilitiesList = ListPage()(FacilitiesList)

const mapStateToProps = ({ shared }, { params, location, match }) => ({
  query: location.query,
  ...params,
  ...shared.list,
  ...match.params
})

const mapDispatchToProps = actions

FacilitiesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(FacilitiesList)

export default Intl({
  customers: 'portal.customers',
  facilities: 'portal.customers.facilities',
  search: 'portal.customers.search'
})(FacilitiesList)
