import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ListPage from 'shared/higher-order-components/ListPage'
import Intl from 'shared/higher-order-components/Intl'
import { Page, Table, Input, Button } from 'shared/components'
import * as actions from '../../state/customers/customerActions'
import { formatDateTime } from 'shared/lib/dateTime'

class FacilitiesList extends Component {
  componentDidMount() {
    this.search()
  }

  get perPage() {
    return 25
  }

  search(options = {}) {
    const { searchCustomersByEntity, orgId } = this.props

    searchCustomersByEntity(orgId, {
      perPage: this.perPage,
      term: this.$termInput.value || '',
      ...options
    })
  }

  capitalize = letter => `${letter.substring(0, 1).toUpperCase()}${letter.substring(1)}`

  render() {
    const { copy, fetching, listItems, orgId } = this.props

    const headers = [
      { text: copy.list('vendorcde') },
      { text: copy.list('customer') },
      { text: copy.list('facilities') },
      { text: copy.list('dateAdded') },
      { text: copy.list('status') },
      { width: 2 }
    ]
    const rows =
      listItems &&
      listItems.map(customer => [
        { text: customer.id },
        {
          content: (
            <Link to={`/portal/${orgId}/customers-facilities/${customer.id}`}>{customer.name}</Link>
          )
        },
        { text: customer.facilities.length },
        { text: formatDateTime(customer.created) },
        { text: this.capitalize(customer.portal_status) },
        {
          className: 'is-single-icon',
          content: (
            <Link to={`/portal/${orgId}/customers-contacts/${customer.id}/contacts`}>
              <i className="i-eye" />
            </Link>
          )
        }
      ])

    const renderSearchOpt = () => {
      return (
        <>
          <Input
            reff={el => (this.$termInput = el)}
            styleType="box"
            name="filter"
            placeholder={copy.search('term')}
            defaultValue=""
            style={{ width: 180 }}
          />

          <Button
            className="listTable_top-bar-filter"
            onClick={() => this.search()}
            style={{ marginRight: '0px', width: 140 }}
          >
            {copy.search('buttonText')}
          </Button>
        </>
      )
    }

    return (
      <Page title="Customers">
        <span className="listTable">
          <div className="listTable_top-bar">{renderSearchOpt()}</div>
          <Table headers={headers} rows={rows} isLoading={fetching} />
        </span>
      </Page>
    )
  }
}

FacilitiesList.propTypes = {
  copy: PropTypes.object.isRequired,
  searchCustomersByEntity: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  fetching: PropTypes.bool,
  listItems: PropTypes.array.isRequired
}

FacilitiesList = ListPage()(FacilitiesList)

const mapStateToProps = ({ shared }, { params, location, match }) => ({
  query: location.query,
  ...params,
  ...shared.list,
  ...match.params
})

const mapDispatchToProps = actions

FacilitiesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(FacilitiesList)

export default Intl({
  list: 'portal.customers.list',
  search: 'portal.customers.search'
})(FacilitiesList)
