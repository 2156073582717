/* eslint-disable no-undef */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter as Router } from 'connected-react-router'
import { getParameterByName } from 'shared/lib/utils'
import storage from 'shared/lib/storage'
import configureStore, { history } from './store'
import { unregister } from './serviceWorker'
// import ReactGA from 'react-ga'
import '../node_modules/react-custom-scroll/dist/customScroll.css'
import './index.scss'
import Routes from './routes'

// Pulls the token out of the url if provided and sets local storage
// This is used for external links embedded in emails
const token = getParameterByName('jwt', window.location)

if (token) {
  storage.local.set('v1.tokenString', token)
}

// Wires up google analytics
// ReactGA.initialize('UA-52197106-2')
// history.listen(location => {
//   ReactGA.pageview(location.pathname)
// })

const store = configureStore()
// eslint-disable-next-line no-underscore-dangle
window.__STORE__ = store

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Routes history={history} />
    </Router>
  </Provider>,
  document.getElementById('root')
)

unregister()
