/* global google */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { render } from 'react-dom'
import { MAP } from 'react-google-maps/lib/constants'
import GoogleMapsAsyncLoader from 'shared/higher-order-components/GoogleMapsAsyncLoader'

class MapControl extends React.Component {
	static contextTypes = {
		[MAP]: PropTypes.object
	}

	static defaultProps = {
		controlPosition: 5, //google.maps.ControlPosition.LEFT_TOP
	}

	static propTypes = {
		children: PropTypes.node,
		controlPosition: PropTypes.number,
		style: PropTypes.object
	}

	componentDidMount() {
		this.map = this.context[MAP]
		this._render()
	}

	componentDidUpdate() {
		this._render()
	}

	componentWillUnmount() {
		const { controlPosition } = this.props
		const index = this.map.controls[controlPosition].getArray().indexOf(this.el)
		this.map.controls[controlPosition].removeAt(index)
	}

	_render() {
		const { controlPosition = google.maps.ControlPosition.LEFT_TOP, style, children } = this.props
		const defaultStyle = { backgroundColor: '#fff', margin: 10 }

		render(
			<div
				style={{ ...defaultStyle, ...style }}
				ref={el => {
					if (!this.renderedOnce) {
						this.el = el
						this.map.controls[controlPosition].push(el)
					} else if (el && this.el && el !== this.el) {
						this.el.innerHTML = ''
							;[].slice.call(el.childNodes).forEach(child => this.el.appendChild(child))
					}
					this.renderedOnce = true
				}}>
				{children}
			</div>,
			document.createElement('div')
		)
	}

	render() {
		return <noscript />
	}
}

export default GoogleMapsAsyncLoader(MapControl)