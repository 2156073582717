import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isFinite, get } from 'lodash'
import GoogleMapsAsyncLoader from 'shared/higher-order-components/GoogleMapsAsyncLoader'
import Intl from 'shared/higher-order-components/Intl'
import { Input, Select, Button } from 'shared/components'
import { geocode, DegMinSecToDecimal, geocodeaddress } from 'shared/lib/geo'
import AddressForm from './AddressForm'

const propTypes = {
	google: PropTypes.object.isRequired,
	closeModal: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	updateAddress: PropTypes.func.isRequired,
	addressData: PropTypes.object.isRequired,
	copy: PropTypes.object.isRequired,
}

class AvtmGpsCoordinatesTab extends Component {
	constructor(props) {
		super(props)
		this.state = {
			latLngDecimal: '',
			latDeg: '', latMin: '', latSec: '', latOrient: '',
			lngDeg: '', lngMin: '', lngSec: '', lngOrient: '',
		}
		this.updateCoords = this.updateCoords.bind(this)
	}

	updateCoords(field, value) {
		value = value.replace(/ /g, '')
		if (['latDeg', 'latMin', 'latSec', 'lngDeg', 'lngMin', 'lngSec'].includes(field)) {
			if (!isFinite(+value)) return
		} else if (field === 'latLngDecimal') {
			let split = value.split(',')

			if (split[0] && !isFinite(+split[0]) && split[0] !== '-') return
			value = split[0]

			if (split[1] && !isFinite(+split[1]) && split[1] !== '-') return
			value += split.length > 1 ? `,${split[1]}` : ''
		}

		this.setState({ [field]: value })
	}

	submitCoords() {
		const { google, onChange, copy } = this.props
		const {
		latLngDecimal,
				latDeg, latMin, latSec, latOrient,
				lngDeg, lngMin, lngSec, lngOrient,
		} = this.state

		let coords = []

		if (latLngDecimal) {
			let split = latLngDecimal.split(',')
			if (split.length) coords = [+split[0], +(split[1] || '')]
		} else {
			coords = [
				DegMinSecToDecimal(latDeg, latMin, latSec, latOrient),
				DegMinSecToDecimal(lngDeg, lngMin, lngSec, lngOrient),
			]
		}

		geocode(google, { location: new google.maps.LatLng(...coords) }, onChange)
	}

	onSelect = (e) => {
		const { options } = this.state
		let address =  options.find(item => item.value === e)
		let data = get(address, 'address')
		this.props.updateAddress(data)
	}

	render() {
		const { closeModal, onChange, addressData, copy } = this.props
		
		return (
			<div className="avtm_gpsct">
				<div className="avtm_gpsct-top">
					<div className="row">
						<div className="col-3">
							<Input
								label={copy.avt('gpsCoordinates')}
								value={this.state.latLngDecimal}
								placeholder="lat,lng"
								onChange={v => this.updateCoords('latLngDecimal', v)}
							/>
						</div>
						<div className="col-1">
							<div className="avtm_gpsct-top-or">OR</div>
						</div>
						<div className="col-8">
							<div className="avtm_gpsct-top-normal">
								<Input
									label={copy.avt('latDeg')}
									placeholder="°"
									value={this.state.latDeg}
									onChange={v => this.updateCoords('latDeg', v)}
								/>
								<Input
									label={copy.avt('latMin')}
									placeholder="'"
									value={this.state.latMin}
									onChange={v => this.updateCoords('latMin', v)}
								/>
								<Input
									label={copy.avt('latSec')}
									placeholder="''"
									value={this.state.latSec}
									onChange={v => this.updateCoords('latSec', v)}
								/>
								<Select
									label="S / N"
									options={[{ label: 'S', value: 'S' }, { label: 'N', value: 'N' }]}
									value={this.state.latOrient}
									onChange={v => this.updateCoords('latOrient', v)}
								/>
								<Input
									label={copy.avt('lngDeg')}
									placeholder="°"
									value={this.state.lngDeg}
									onChange={v => this.updateCoords('lngDeg', v)}
								/>
								<Input
									label={copy.avt('lngMin')}
									placeholder="'"
									value={this.state.lngMin}
									onChange={v => this.updateCoords('lngMin', v)}
								/>
								<Input
									label={copy.avt('lngSec')}
									placeholder="''"
									value={this.state.lngSec}
									onChange={v => this.updateCoords('lngSec', v)}
								/>
								<Select
									label="E / W"
									options={[{ label: 'E', value: 'E' }, { label: 'W', value: 'W' }]}
									value={this.state.lngOrient}
									onChange={v => this.updateCoords('lngOrient', v)}
								/>
							</div>
						</div>
					</div>
					<Button
							className="form_submit"
							size="small"
							iconRight="search"
							onClick={this.submitCoords.bind(this)}
						>
						{copy.shared('search')}
					</Button>
				</div>
				<AddressForm
					onChange={onChange}
					closeModal={closeModal}
					addressData={addressData}
				/>
			</div>
		)
	}
}

AvtmGpsCoordinatesTab.propTypes = propTypes

AvtmGpsCoordinatesTab = GoogleMapsAsyncLoader(AvtmGpsCoordinatesTab)

export default Intl({ avt: 'shared.avt' })(AvtmGpsCoordinatesTab)
