import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Intl from 'shared/higher-order-components/Intl'
import Form from 'shared/higher-order-components/Form'
import { Page, FormSubmit, Input, Button, ValidationList } from 'shared/components'
import Nav from './Nav'
import * as actions from '../../state/customers/customerActions'

class CustomersForm extends Component {
  componentDidMount() {
    const { formType, orgId, customerId, getCustomersByEntityAndId } = this.props
    if (formType === 'edit') getCustomersByEntityAndId(orgId, customerId)
  }

  render() {
    const { orgId, customerId, formType, fields, messages, submit, formStatus, copy } = this.props
    return (
      <Page title="Customers" navContent={<Nav {...this.props} />}>
        <div className="form form-small">
          <div className="form_heading">{copy.get('heading')}</div>
          <div className="form_sub-heading" />
          <ValidationList messages={messages} />
          <div className="row">
            <div className="col-6">
              <Input iconLeft="person" {...fields.name} label={copy.get('name')} autoFocus />
            </div>

            <div className="col-6">
              <Input iconLeft="flash_on" {...fields.portal_status} label={copy.get('Status')} />
            </div>
          </div>

          <Link to={`/portal/${orgId}/customers`}>
            <Button className="form_cancel" size="large" type="secondary">
              {copy.get('cancel')}
            </Button>
          </Link>

          <FormSubmit
            formType={formType}
            formStatus={formStatus}
            onClick={submit}
            icons=""
            text={{
              clean: copy.get('Save'),
              touched: copy.get('Save'),
              working: copy.get('Saving')
            }}
            style={{ width: '50%', textAlign: 'center' }}
          />
        </div>
      </Page>
    )
  }
}

CustomersForm.propTypes = {
  formType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  formItem: PropTypes.object.isRequired,
  formLoaded: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

CustomersForm = Form({
  fields: () => ({
    name: [],
    portal_status: []
  }),

  mapPropsToFields: props => (props.formType === 'edit' ? props.formItem : false),

  isFormReady: ({ props }) => props.formLoaded || props.formType === 'create',

  submit({ props, fields }) {
    if (props.formType === 'create') {
      props.addCustomerByEntity(props.orgId, fields)
    } else {
      props.updateCustomerByEntity(props.orgId, props.customerId, fields)
    }
  }
})(CustomersForm)

const mapStateToProps = ({ shared }, { params, match }) => ({
  ...params,
  ...shared.form,
  ...match.params
})

const mapDispatchToProps = actions

CustomersForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomersForm)

export default Intl({
  get: 'portal.customers.form'
})(CustomersForm)
