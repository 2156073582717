import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'shared/components'
import checkSolid from 'shared/assets/check-solid.png'
import storage from 'shared/lib/storage'
import { storePortalSetup } from '../../state/wizard/WizardActions'

const WizardFinish = ({ id, name, wizard, storePortalSetup }) => {
  const orgId = id || storage.local.getObj('user').master_entity.id
  const orgName = name || storage.local.getObj('user').master_entity.name

  return (
    <div className="form form-widget text-left">
      <div className="user-container">
        <img className="add-users" src={checkSolid} alt="trackmatic" />
        <div className="form_heading">
          Congratulations <span>{orgName}</span>
        </div>
        <div className="form_sub-heading">
          Your Supplier Portal profile has been successfully created.
        </div>

        <Button
          onClick={() => storePortalSetup(orgId, wizard.data)}
          style={{ width: '30%' }}
          size="large"
          type="primary"
        >
          Go to your Dashboard
        </Button>
      </div>
    </div>
  )
}

WizardFinish.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  wizard: PropTypes.object.isRequired,
  storePortalSetup: PropTypes.func.isRequired
}

const mapStateToProps = ({ app: { user }, account }) => ({
  ...user.master_entity,
  ...account
})

export default connect(
  mapStateToProps,
  { storePortalSetup }
)(WizardFinish)
