import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formatLat, formatLng } from 'shared/lib/geo'
import Intl from 'shared/higher-order-components/Intl'
import Form from 'shared/higher-order-components/Form'
import { Button, Input } from 'shared/components'

const propTypes = {
  addressData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
}

// eslint-disable-next-line react/prefer-stateless-function
class AvtmAddressForm extends Component {
  render() {
    const { closeModal, fields, updateField, submit, copy } = this.props

    return (
      <div className="form avtm_addressForm">
        <div className="avtm_addressForm-heading">Cleaned Address Data</div>
        <div className="row">
          <div className="col-2">
            <Input {...fields.unit_no} label={copy.form('unit_no')} />
          </div>
          <div className="col-5">
            <Input {...fields.building_name} label={copy.form('building_name')} />
          </div>
          <div className="col-2">
            <Input {...fields.street_no} label={copy.form('street_no')} />
          </div>
          <div className="col-3">
            <Input {...fields.sub_division_number} label={copy.form('sub_division_number')} />
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            <Input {...fields.street} label={copy.form('street')} />
          </div>
          <div className="col-5">
            <Input {...fields.suburb} label={copy.form('suburb')} />
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <Input {...fields.city} label={copy.form('city')} />
          </div>
          <div className="col-5">
            <Input {...fields.province} label={copy.form('province')} />
          </div>
          <div className="col-2">
            <Input {...fields.postal_code} label={copy.form('postal_code')} />
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <Input
              {...fields.coords}
              onChange={() => updateField('coords', fields.coords.value)}
              label={copy.form('coords')}
              value={
                !fields.coords.value
                  ? ''
                  : `${formatLat(fields.coords.value[1])} ${formatLng(fields.coords.value[0])}`
              }
            />
          </div>
        </div>
        <Button onClick={closeModal} className="form_cancel" type="secondary">
          {copy.shared('cancel')}
        </Button>
        <Button onClick={submit} className="form_submit" iconRight="upload">
          {copy.shared('save')}
        </Button>
      </div>
    )
  }
}

AvtmAddressForm.propTypes = propTypes

AvtmAddressForm = Form({
  fields: () => ({
    unit_no: [],
    building_name: [],
    street_no: [],
    sub_division_number: [],
    street: [],
    suburb: [],
    city: [],
    province: [],
    postal_code: [],
    coords: [],
    location: []
  }),

  mapPropsToFields: props => props.addressData,
  shouldReceiveFields: () => true,

  submit({ props, fields }) {
    props.onChange(fields, true)
    props.closeModal()
  }
})(AvtmAddressForm)

export default Intl({ form: 'shared.avt.form' })(AvtmAddressForm)
