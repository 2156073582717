import React, { Component } from 'react'
import PropTypes from 'prop-types'

const MapTooltipItem = ({ label, value }) => (
	<div className="map_tooltip-item">
		<div className="map_tooltip-key">{label}:</div>
		<div className="map_tooltip-value">{value}</div>
	</div>
)

MapTooltipItem.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired
}

export default MapTooltipItem