import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from 'shared/components'
import Intl from 'shared/higher-order-components/Intl'

class FilterOptions extends Component {
  static propTypes = {
    filters: PropTypes.func.isRequired,
    copy: PropTypes.object.isRequired,
    children: PropTypes.any
  }

  constructor(props) {
    super(props)
    this.state = { showFilterOptions: false }
  }

  filterToggle = () => {
    this.setState(state => ({
      showFilterOptions: !state.showFilterOptions
    }))
  }

  render() {
    const { filters, copy } = this.props

    return (
      <div className="filter">
        <Button
          className={classnames('listTable_top-bar-filter', {
            'is-open-filter': this.state.showFilterOptions
          })}
          onClick={this.filterToggle.bind(this)}
        >
          {copy.shared('filter')}
        </Button>
        <div className={!this.state.showFilterOptions ? 'filter-options_hidden' : 'filter-options'}>
          <Button className="listTable_top-bar-filter" onClick={filters}>
            {copy.shared('applyFilter')}
          </Button>
          <Button
            className="form_cancel"
            type="secondary"
            style={{ right: 140 }}
            onClick={this.filterToggle.bind(this)}
          >
            {copy.shared('close')}
          </Button>
          <div className="filter-options_box">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

FilterOptions = Intl({})(FilterOptions)

export { FilterOptions }
