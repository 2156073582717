import React from 'react'
import { Link } from 'react-router-dom'
import { Page } from 'shared/components'
import clientErrorImg from 'shared/assets/404.png'

const NotFound = () => {
  return (
    <span>
      <Page title="Not Found">
        <div className="redirect_container">
          <div className="redirect_container_content">
            <div className="redirect_container_content_title">
              Sorry the page you are looking for is not available.
            </div>
            <div className="redirect_container_content_icon">
              <img className="redirect_img" src={clientErrorImg} alt="404" />
            </div>
            <div className="redirect_container_content_footertxt">
              <div className="error-code">Error: 404 Page Not Found</div>
              <div className="redirect-txt">
                Please return to the previous page or go back to the <Link to="/">dashboard</Link>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </span>
  )
}

export default NotFound
