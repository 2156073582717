import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, ValidationList, Input } from 'shared/components'
import Form, { isRequired } from 'shared/higher-order-components/Form'
import { Link } from 'react-router-dom'
import * as actions from '../../state/wizard/WizardActions'

let AccountManage = ({ fields, messages, submit, copy }) => {
  return (
    <div className="form form-widget text-left">
      <div className="form_heading">{copy.get('account.desc')}</div>
      <div className="form_sub-heading" />

      <ValidationList messages={messages} />
      <div className="row">
        <div className="col-6">
          <Input iconLeft="person" {...fields.name} label={copy.get('account.name')} autoFocus />
        </div>
        <div className="col-6">
          <Input iconLeft="person" {...fields.surname} label={copy.get('account.surname')} />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Input
            iconLeft="fingerprint"
            {...fields.idNum}
            label={copy.get('account.idNum')}
            autoFocus
          />
        </div>
        <div className="col-6">
          <Input iconLeft="mobile" {...fields.mobile} label={copy.get('account.mobile')} />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Input
            iconLeft="mail_outline"
            {...fields.email}
            label={copy.get('account.email')}
            autoFocus
          />
        </div>
        <div className="col-6">
          <Input iconLeft="mobile" {...fields.officeNum} label={copy.get('account.officeNum')} />
        </div>
      </div>

      <Link to="/setup/users">
        <Button className="form_cancel" size="large" type="secondary">
          Skip
        </Button>
      </Link>

      <Button
        onClick={submit}
        className="form_cancel"
        style={{ float: 'right', width: '50%' }}
        size="large"
        type="primary"
      >
        Next
      </Button>
    </div>
  )
}

AccountManage.propTypes = {
  fields: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

AccountManage = Form({
  fields: ({ copy }) => ({
    name: [isRequired(copy.get('account.name'))],
    surname: [],
    idNum: [],
    mobile: [],
    email: [],
    officeNum: []
  }),
  submit: ({ props, fields }) => {
    props.storeWidgetData(fields, false, 'users')
  }
})(AccountManage)

const mapDispatchToProps = actions

export default connect(
  null,
  mapDispatchToProps
)(AccountManage)
