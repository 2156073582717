import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Intl from 'shared/higher-order-components/Intl'
import { Nav } from 'shared/components'
import App from 'app/containers/App'
import getNavItems from 'shared/static/modules'
import Footer from './Footer'

let SupplierPortalModule = ({ children, copy, user, location, ...props }) => {
  const navOptions = user.master_entity && getNavItems(user.master_entity.id)

  const currentOption = (navOptions &&
    navOptions.find(
      ({ link }) => location.pathname.includes(link) // eslint-disable-line
    )) || { title: 'Supplier Portal' }

  return (
    <App {...props}>
      <Nav currentModule="supplier-portal" currentOption={currentOption.title} />
      <div className="module-portal">{children}</div>
      <Footer />
    </App>
  )
}

SupplierPortalModule.propTypes = {
  children: PropTypes.element.isRequired,
  copy: PropTypes.object.isRequired
}

const mapStateToProps = ({ router, app }) => ({
  ...router,
  ...app
})

SupplierPortalModule = connect(
  mapStateToProps,
  null
)(SupplierPortalModule)

export default Intl({ get: 'portal' })(SupplierPortalModule)
