/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Intl from 'shared/higher-order-components/Intl'
import { Input, ValidationList, FormSubmit, UsernameInput } from 'shared/components'
import Form, { isRequired, serverMessage } from 'shared/higher-order-components/Form'
import imageLogo from 'shared/assets/logo-light.svg'
import storage from 'shared/lib/storage'
import * as actions from '../state/RegisterActions'

class Complete extends Component {
  render() {
    const { fields, messages, copy, submit, formStatus, setValidationMessages } = this.props
    return (
      <div className="splash center-cont" style={{ height: '100%' }}>
        <div className="account__login splash-box center">
          <div className="splash-logo-holder">
            <img className="splash-logo" src={imageLogo} alt="trackmatic" />
            <div className="splash-heading">{copy.get('heading')}</div>
          </div>
          <ValidationList messages={messages} icon="i-exclamation" customIconClass="splash-round" />

          <UsernameInput
            {...fields.user_name}
            styleType="line"
            iconLeft="mail_outline"
            placeholder={copy.get('username')}
            setValidationMessages={setValidationMessages}
            disabled={false}
          />

          {/* <Input
            {...fields.user_name}
            id="username"
            styleType="line"
            iconLeft="mail_outline"
            placeholder={copy.get('username')}
          /> */}

          <Input
            {...fields.password}
            styleType="line"
            iconLeft="fingerprint"
            placeholder={copy.get('password')}
          />

          <Input
            {...fields.verifyPassword}
            styleType="line"
            iconLeft="fingerprint"
            placeholder={copy.get('passwordConfirmation')}
          />

          <FormSubmit
            kind="primary"
            size="large"
            formType="create"
            formStatus={formStatus}
            text={{
              clean: copy.get('submit'),
              touched: copy.get('submit'),
              working: copy.get('submitting')
            }}
            icons=""
            onClick={submit}
            style={{ width: '100%', textAlign: 'center' }}
          />
        </div>
      </div>
    )
  }
}

Complete.propTypes = {
  fields: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired,
  setValidationMessages: PropTypes.func.isRequired
}

Complete = Form({
  fields: ({ copy }) => ({
    user_name: [isRequired(copy.get('username')), serverMessage('username')],
    password: [
      serverMessage('password'),
      isRequired(copy.get('password')),
      ({ fields }) =>
        (f => {
          if (f.password.value && f.verifyPassword.value) {
            if (f.password.value === f.verifyPassword.value) {
              return true
            }
            return false
          }
          return true
        })(fields)
          ? false
          : {
              message: copy.get('passwordsMustMatch'),
              preventSubmit: true
            }
    ],
    verifyPassword: [
      serverMessage('password_confirmation'),
      isRequired(copy.get('passwordConfirmation'))
    ]
  }),

  submit({ props, fields }) {
    const data = storage.local.getObj('access_data')
    props.complete(fields, data)
  }
})(Complete)

const mapStateToProps = ({ shared, account: { register } }, { params }) => ({
  ...register,
  ...shared.form,
  ...params
})
const mapDispatchToProps = actions

Complete = connect(
  mapStateToProps,
  mapDispatchToProps
)(Complete)

export default Intl({ get: 'account.auth' })(Complete)
