import api, { handleError } from 'shared/api/index'
import { updateQuery } from 'shared/state/actions'
import { paginateQuery } from 'shared/api/utils'
import { push } from 'connected-react-router'
import { toClient, userApi } from './resource'

const path = 'link/suppliers'

const fetchedItems = (items, pagination) => {
  return {
    type: 'shared/list/FETCHED_ITEMS',
    items,
    pagination
  }
}

export const searchUsersByEntity = (entityId, query = {}) => dispatch => {
  query = dispatch(updateQuery(query))
  dispatch({ type: 'shared/list/FETCHING' })
  api.get(`${path}/${entityId}/users`, paginateQuery(query)).then(
    ({ data, body: { pagination } }) => {
      const items = data.map(item => toClient(item))
      dispatch(fetchedItems(items, pagination))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const addUserByEntity = (entityId, user) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.post(`${path}/${entityId}/users`, userApi.user(user)).then(
    () => {
      dispatch(push(`/portal/${entityId}/users`))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const getUserByEntity = (entityId, userId) => dispatch => {
  api.get(`${path}/${entityId}/users/${userId}`).then(
    ({ data }) => {
      dispatch({
        type: 'shared/form/FETCHED_ITEM',
        item: data
      })
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const updateUserByEntity = (entityId, userId, user) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.put(`${path}/${entityId}/users/${userId}`, userApi.user(user)).then(
    () => {
      dispatch(push(`/portal/${entityId}/users`))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const addMembershipByEntity = (entityId, userId, orgId, permissions) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.put(`${path}/${entityId}/users/${userId}/memberships`, permissions).then(
    () => {
      dispatch(push(`/portal/${orgId}/users`))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const renameUserByEntity = (entityId, userId, orgId, user) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.put(`${path}/${entityId}/users/${userId}/rename`, user).then(
    () => {
      dispatch(push(`/portal/${orgId}/users`))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const getUserAccessLogsByEntity = (entityId, userId, query = {}) => dispatch => {
  query = dispatch(updateQuery(query))
  dispatch({ type: 'shared/list/FETCHING' })

  api.get(`${path}/${entityId}/users/${userId}/access_logs`, paginateQuery(query)).then(
    ({ body }) => {
      const items = body.data.map(item => toClient(item.data))
      dispatch(fetchedItems(items, body.pagination))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/list/FETCH_ERROR' })
      handleError({ errors })
    }
  )
}

export const blockUserByEntity = (entityId, userId) => dispatch => {
  api.patch(`${path}/${entityId}/users/${userId}/block`).then(() => {
    dispatch(push(`/portal/${entityId}/users`))
  })
}

export const unblockUserByEntity = (entityId, userId) => dispatch => {
  api.patch(`${path}/${entityId}/users/${userId}/unblock`).then(() => {
    dispatch(push(`/portal/${entityId}/users`))
  })
}

export const fetchLinkedCustomers = (entityId, customerId) => dispatch => {
  dispatch({ type: 'shared/item/FETCHING' })
  api.get(`${path}/${entityId}/customers/${customerId}`).then(({ data }) => {
    dispatch({
      type: 'shared/item/FETCHED_ITEM',
      item: data
    })
  }, handleError)
}

export const updateFacilities = (entityId, userId, facilities) => dispatch => {
  api
    .put(`${path}/${entityId}/users/${userId}/facilities`, userApi.userFacilities(facilities))
    .then(data => {
      console.log('API updateFacilities', data)
      dispatch(push(`/portal/${entityId}/users`))
    }, handleError)
}

// export const updateMembership = (entityId, userId, membership) => dispatch => {
//   api
//     .put(`${path}/${entityId}/users/${userId}/memberships`, userApi.userFacilities(facilities))
//     .then(data => {
//       console.log('API updateFacilities', data)
//       dispatch(push(`/portal/${entityId}/users`))
//     }, handleError)
// }
