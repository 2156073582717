import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'shared/higher-order-components/Modal'
import Intl from 'shared/higher-order-components/Intl'
import { Button, FormSubmit } from 'shared/components'
import st from './Assignment.module.scss'
import cx from 'classnames'
import storage from 'shared/lib/storage'

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  fieldReference: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  selectedTitle: PropTypes.string.isRequired,
  availableTitle: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  availableItems: PropTypes.array.isRequired,
  setItemTitle: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  copy: PropTypes.object.isRequired
}

class Assignment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItems: props.selectedItems || [],
      availableItems: props.availableItems || [],
      formStatus: 'clean',
      headerBg: ''
    }
  }

  componentWillMount() {
    const headerBg = storage.local.getObj('portal_data', 'portal_settings.primary_color')
    this.setState({ headerBg })
  }

  componentWillReceiveProps(props) {
    this.setState(prevState => ({
      selectedItems: props.selectedItems || [],
      availableItems: props.availableItems || []
    }))
  }

  addItem = item => {
    this.setState(prevState => ({
      selectedItems: [...prevState.selectedItems, item],
      formStatus: 'touched'
    }))
  }

  removeItem = item => {
    this.setState(prevState => ({
      selectedItems: prevState.selectedItems.filter(s => s.id !== item.id),
      formStatus: 'touched'
    }))
  }

  addAllItems = () => {
    this.setState(prevState => ({
      selectedItems: prevState.availableItems,
      formStatus: 'touched'
    }))
  }

  removeAllItems = () => {
    this.setState(prevState => ({
      selectedItems: [],
      formStatus: 'touched'
    }))
  }

  getAvailableItems = () => {
    const { selectedItems, availableItems } = this.state
    const selectedIds = selectedItems.map(item => item.id)
    return availableItems.filter(item => !selectedIds.includes(item.id))
  }

  submit = () => {
    const { onSubmit, closeModal } = this.props
    onSubmit({ items: this.state.selectedItems })
    closeModal()
  }

  renderList({ type, items, action }) {
    const { fieldReference } = this.props
    const {
      copy,
      setItemTitle = item => item[!fieldReference ? 'name' : fieldReference]
    } = this.props
    const title = this.props[`${type}Title`]

    return (
      <div className={cx(st.assignment_list, st[`assignment_list-${type}`])}>
        <div className={st['assignment_list-title']}>{title}</div>
        <div className={st['assignment_list-items']}>
          {items.map((item, i) => (
            <div
              key={`item-${i}`}
              className={st['assignment_list-item']}
              onClick={action.bind(this, item)}
            >
              {setItemTitle(item)}
              <div className={st['assignment_list-item-action']}>
                <i className={`i-${type === 'selected' ? 'minus' : 'plus'}-circle`} />
              </div>
            </div>
          ))}
        </div>
        <div
          className={st[`assignment_list-${type}-move-all`]}
          onClick={this[`${type === 'selected' ? 'remove' : 'add'}AllItems`]}
        >
          {copy.shared(`assignment.${type}MoveAll`)}
        </div>
      </div>
    )
  }

  render() {
    const { modalTitle, className, closeModal, copy } = this.props
    console.log('selectedItems', this.props.selectedItems)
    return (
      <span className={className || ''}>
        <i className="i-close modal_close" onClick={closeModal} />
        <div style={{ background: this.state.headerBg }} className="modal_header">
          <i className="i-edit" />
          {modalTitle}
        </div>
        <div className={st.assignment}>
          <div className={st.assignment_content}>
            {this.renderList({
              type: 'available',
              items: this.getAvailableItems(),
              action: this.addItem
            })}
            <div className={st.assignment_switch}>
              <i className="i-switch" />
            </div>
            {this.renderList({
              type: 'selected',
              items: this.state.selectedItems,
              action: this.removeItem
            })}
          </div>
          <div className={st.assignment_bottom}>
            <Button
              className={st.assignment_cancel}
              size="large"
              type="secondary"
              onClick={closeModal}
            >
              {copy.shared('cancel')}
            </Button>
            <FormSubmit
              className={st.assignment_submit}
              formStatus={this.state.formStatus}
              formType="edit"
              text={{
                clean: copy.shared('assignment.assigned'),
                touched: copy.shared('assignment.assign')
              }}
              onClick={this.submit}
            />
          </div>
        </div>
      </span>
    )
  }
}

Assignment.propTypes = propTypes

Assignment = Modal({ contStyle: { padding: 0 } })(Assignment)

export default Intl()(Assignment)
