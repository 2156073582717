const initialState = {
  data: [],
  finished: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'account/STORE_WIZARD_DATA':
      return {
        ...state,
        data: { ...state.data, ...action.data },
        finished: action.status
      }
    case 'account/ROUTE_CHANGED':
      return initialState
    default:
      return state
  }
}
