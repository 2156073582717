import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { geocode, formatAddress } from 'shared/lib/geo'
import api, { handleError } from 'shared/api/index'
import { connect } from 'react-redux'

const mapStateToProps = ({
  app: {
    user: { organisation }
  }
}) => {
  return { organisation }
}

const mapDispatchToProps = {}

class ReverseGeocodedAddress extends Component {
  static propTypes = {
    coordinates: PropTypes.array.isRequired,
    google: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { address: null }
  }

  componentDidMount = () => {
    const { coordinates, google, organisation } = this.props

    api
      .get(`tracking/${organisation.id}/geocoder/reverse`, {
        lon: coordinates[0],
        lat: coordinates[1]
      })
      .then(
        ({ body }) => {
          debugger
          this.setState({
            address: body.data.text || '-'
          })
        },
        ({ errors }) => {
          handleError({ errors })
        }
      )
  }

  render() {
    return <span>{this.state.address || 'Loading...'}</span>
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReverseGeocodedAddress)
