import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import st from '../Table/Table.module.scss'

const propTypes = {
  field: PropTypes.string.isRequired,
  current: PropTypes.string,
  update: PropTypes.func
}

const OrderChevron = ({ field, current, update = () => {} }) => {
  let klass
  let value

  if (field === current) {
    klass = 'i-sort-down'
    value = `-${field}`
  } else if (`-${field}` === current) {
    klass = 'i-sort-up'
    value = undefined
  } else {
    klass = 'i-sort'
    value = field
  }

  return <i className={cx(klass, st[klass])} onClick={() => update(value)} />
}

OrderChevron.propTypes = propTypes

export { OrderChevron }
