import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Marker, InfoWindow } from 'react-google-maps'
import { noop } from 'lodash'
import GoogleMapsAsyncLoader from 'shared/higher-order-components/GoogleMapsAsyncLoader'

const blue = '#00b0ef',
	yellow = '#f7ed35',
	white = '#fff',
	black = '#000',
	green = '#7bc749',
	orange = '#ff4b0c'


const defautProps = {
	draggable: true,
	onRightClick: noop,
	onClick: noop,
	onDragEnd: noop,
	onDrag: noop,
	onDragStart: noop
}

const propTypes = {
	onDrag: PropTypes.func,
	onClick: PropTypes.func,
	draggable: PropTypes.bool,
	onDragEnd: PropTypes.func,
	onDragStart: PropTypes.func,
	onRightClick: PropTypes.func,
	google: PropTypes.object.isRequired,
	position: PropTypes.object.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

class WaypointMarker extends Component {

	onClick = (e) => {
		this.props.onClick()
	}

	onDragStart = (e) => {
		const { id, position, onDragStart } = this.props
		onDragStart(id, position)
	}

	onDragEnd = (e) => {
		const { id, onDragEnd } = this.props
		onDragEnd(id, e)
	}

	onDrag = (e) => {
		const { id, onDrag } = this.props
		onDrag(id, e)
	}

	onRightClick = (e) => {
		const { id, onRightClick } = this.props
		onRightClick(id, e)
	}

	render() {

		const { draggable, position, google } = this.props

		return (
			<Marker
				position={position}
				draggable={draggable}
				options={{
					icon: {
						path: google.maps.SymbolPath.CIRCLE,
						fillColor: yellow,
						strokeColor: yellow,
						fillOpacity: 1,
						scale: 10,
					},
					label: {
						color: black,
						text: 'W',
						fontWeight: 'bold',
					}
				}}
				onClick={this.onClick}
				onDrag={this.onDrag}
				onDragEnd={this.onDragEnd}
				onDragStart={this.onDragStart}
				onRightClick={this.onRightClick} />
		)
	}
}

WaypointMarker.propTypes = propTypes
WaypointMarker.defautProps = defautProps

export default GoogleMapsAsyncLoader(WaypointMarker)