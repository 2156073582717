/* global google */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalContainer from 'shared/higher-order-components/ModalContainer'
import Intl from 'shared/higher-order-components/Intl'
import { Tabs, Tab } from 'shared/components'
import SingleLineSearchTab from './SingleLineSearchTab'
import { cloneDeep, get } from 'lodash'
import GpsCoordinatesTab from './GpsCoordinatesTab'
import StructuredSearchTab from './StructuredSearchTab'
import AvtMap from './AvtMap'
import storage from 'shared/lib/storage'
import { getSites } from './api'
import { getAddressFromGooglePlace } from 'shared/lib/geo'

const propTypes = {
  onChange: PropTypes.func.isRequired,
  addressData: PropTypes.object.isRequired,
  copy: PropTypes.object.isRequired,
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
}

class Avtm extends Component {
  state = {
    selectedOptionId: 'orgCountry',
    searchOptions: [],
    countryCode: storage.local.getObj('user', 'preferences.regional_settings.country') || 'ZA',
    headerBg: ''
  }

  componentWillMount() {
    const countryCode =
      storage.local.getObj('user', 'preferences.regional_settings.country') || 'ZA'
    const headerBg = storage.local.getObj('portal_data', 'portal_settings.primary_color')
    this.setState({ countryCode, headerBg })
  }

  componentWillReceiveProps(newProps) {
    if (this.props.isOpen !== newProps.isOpen) {
      this.initPinOnMap()
    }
  }

  setMap = map => {
    this.$map = map
    this.initPinOnMap()
  }

  initPinOnMap = () => {
    const { addressData } = this.props
    if (this.$map && addressData.location) {
      this.$map.set({
        addressData,
        position: addressData.location,
        center: addressData.location,
        zoom: 16
      })
    }
    if (this.$map && addressData.coords && addressData.coords.length === 2) {
      this.$map.set({
        addressData,
        position: { lat: addressData.coords[1], lng: addressData.coords[0] },
        center: { lat: addressData.coords[1], lng: addressData.coords[0] },
        zoom: 16
      })
    }
  }

  initGoogleSearch = () => {
    const { addressData } = this.props
    // if(addressData) {
    // 	this.$map.set({
    // 		addressData: addressData,
    // 		center: addressData.location,
    // 		position: addressData.location,
    // 		zoom: 16
    // 	})
    // }
  }

  updateAddress = e => {
    if (e) {
      this.$map.set({
        addressData: e,
        center: e.location,
        position: e.location,
        zoom: 16
      })
    }
  }

  onMapIdle = () => {
    const { selectedOptionId } = this.state
    if (selectedOptionId === 'currentViewOnly') {
      this.setSearchBoundsToViewPort()
    }
  }

  setSearchBoundsToOrganisationCountry = () => {
    this.setState({ selectedOptionId: 'orgCountry' })
    this.autoComplete.setComponentRestrictions({ country: this.state.countryCode })
  }

  setSearchBoundsToViewPort = () => {
    this.setState({ selectedOptionId: 'currentViewOnly' })
    this.autoComplete.setComponentRestrictions(undefined)
    this.setSearchBounds(this.$map.getBounds())
  }

  removeSearchRestrictions = () => {
    this.setState({ selectedOptionId: 'wholeWorld' })
    this.setSearchBounds(null)
    this.autoComplete.setComponentRestrictions(undefined)
  }

  setSearchBounds = bounds => {
    this.autoComplete.setBounds(bounds)
  }

  onChange = e => {
    this.props.onChange(e, true)
    this.initPinOnMap()
  }

  render() {
    const { onChange, addressData, copy, children } = this.props
    const { closeModal, isOpen } = this.props

    console.log('addressData', addressData)
    return (
      <span>
        {children}
        <ModalContainer
          contentLabel="Address validation tool"
          isOpen={isOpen}
          modalClassName={'avtm'}
        >
          <span>
            <i className="i-close modal_close" onClick={closeModal} />
            <div style={{ background: this.state.headerBg }} className="modal_header">
              <i className="i-address" />
              {copy.avt('avtAddressManagement')}
            </div>
            <div className="avtm_inner">
              <div className="avtm_tabs-cont">
                <Tabs active={1}>
                  <Tab activeKey={1} title={copy.avt('singleLineSearch')}>
                    <SingleLineSearchTab
                      searchOptions={this.state.searchOptions}
                      setSearchInput={el => (this.$searchInput = el)}
                      searchAddress={this.searchAddress}
                      updateAddress={this.updateAddress}
                      closeModal={closeModal}
                      onChange={onChange}
                      addressData={addressData}
                    />
                  </Tab>
                  <Tab activeKey={2} title={copy.avt('structuredSearch')}>
                    <StructuredSearchTab
                      closeModal={closeModal}
                      onChange={e => this.onChange(e)}
                      addressData={addressData}
                    />
                  </Tab>
                  <Tab activeKey={3} title={copy.avt('gpsCoordinates')}>
                    <GpsCoordinatesTab
                      closeModal={closeModal}
                      onChange={e => this.onChange(e)}
                      addressData={addressData}
                      updateAddress={this.updateAddress}
                    />
                  </Tab>
                </Tabs>
              </div>
              <AvtMap
                ref={this.setMap}
                height={690}
                addressData={addressData}
                onChange={onChange}
                onIdle={this.onMapIdle}
                onMapLoadingComplete={this.initGoogleSearch}
              />
            </div>
          </span>
        </ModalContainer>
      </span>
    )
  }
}

Avtm.propTypes = propTypes

export default Intl({ avt: 'shared.avt' })(Avtm)

/**	updateBounds = (selectedSite, radius) => {
		radius = +radius

		const center = new google.maps.LatLng(...selectedSite.coordinates)
		const sw = google.maps.geometry.spherical.computeOffset(
			center, radius * 1000 * Math.sqrt(2), 225
		)
		const ne = google.maps.geometry.spherical.computeOffset(
			center, radius * 1000 * Math.sqrt(2), 45
		)
		this.autoComplete.setBounds(new google.maps.LatLngBounds(sw, ne))
	} */
