import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class ResponsiveTable extends Component {
  static propTypes = {
    className: PropTypes.string,
    headers: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.array),
    copy: PropTypes.object.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    isLoading: PropTypes.bool
  }

  render() {
    const { className, headers, rows, height, width, isLoading } = this.props

    const tableHeader = () => (
      <thead>
        <tr
          style={{
            textAlign: 'left'
          }}
        >
          {headers.map(({ text, className, ...props }, i) => (
            <th key={'header' + i}>
              {text}
              {text ? <div className={className}>{text}</div> : <div>&nbsp;&nbsp;</div>}
            </th>
          ))}
        </tr>
      </thead>
    )

    const tableRows = () => (
      <tbody>
        {rows.map((cells, i) => (
          <tr key={'row' + i}>
            {cells.map(({ text, ...props }, i) => (
              <td key={'cell' + i}>{text}</td>
            ))}
          </tr>
        ))}
      </tbody>
    )

    return (
      <div
        className={classnames('responsive-table', className, {
          'responsive-table_is-loading': isLoading
        })}
        style={{ height: height, width: width }}
      >
        <div className="responsive-table_container">
          <table
            style={{
              fontSize: 'smaller',
              width: '100%'
            }}
          >
            {tableHeader()}
            {tableRows()}
          </table>
        </div>
      </div>
    )
  }
}

export { ResponsiveTable }
