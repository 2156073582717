import React from 'react'
import PropTypes from 'prop-types'
import Intl from 'shared/higher-order-components/Intl'
import { PageNav } from 'shared/components'

const Nav = ({ copy, orgId }) => {
  return (
    <PageNav
      links={[
        {
          to: `/portal/${orgId}/info`,
          text: `${copy.get(`Company Info`)}`
        },
        {
          to: `/portal/${orgId}/settings`,
          text: `${copy.get(`Settings`)}`
        }
      ]}
    />
  )
}

Nav.propTypes = {
  copy: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired
}

export default Intl({ get: 'portal.bookings' })(Nav)
