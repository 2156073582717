import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { emit, forOwn } from 'lodash'

const propTypes = {
  google: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  path: PropTypes.array,
  onClick: PropTypes.func,
  onMouseOut: PropTypes.func,
	onMouseOver: PropTypes.func,
	onContextMenu: PropTypes.func
}

const defaultProps = {
  style: {
    strokeColor: '#FF0000',
    strokeOpacity: 1.0,
    strokeWeight: 2
  },
  onClick: function() { },
  onMouseOut: function() { },
	onMouseOver: function() { },
	onContextMenu: function() { }
}

const eventMap = {
  onClick: "click",
  onMouseOut: "mouseout",
	onMouseOver: "mouseover",
	onContextMenu: "rightclick"
}

class MapPolygon extends Component {

  constructor(props){
    super(props)

    const {
      google,
      map,
      style
    } = props
 
    const marker = new google.maps.Polygon({
      geodesic: true,
      map,
      path: this.getPositions(),
      ...style
    })

    this.$marker = marker
  }

  componentDidMount() {    
    forOwn(eventMap, (value, key) => {
      this.$marker.addListener(value, this.props[key])
    })
  }

  componentDidUpdate = () => {
		this.$marker.setOptions(this.props.style)
		this.$marker.setPath(this.getPositions())
  }

  componentWillUnmount = () => {
    if(!this.$marker) {
      return
    }

    const {
      google
    } = this.props
    
    forOwn(eventMap, (value, key) => {
      google.maps.event.clearListeners(this.$marker, value)
    })

    this.$marker.setMap(null)
    this.$marker = null
  }

  getPositions = () => {
    const {
      path
    } = this.props
    return path.map(item => ({ lng: item[0], lat: item[1] }))
  }

  render() {
    return null
  }

}

MapPolygon.propTypes = propTypes
MapPolygon.defaultProps = defaultProps

export default MapPolygon