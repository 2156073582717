import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Intl from 'shared/higher-order-components/Intl'
import Form from 'shared/higher-order-components/Form'
import { Page, FormSubmit, Input, ValidationList, Avt } from 'shared/components'
import Nav from './Nav'
import { updateCompanyInfo, fetchCompanyInfo } from '../../state/organisation/actions'

let InformationForm = ({
  fetchCompanyInfo,
  formType,
  fields,
  messages,
  submit,
  formStatus,
  copy,
  ...props
}) => {
  useEffect(() => {
    fetchCompanyInfo(props.orgId)
  }, [])

  return (
    <Page title={copy.get('heading')} navContent={<Nav {...props} />}>
      <div className="form form-medium">
        <div className="form_heading">{copy.get('heading')}</div>
        <div className="form_sub-heading" />
        <ValidationList messages={messages} />
        <div className="row">
          <div className="col-6">
            <Input iconLeft="briefcase" {...fields.registered_name} label={copy.get('regName')} />
          </div>

          <div className="col-6">
            <Input iconLeft="briefcase" {...fields.name} label={copy.get('name')} />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Input iconLeft="file-archive-o" {...fields.vat_no} label={copy.get('vat')} disabled />
          </div>

          <div className="col-6">
            <Input
              iconLeft="file-archive-o"
              {...fields.reg_no}
              label={copy.get('regNo')}
              disabled
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Avt {...fields.address} />
          </div>
        </div>

        <FormSubmit
          formType={formType}
          formStatus={formStatus}
          onClick={submit}
          icons=""
          text={{
            clean: copy.get('Save'),
            touched: copy.get('Save'),
            working: copy.get('Saving')
          }}
          style={{ width: '50%', textAlign: 'center' }}
        />
      </div>
    </Page>
  )
}

InformationForm.propTypes = {
  formType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  formItem: PropTypes.object.isRequired,
  formLoaded: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

InformationForm = Form({
  fields: () => ({
    registered_name: [],
    reg_no: [],
    name: [],
    vat_no: [],
    address: []
  }),
  isFormReady: ({ props }) => props.formLoaded,
  mapPropsToFields: ({ formItem }) => formItem || false,

  submit({ props, fields }) {
    props.updateCompanyInfo(props.orgId, fields)
  }
})(InformationForm)

const mapStateToProps = ({ shared }, { match }) => ({
  ...match.params,
  ...shared.form
})

const mapDispatchToProps = { updateCompanyInfo, fetchCompanyInfo }

InformationForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationForm)

export default Intl({
  get: 'portal.company'
})(InformationForm)
