import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { range } from 'lodash'
import Intl from 'shared/higher-order-components/Intl'
import { Button } from 'shared/components'
import st from './Pagination.module.scss'

const propTypes = {
  update: PropTypes.func.isRequired,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.number,
  perPage: PropTypes.number.isRequired,
  copy: PropTypes.object.isRequired
}

const humanizePages = (allPages, currentPage) => {
  let prevDotsInserted = false
  let nextDotsInserted = false

  const pages = allPages
    .map(loopPage => {
      if (currentPage - loopPage > 3) {
        if (!prevDotsInserted) {
          prevDotsInserted = true
          return 'dots'
        }
        return null
      }
      if (loopPage - currentPage > 3) {
        if (!nextDotsInserted) {
          nextDotsInserted = true
          return 'dots'
        }
        return null
      }
      return loopPage
    })
    .filter(p => p)

  if (pages[0] === 'dots') pages.unshift(1)
  if (pages[pages.length - 1] === 'dots') pages.push(allPages.length)

  if (pages[2] === 2) pages.splice(1, 1)
  if (pages[pages.length - 3] === allPages.length - 1) pages.splice(pages.length - 2, 1)

  return pages
}

let Pagination = ({ update, page = 1, total = 0, perPage = 25, copy }) => {
  const currentPage = +page
  const pageStart = (currentPage - 1) * perPage + 1
  const pageEnd = currentPage * perPage > total ? total : currentPage * perPage
  const allPages = !total ? [1] : range(1, total / perPage + 1)
  const pages = humanizePages(allPages, currentPage)

  return (
    <div className={st.pagination}>
      <div className={st.pagination_text}>
        {copy.get('text', { pageStart, pageEnd, count: total })}
      </div>
      <div className={st.pagination_actions}>
        {currentPage > 1 ? (
          <Button
            className={classnames(st.pagination_button, st['is-prev'])}
            iconLeft="chevron-left"
            size="small"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={update.bind(this, currentPage - 1)}
          >
            {copy.get('prev')}
          </Button>
        ) : null}
        {pages.map((loopPage, i) =>
          loopPage === 'dots' ? (
            <div className={st.pagination_dots} key={i}>
              ...
            </div>
          ) : (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              key={i}
              onClick={currentPage === loopPage ? () => {} : update.bind(this, loopPage)}
              className={classnames(st.pagination_item, {
                [`${st['pagination_item-active']}`]: currentPage === loopPage
              })}
            >
              {loopPage}
            </div>
          )
        )}
        {currentPage < allPages.length ? (
          <Button
            className={classnames(st.pagination_button, st['is-next'])}
            iconRight="chevron-right"
            size="small"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={update.bind(this, currentPage + 1)}
          >
            {copy.get('next')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

Pagination.propTypes = propTypes

Pagination = Intl({ get: 'shared.pagination' })(Pagination)

export { Pagination }
