import moment from 'moment'
import 'moment-duration-format'
import { get, isArray, isNil } from 'lodash'
import { goToCopy } from 'shared/lib/intl'
import storage from 'shared/lib/storage'

const copy = goToCopy('shared.dateTime')

const defaultDateFormat = 'M/D/YYYY'
const defaultTimeFormat = 'h:mm A'

export const getDateFormat = () => (
	storage.local.getObj('user', 'preferences.regional_settings.date_format') || defaultDateFormat
)

export const getTimeFormat = () => (
	storage.local.getObj('user', 'preferences.regional_settings.time_format') || defaultTimeFormat
)

export const getCurrentDateTime = () => {
	return moment()
}

export const convertToDateTime = (value) => {
	return moment(value)
}

export const convertToUtc = (value) => {
	return moment(value).utc()
}

export const ceil = (unit, value) => (
	moment(value).add(`${unit}s`, 1).startOf(unit).toDate()
)

export const floor = (unit, value) => (
	moment(value).startOf(unit).toDate()
)

export const formatDate = value => (
	value ? moment(value).format(getDateFormat()) : value
)

export const formatTime = value => (
	value ? moment(value).format(getTimeFormat()) : value
)

export const formatTimeWithDayOffset = value => {
	if(!value) { return value }
	const m = moment(value)
	const days = m.diff(moment(), 'days')
	const formatted = m.format(getTimeFormat())
	if(days > 0) {
		return `${formatted} +${days}`
	} else {
		return formatted
	}
}

export const formatTimeDuration = value => (
	value ? moment(value).format('HH:mm') : value
)

export const formatDateTime = (value, format = 'date time') => {
	if (!value) return value
	const finalFormat = format
		.replace('date', getDateFormat())
		.replace('time', getTimeFormat())
	return moment(value).format(finalFormat)
}

export const formatDiffDuration = (begin, end, withoutDays) => {
	if (isNil(begin)) return begin
	const dur = moment.duration(moment(end).diff(moment(begin))).abs()
	return dur.format(
		withoutDays || !dur.days() ? `hh:mm:ss` : `d[ ${copy('days')}, ]hh:mm:ss`,
		{ trim: false }
	)
}

export const formatDiffDurationHumanize = (begin, end) => {
	if (isNil(begin)) return begin
	return moment.duration(moment(end).diff(moment(begin))).humanize()
}

export const formatDuration = (duration) => {
	if (isNil(duration)) return duration
	const dur = moment.duration(duration)
	return dur.format(
		!dur.days() ? `hh:mm:ss` : `d[ ${copy('days')}, ]hh:mm:ss`,
		{ trim: false }
	)
}

export const formatDurationWithoutSeconds = (duration) => {
	if (isNil(duration)) return duration
	const dur = moment.duration(duration)
	return dur.format(
		!dur.days() ? `hh:mm` : `d[ ${copy('days')}, ]hh:mm`,
		{ trim: false }
	)
}

export const formatDiffDurationWithoutSeconds = (begin, end, withoutDays) => {
	if (isNil(begin)) return begin
	const dur = moment.duration(moment(end).diff(moment(begin))).abs()
	return dur.format(
		withoutDays || !dur.days() ? `hh:mm:ss` : `d[ ${copy('days')}, ]hh:mm`,
		{ trim: false }
	)
}

export const formattedDateToISO = value => (
	value ? moment(value, getDateFormat()).toISOString() : value
)

export const isValidDateFormat = value => (
	moment(value, getDateFormat(), true).isValid()
)

export const unixTimestamp = value => (+ new Date(value))