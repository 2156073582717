import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import pubsub from 'sweet-pubsub'

const Hoc = (Child) => {
	class MapDirectionsHOC extends Component {

		static propTypes = {
			position: PropTypes.array,
			google: PropTypes.object,
			map: PropTypes.object
		}

		constructor(props) {
			super(props)
			this.state = { points: [] }
			this.$renderer = new props.google.maps.DirectionsRenderer({
				draggable: true,
				preserveViewport: true
			})
		}

		componentDidMount = () => {
			this.$directions = new this.props.google.maps.DirectionsService()
		}

		addPoint = (point, callback) => {
			this.setState({
				points: [...this.state.points, point]
			}, () => {
				this.calculate()
				callback && callback()
			})
		}

		removePoint = (point, callback) => {
			this.setState({
				points: this.state.points.filter(p => (p !== point))
			}, (state) => {
				this.calculate()
				callback && callback()
			})
		}

		close = (callback) => {
			this.setState({
				points: []
			}, () => {
				this.$renderer.setMap(null)
				callback()
			})
		}

		setPanel = (el) => {
			this.$renderer.setPanel(el)
		}

		calculate = () => {
			const {
				points
			} = this.state

			if (points.length < 1) {
				this.$renderer && this.$renderer.setMap(null)
				return
			}

			const first = points[0]
			const last = points[points.length - 1]
			const waypoints = points.slice(1, points.length - 1)

			const toPoint = (x) => ({
				lng: x[0],
				lat: x[1]
			})

			pubsub.emit('ajax/start', 'POST')
			this.$directions.route({
				origin: toPoint(first),
				destination: toPoint(last),
				waypoints: waypoints.map(x => ({ location: toPoint(x) })),
				optimizeWaypoints: true,
				travelMode: this.props.google.maps.TravelMode.DRIVING
			}, (result, status) => {
				if (status === this.props.google.maps.DirectionsStatus.OK) {
					pubsub.emit('ajax/end', 'POST')
					this.$renderer.setDirections(result)
					this.$renderer.setMap(this.props.map)
				}
			})
		}

		render() {
			return (<Child
				{...this.props}
				addPoint={this.addPoint}
				removePoint={this.removePoint}
				points={this.state.points}
				close={this.close}
				setPanel={this.setPanel}
			/>)
		}
	}
	return MapDirectionsHOC
}

export default Hoc