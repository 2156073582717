import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get, isEqual } from 'lodash'
import { geocodeaddress } from 'shared/lib/geo'
import storage from 'shared/lib/storage'
import GoogleMapsAsyncLoader from 'shared/higher-order-components/GoogleMapsAsyncLoader'
import Intl from 'shared/higher-order-components/Intl'
import { Input, Select, Button } from 'shared/components'
import AddressForm from './AddressForm'

const propTypes = {
  google: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedOptionId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  addressData: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  searchOptions: PropTypes.array.isRequired,
  copy: PropTypes.object.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired
}

class AvtmSingleLineSearchTab extends Component {
  state = {
    settingsOpen: false,
    countryCode: storage.local.getObj('user', 'preferences.regional_settings.country'),
    searchOptions: []
  }

  searchAddress = e => {
    const { google } = this.props
    console.log('what is google', google)
    if (e) {
      console.log(e)
      let options = {
        address: e,
        componentRestrictions: {
          country: this.state.countryCode || 'ZA'
        }
      }
      geocodeaddress(google, options, searchOptions => {
        console.log('searchOptions,', searchOptions)
        this.setState({ searchOptions })
      })
    } else {
      this.setState({ searchOptions: [] })
    }
  }

  cleanSearch = () => {
    this.setState({ searchOptions: [] })
  }

  onSelect = e => {
    if (e) {
      this.$searchInput.value = e.label
      this.props.updateAddress(e.address)
      this.cleanSearch()
    }
  }

  render() {
    const { copy, onChange, closeModal, addressData } = this.props

    const { searchOptions } = this.state

    return (
      <div className="avtm_slst">
        <div className="avtm_slst-top">
          <Input
            reff={el => (this.$searchInput = el)}
            autoComplete={'off'}
            onEnter={this.searchAddress}
            onChange={this.cleanSearch}
            placeholder={copy.avt('searchAddresses')}
          />
          <div className="avtm_slst-dropdown-trigger">
            <div
              className={classnames({
                'avtm_slst-dropdown-cont': !searchOptions.length,
                'avtm_slst-dropdown-cont_toggled': searchOptions.length
              })}
            >
              <div className="avtm_slst-dropdown" style={{ width: 815, top: 1 }}>
                <div className="avtm_slst-dropdown-sites">
                  {searchOptions.map(option => (
                    <div
                      key={option.id}
                      className={classnames('avtm_slst-dropdown-site', {
                        'is-selected': option.isSelected
                      })}
                      onClick={() => {
                        this.onSelect(option)
                      }}
                    >
                      <span>{option.label}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddressForm onChange={onChange} closeModal={closeModal} addressData={addressData} />
      </div>
    )
  }
}

AvtmSingleLineSearchTab.propTypes = propTypes

AvtmSingleLineSearchTab = GoogleMapsAsyncLoader(AvtmSingleLineSearchTab)

export default Intl({ avt: 'shared.avt' })(AvtmSingleLineSearchTab)
