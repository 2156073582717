/* eslint react/no-multi-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import colors from 'shared/static/colors'
import { Button } from 'shared/components'
import { downloadDocument } from 'shared/document/request'
import st from './InputFile.module.scss'

const propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  inputText: PropTypes.string,
  iconRight: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  iconLeft: PropTypes.string,
  fileData: PropTypes.object.isRequired,
  type: PropTypes.string,
  styleType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onFileSelect: PropTypes.func,
  currentDocument: PropTypes.object,
  reff: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object
}

class InputFile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: '',
      fileData: props.fileData || {},
      formStatus: 'clean'
    }
  }

  render() {
    const {
      className,
      inputClassName,
      label,
      name,
      invalid,
      iconLeft,
      iconRight,
      disabled,
      orgId,
      currentDocument,
      style = {},
      styleType = 'line',
      type = 'text',
      reff,
      ...inputProps
    } = this.props

    const { fileName } = this.state

    const process = event => {
      const { onFileSelect } = this.props
      const name = event.target.value.split(/(\\|\/)/g).pop()
      const formData = new FormData()
      const files = Array.from(event.target.files)
      console.log(files)

      formData.append('image', files[0], name)

      this.setState({
        fileName: name
      })
      onFileSelect({ data: formData })
    }

    if (disabled) style.color = colors.textLight

    const renderInput = () => (
      <div>
        <Button size="large" className={st.fileUpload} iconLeft="upload">
          <input
            type="file"
            id="file-to-upload"
            multiple={false}
            onChange={e => {
              process(e)
            }}
            className={st.upload}
          />
          Upload Logo
          {/* <label className={st.label} for="file-to-upload">
            Upload your logo
          </label> */}
        </Button>
      </div>
    )

    return (
      <div
        className={classnames('form_element form_element-input', className, {
          'is-invalid': invalid
        })}
      >
        {label ? (
          <label className="form_label" htmlFor={`fe-${name}`}>
            {label} {fileName ? `File Selected (${fileName})` : null}
          </label>
        ) : null}
        {renderInput()}
      </div>
    )
  }
}

InputFile.propTypes = propTypes

export { InputFile }
