import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { back } from 'shared/state/actions'
import Intl from 'shared/higher-order-components/Intl'
import Form, { isRequired, serverMessage } from 'shared/higher-order-components/Form'
import { Nav, Page, Button, Input, ValidationList, FormSubmit } from 'shared/components'
import { submit } from '../state/changePassword/actions'

const mapStateToProps = ({ app, shared }) => ({
  user: app.user,
  ...shared.form
})

const mapDispatchToProps = { onSubmit: submit, back }

const propTypes = {
  messages: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

let ChangePassword = ({ messages, fields, submit, back, formStatus, copy }) => (
  <span>
    <Page title="Change Password">
      <div className="form form-small">
        <div className="form_heading">{copy.get('changePassword')}</div>
        <div className="form_sub-heading" />
        <ValidationList messages={messages} />
        <Input
          {...fields.password}
          label={copy.get('password')}
          type="password"
          placeholder="***"
          iconLeft="fingerprint"
        />
        <Input
          {...fields.newPassword}
          label={copy.get('newPassword')}
          type="password"
          placeholder="***"
          iconLeft="fingerprint"
        />
        <Input
          {...fields.passwordConfirmation}
          label={copy.get('passwordConfirmation')}
          type="password"
          placeholder="***"
          iconLeft="fingerprint"
        />
        <Button className="form_cancel" size="large" type="secondary" onClick={back}>
          {copy.shared('cancel')}
        </Button>
        <FormSubmit
          formStatus={formStatus}
          formType="change"
          icons={{ clean: 'upload' }}
          onClick={submit}
        />
      </div>
    </Page>
  </span>
)

ChangePassword.propTypes = propTypes

ChangePassword = Form({
  fields: ({ copy }) => ({
    password: [serverMessage('password'), isRequired(copy.get('password'))],
    newPassword: [
      isRequired(copy.get('newPassword')),
      ({ fields }) =>
        (f => {
          if (f.newPassword.value && f.passwordConfirmation.value) {
            if (f.newPassword.value === f.passwordConfirmation.value) {
              return true
            }
            return false
          }
          return true
        })(fields)
          ? false
          : {
              message: copy.get('passwordsMustMatch'),
              preventSubmit: true
            }
    ],
    passwordConfirmation: [isRequired(copy.get('passwordConfirmation'))]
  }),

  submit({ props, fields }) {
    props.onSubmit(fields, props.user)
  }
})(ChangePassword)

ChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword)

export default Intl({ get: 'account.auth' })(ChangePassword)
