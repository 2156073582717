import { debounce, isNil, isEqual, some} from 'lodash'
import colors from 'shared/static/colors'

export const defaultOptions = color => ({
	strokeColor: color,
	strokeOpacity: 1,
	strokeWeight: 2,
	fillColor: color,
	fillOpacity: 0.2,
	editable: true,
	draggable: true,
	suppressUndo: true
})

export const createLatLng = item => ({ lng: item[0], lat: item[1] })

export const translateDrawingMode = mode => {
	if (mode === 'radius') {
		return 'circle'
	}
	return mode
}

export const checkBounds = (google, mode, overlay) => (marker) => {
	if (mode === 'radius') {
		const distance = google.maps.geometry.spherical.computeDistanceBetween(overlay.getCenter(), marker.getPosition())
		return distance < overlay.getRadius()
	} else {
		return google.maps.geometry.poly.containsLocation(marker.getPosition(), overlay)
	}
}

export const getCenter = (google) => (mode, overlay) => {
	let center = null
	if (mode === 'radius') {
		center = overlay.getCenter()
	} else {
		let bounds = new google.maps.LatLngBounds()
		overlay.getPath().forEach(item => bounds.extend(item))
		center = bounds.getCenter()
	}
	return [center.lng(), center.lat()]
}

export const getEdgePos = (google, overlay, marker) => {
	let center = overlay.getCenter()
	let markerPos = marker.getPosition()
	let heading = google.maps.geometry.spherical.computeHeading(center, markerPos)
	let radius = overlay.getRadius()
	let distance = radius - 1
	let offset = google.maps.geometry.spherical.computeOffset(center, distance, heading)
	return [offset.lng(), offset.lat()]
}

export const whenNotNull = (item, callback) => {
	if (!item) {
		return
	}
	callback(item)
}

export const createDrawingManager = ({
	color,
	map,
	onOverlayComplete,
	mode,
	google
}) => {
	const drawingManager = new google.maps.drawing.DrawingManager({
		drawingControl: false,
		circleOptions: defaultOptions(color),
		polygonOptions: defaultOptions(color),
		map: map
	})
	google.maps.event.addListener(drawingManager, 'overlaycomplete', onOverlayComplete)
	drawingManager.setDrawingMode(translateDrawingMode(mode))
	return drawingManager
}

export const shapeFactoryMethods = {

	// Factory method for entrance marker
	entrance: {

		createEntrance: marker => ([marker.getPosition().lng(), marker.getPosition().lat()]),

		createMarker: (google, map) => value => {
			return value.entrance ? new google.maps.Marker({
				map,
				icon: {
					path: 'M16,3.5c-4.142,0-7.5,3.358-7.5,7.5c0,4.143,7.5,18.121,7.5,18.121S23.5,15.143,23.5,11C23.5,6.858,20.143,3.5,16,3.5z M16,14.584c-1.979,0-3.584-1.604-3.584-3.584S14.021,7.416,16,7.416S19.584,9.021,19.584,11S17.979,14.584,16,14.584z',
					scale: 1,
					anchor: new google.maps.Point(16.5, 34),
					fillColor: '#2363AE',
					fillOpacity: 1,
					strokeColor: '#2363AE',
					strokeOpacity: 1,
				},
				draggable: true,
				position: createLatLng(value.entrance)
			}) : null
		},

		bind: (marker, isWithinBounds, callback) => {
			let lastPositionWithinBounds = marker.getPosition()
			marker.addListener('drag', callback)
		}
	},

	// Factory methods for radius
	radius: {

		createShape: overlay => ({
			marker: [
				overlay.getCenter().lng(),
				overlay.getCenter().lat()
			],
			size: overlay.getRadius()
		}),

		createOverlay: (google, map, color = colors.blue) => value => {
			if (!(value.marker && value.size)) { return null }
			const overlay = new google.maps.Circle({
				center: createLatLng(value.marker),
				radius: value.size,
				map: map,
				...defaultOptions(color)
			})

			overlay.fitBounds = (moreBounds = []) => {
				let bounds = overlay.getBounds()
				moreBounds.forEach(item => bounds = bounds.extend(item))
				overlay.map.fitBounds(bounds)
			}

			return overlay
		},

		bind: (overlay, callback) => {
			const events = [
				'radius_changed',
				'center_changed'
			]
			events.forEach(event => overlay.addListener(event, callback))
			callback()
		}
	},

	// Factory methods for polygon
	polygon: {

		createShape: overlay => ({
			markers: overlay.getPath().getArray().map(path => ([
				path.lng(),
				path.lat()
			]))
		}),

		createOverlay: (google, map, color = colors.blue) => value => {
			if (!value.markers) { return null }
			const overlay = new google.maps.Polygon({
				paths: value.markers.map(createLatLng),
				map: map,
				...defaultOptions(color)
			})

			overlay.fitBounds = (moreBounds = []) => {
				let bounds = new google.maps.LatLngBounds()
				overlay.getPath().getArray().forEach(item => bounds = bounds.extend(item))
				moreBounds.forEach(item => bounds = bounds.extend(item))
				overlay.map.fitBounds(bounds)
			}

			return overlay
		},

		bind: (overlay, callback) => {

			const events = [
				'insert_at',
				'remove_at',
				'set_at'
			]

			const path = overlay.getPath()
			events.forEach(event => path.addListener(event, callback))
			overlay.addListener('rightclick', e => {
				if (isNil(e.vertex) || path.length <= 3) {
					return
				}
				path.removeAt(e.vertex)
			})
			callback()
		}
	}
}


const roundDecimals = (num, prec) => {
	return Math.round(num * prec) / prec
}

const decimalsEqual = (num, num2, prec) => {
	return isEqual(roundDecimals(num, prec), roundDecimals(num2, prec))
}

export const isCoord = (coord) => {
	return (some(coord) && coord.length === 2 && coord.every(i => !isNaN(i)))
		|| (some(coord) && coord.every(c => { return some(c) && c.every(i => !isNaN(i)) }))
}

export const coordEqual = (coord1, coord2, prec) => {
	if (isCoord(coord1) && isCoord(coord2)) {
		// singles
		for (let i = 0; i < coord1.length; i++) {
			if (!decimalsEqual(coord1[i], coord2[i], prec))
				return false
		}
		return true
	} else if (some(coord1) && some(coord2) && coord1.length === coord2.length) {
		// array of coords
		for (let arr = 0; arr < coord1.length; arr++) {
			for (let i = 0; i < coord1[arr].length; i++) {
				if (!decimalsEqual(coord1[arr][i], coord2[arr][i], prec))
					return false
			}
		}
	}
	return undefined
}