import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Intl from 'shared/higher-order-components/Intl'
import { Page, Button, Table, WarningDialog, Assignment } from 'shared/components'
import Nav from './Nav'

import { searchCustomersByEntity } from '../../state/customers/customerActions'
import { getUserByEntity, updateFacilities } from '../../state/users/userActions'

let LinkedCustomerList = ({
  formType,
  fields,
  messages,
  submit,
  formStatus,
  copy,
  searchCustomersByEntity,
  updateFacilities,
  getUserByEntity,
  itemLoaded,
  fetching,
  listItems,
  formItem,
  formLoaded,
  ...props
}) => {
  const [selectedCustomers, updateCustomers] = useState([])
  const [selectedFacilities, updateFacility] = useState({})
  const { orgId, userId } = props

  useEffect(() => {
    getUserByEntity(orgId, userId)
    searchCustomersByEntity(orgId)
  }, [getUserByEntity, orgId, userId, searchCustomersByEntity])

  useEffect(() => {
    updateCustomers(
      formItem.memberships
        ? formItem.memberships.map(({ customer, facilities }) => ({ ...customer, facilities }))
        : []
    )
  }, [formItem])

  useEffect(() => {
    updateFacility(
      formItem.memberships
        ? formItem.memberships.reduce((aggregator, { customer, facilities }) => {
            aggregator[customer.id] = facilities
            return aggregator
          }, {})
        : {}
    )
  }, [formItem])

  const viewCustomerFacilities = customerId => {
    return listItems.filter(item => item.id === customerId).map(f => f.facilities)[0]
  }

  const removeCustomer = itemId => {
    const filteredCust = selectedCustomers.filter(item => item.id !== itemId)
    delete selectedFacilities[itemId]
    updateCustomers(filteredCust)
  }

  const addFacilities = (id, facilities) => {
    const tranformedArr = {
      [id]: facilities
    }
    updateFacility({ ...selectedFacilities, ...tranformedArr })
  }

  const saveCustomersFacilities = () => {
    updateFacilities(props.orgId, props.userId, selectedFacilities)
  }

  const headers = [
    { text: copy.get('Customer') },
    { text: copy.get('Facilities') },
    { text: copy.get('Assigned') },
    { width: 5 },
    { width: 5 }
  ]

  const rows = selectedCustomers.map(item => [
    { text: item.name },
    { text: item.facilities.length || '0' },
    { text: selectedFacilities[item.id] ? selectedFacilities[item.id].length : '0' },
    {
      className: 'is-single-icon',
      content: (
        <Assignment
          modalTrigger={<i className="i-edit" />}
          className="module-portal"
          type="high"
          modalTitle={copy.get('Assign Facilities')}
          selectedTitle={copy.get('Selected Facilities')}
          availableTitle={copy.get('Available Facilities')}
          selectedItems={selectedFacilities[item.id]}
          availableItems={viewCustomerFacilities(item.id)}
          onSubmit={({ items }) => addFacilities(item.id, items)}
        />
      )
    },
    {
      className: 'is-single-icon',
      content: (
        <WarningDialog
          message={copy.get('deleteWarning')}
          onConfirm={() => removeCustomer(item.id)}
          modalTrigger={<i style={{ color: 'red' }} className="i-trash" />}
        />
      )
    }
  ])

  return (
    <Page title="Linked Customers" navContent={<Nav {...props} />}>
      <div className="listTable" style={{ width: '600px', margin: '0 auto' }}>
        <div className="listTable_top-bar" style={{ marginTop: '20px' }}>
          <Assignment
            modalTrigger={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Button className="float-right" iconLeft="plus">
                {copy.get('Assign Customer')}
              </Button>
            }
            className="module-portal"
            type="high"
            modalTitle={copy.get('Assign Customer')}
            selectedTitle={copy.get('Selected Customers')}
            availableTitle={copy.get('Available Customers')}
            selectedItems={selectedCustomers}
            availableItems={listItems}
            onSubmit={({ items }) => updateCustomers(items)}
          />
        </div>
        <div className="form form-small">
          {formLoaded && (
            <div className="form_heading">{`Linked Customers for ${formItem.names.full}`}</div>
          )}

          <Table headers={headers} rows={rows} isLoading={fetching} className="table-simple" />
          <Button
            className="float-right"
            onClick={() => saveCustomersFacilities()}
            style={{ width: 140 }}
          >
            {copy.get('Save')}
          </Button>
        </div>
      </div>
    </Page>
  )
}

LinkedCustomerList.propTypes = {
  formType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  formItem: PropTypes.object.isRequired,
  formLoaded: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

const mapStateToProps = ({ shared }, { match }) => ({
  ...shared.form,
  ...shared.list,
  ...match.params
})

const mapDispatchToProps = { getUserByEntity, searchCustomersByEntity, updateFacilities }

LinkedCustomerList = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkedCustomerList)

export default Intl({
  get: 'portal.users'
})(LinkedCustomerList)
