import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class SplitButtonItem extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    close: PropTypes.func.isRequired
  }

  render() {
    const { label, onClick, close } = this.props

    return (
      <div onClick={close}>
        <div className="splitbutton-items" onClick={onClick}>
          {label}
        </div>
      </div>
    )
  }
}

export { SplitButtonItem }
