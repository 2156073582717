import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListPage from 'shared/higher-order-components/ListPage'
import { Input, Button, Select, ValidationList } from 'shared/components'
import Form, { isRequired, serverMessage } from 'shared/higher-order-components/Form'
import ModalContainer from 'shared/higher-order-components/ModalContainer'
import userImage from 'shared/assets/user-plus-solid.png'
import { mapValues } from 'lodash'
import classnames from 'classnames'

class AddUsersModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.$unassigned = true
    this.closeModal = this.closeModal.bind(this)
    this.closeModalAndClear = this.closeModalAndClear.bind(this)
  }

  openModal = () => {
    this.setIsOpen(true)
  }

  closeModal = () => {
    const { resetForm } = this.props
    this.setIsOpen(false)
    resetForm()
  }

  closeModalAndClear = () => {
    const { resetForm } = this.props
    this.setIsOpen(false)
    resetForm()
  }

  setIsOpen = isOpen => {
    this.setState({ isOpen: isOpen })
  }

  trimValue = value => value.replace(/^\s+|\s+$/gm, '')

  onSubmit = fields => {
    const { submit } = this.props
    submit(fields)
    this.closeModalAndClear()
  }

  render() {
    const { copy, fields, classN, messages } = this.props

    const { isOpen } = this.state

    return (
      <span>
        <div className={classnames('user-container', classN)}>
          <img className="add-users" src={userImage} onClick={() => this.openModal()} alt="Users" />

          <div className="user-text">{copy.get('users.modal.iconHeading')}</div>
        </div>
        <ModalContainer contentLabel={copy.get('users.modal.heading')} isOpen={isOpen}>
          <span>
            <div className="form form-widget text-left">
              <div className="form_heading">{copy.get('users.modal.heading')}</div>
              <div className="form_sub-heading" />
              <ValidationList messages={messages} />

              <div className="row">
                <div className="col-6">
                  <Input
                    iconLeft="person"
                    {...fields.first}
                    label={copy.get('users.modal.fName')}
                  />
                </div>
                <div className="col-6">
                  <Input iconLeft="person" {...fields.last} label={copy.get('users.modal.lName')} />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <Input
                    iconLeft="mail_outline"
                    {...fields.email}
                    label={copy.get('users.modal.email')}
                  />
                </div>
                <div className="col-6">
                  <Input
                    iconLeft="mobile"
                    {...fields.mobile}
                    label={copy.get('users.modal.mobile')}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <Input
                    iconLeft="fingerprint"
                    {...fields.identity_number}
                    label={copy.get('users.modal.idNum')}
                  />
                </div>
                <div className="col-6">
                  <Input
                    iconLeft="mobile"
                    {...fields.work}
                    label={copy.get('users.modal.officeNum')}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <Select
                    {...fields.role}
                    iconLeft="list"
                    label={copy.get('users.modal.permission')}
                    options={[
                      {
                        value: 'admin',
                        label: copy.get('users.modal.admin')
                      },
                      {
                        value: 'user',
                        label: copy.get('users.modal.user')
                      }
                    ]}
                    defaultValue="user"
                  />
                </div>
              </div>

              <Button
                className="form_cancel"
                size="large"
                type="secondary"
                onClick={this.closeModal}
              >
                Close
              </Button>

              <Button
                style={{ float: 'right', width: '50%' }}
                size="large"
                type="primary"
                onClick={this.onSubmit}
              >
                Save
              </Button>
            </div>
          </span>
        </ModalContainer>
      </span>
    )
  }
}

AddUsersModal.propTypes = {
  copy: PropTypes.object.isRequired
}

AddUsersModal = Form({
  fields: ({ copy }) => ({
    first: [isRequired(copy.get('users.modal.fName'))],
    last: [isRequired(copy.get('users.modal.lName'))],
    mobile: [isRequired(copy.get('users.modal.mobile'))],
    email: [isRequired(copy.get('users.modal.email'))],
    work: [],
    identity_number: [],
    role: []
  }),
  submit: ({ props, fields }) => {
    props.onSave(fields)
  }
})(AddUsersModal)

AddUsersModal = ListPage()(AddUsersModal)

export default AddUsersModal
