/* global google */

export const GOOGLE_MAP_URL = "https://maps.googleapis.com/maps/api/js?client=gme-trackmaticsolutions&libraries=drawing,geometry,places&callback=gmClientCb"
export const MARKER_SVG_PATH = "M0-77.834C-14.635-77.834-26.5-65.969-26.5-51.334-26.5-33.459 0-.166 0-.166S26.5-33.5 26.5-51.334C26.5-65.969 14.635-77.834 0-77.834ZM0-41.027C-5.691-41.027-10.306-45.64-10.306-51.334-10.306-57.025-5.692-61.639 0-61.639S10.306-57.026 10.306-51.334C10.306-45.641 5.691-41.027 0-41.027Z"

export { MAP } from 'react-google-maps/lib/constants'

export const animations = {
	NONE: () => null,
	BOUNCE: () => google.maps.Animation.BOUNCE,
	DROP: () => google.maps.Animation.DROP
}

export const controlPosition = {
	TOP_CENTER: () => google.maps.ControlPosition.TOP_CENTER,
	TOP_LEFT: () => google.maps.ControlPosition.TOP_LEFT,
	TOP_RIGHT: () => google.maps.ControlPosition.TOP_RIGHT,
	LEFT_TOP: () => google.maps.ControlPosition.LEFT_TOP,
	RIGHT_TOP: () => google.maps.ControlPosition.RIGHT_TOP,
	LEFT_CENTER: () => google.maps.ControlPosition.LEFT_CENTER,
	RIGHT_CENTER: () => google.maps.ControlPosition.RIGHT_CENTER,
	LEFT_BOTTOM: () => google.maps.ControlPosition.LEFT_BOTTOM,
	RIGHT_BOTTOM: () => google.maps.ControlPosition.RIGHT_BOTTOM,
	BOTTOM_CENTER: () => google.maps.ControlPosition.BOTTOM_CENTER,
	BOTTOM_LEFT: () => google.maps.ControlPosition.BOTTOM_LEFT,
	BOTTOM_RIGHT: () => google.maps.ControlPosition.BOTTOM_RIGHT,
} 

const jacobsdal = { lat: -29.133293, lng: 24.797398 } // random Freestate town
export const ZA_DEFAULT_CENTER = Object.freeze(jacobsdal)

const trackmaticHq = { lat: -26.127684, lng: 28.073161 } // trackmatic solutions
export const TRACKMATIC_HQ = Object.freeze(trackmaticHq)