import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { MapOverlay } from 'shared/components'

class MapContextMenu extends Component {

	static propTypes = {
		google: PropTypes.object,
		map: PropTypes.object,
		position: PropTypes.array,
		children: PropTypes.array
	}

	constructor(props) {
		super(props)
		this.state = { position: props.position }
	}

	componentDidMount = () => {
		document.body.addEventListener('click', this.close)
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ position: nextProps.position })
	}

	componentWillUnmount = () => {
		document.body.removeEventListener('click', this.close)
	}

	close = () => {
		this.setState({ position: null })
	}

	renderChildren(items) {
		return items && React.Children.map(items, child => {
			return (<div className="map_context-menu_item">
				{child ? React.cloneElement(child, {
					...this.props
				}) : null}
			</div>)
		})
	}

	render() {
		return (this.state.position && this.props.children ? <MapOverlay
			{...this.props}
			position={this.state.position}>
			<div className="map_context-menu">
				{this.renderChildren(this.props.children)}
			</div>
		</MapOverlay> : null)
	}
}

export default MapContextMenu