import { createStore, applyMiddleware, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunkMiddleware from 'redux-thunk'

import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'

export const history = createBrowserHistory()

export default function configureStore(preloadedState = {}) {
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    enableBatching(createRootReducer(history)),
    preloadedState,
    composeEnhancer(applyMiddleware(routerMiddleware(history), thunkMiddleware))
  )
  store.asyncReducers = {}
  return store
}
