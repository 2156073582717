import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal as ReactModal } from 'react-overlays'
import { isUndefined } from 'lodash'
import classnames from 'classnames'

export default ({ modalClassName, modalStyle, contClassName, contStyle } = {}) => Child => {
  const propTypes = {
    modalTrigger: PropTypes.node,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    closeOnBackgroundClick: PropTypes.bool,
    children: PropTypes.node,
    isOpen: PropTypes.bool
  }

  class Modal extends Component {
    constructor(props) {
      super(props)
      this.state = { isOpen: props.isOpen }
    }

    componentDidMount() {
      const { onModalOpen } = this.props
      if (this.state.isOpen && onModalOpen) onModalOpen()
    }

    componentDidUpdate(prevProps, prevState) {
      const { onModalOpen, onModalClose } = this.props
      const { isOpen } = this.state
      if (isOpen !== prevState.isOpen) {
        if (isOpen && onModalOpen) onModalOpen()
        if (!isOpen && onModalClose) onModalClose()
      }
    }

    render() {
      const {
        modalTrigger,
        children,
        closeOnBackgroundClick = true,
        isOpen,
        ...childProps
      } = this.props

      return (
        <>
          <span onClick={() => this.setState({ isOpen: true })}>
            {modalTrigger || null}
            {children || null}
          </span>
          <ReactModal show={isUndefined(isOpen) ? this.state.isOpen : isOpen} backdrop={false}>
            <div
              className={classnames('modal_cont center-cont', contClassName)}
              style={contStyle}
              onClick={e => {
                if (closeOnBackgroundClick && e.target.classList.contains('modal_cont')) {
                  this.setState({ isOpen: false })
                }
              }}
            >
              <div className={classnames('modal center', modalClassName)} style={modalStyle}>
                <Child {...childProps} closeModal={() => this.setState({ isOpen: false })} />
              </div>
            </div>
          </ReactModal>
        </>
      )
    }
  }

  Modal.propTypes = propTypes

  return Modal
}
