import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isUndefined } from 'lodash'
import st from './Checkbox.module.scss'

const propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  context: PropTypes.string,
  value: PropTypes.string,
  reff: PropTypes.func,
  onChange: PropTypes.func
}

class Checkbox extends Component {
  constructor(props) {
    super(props)
    this.state = { value: !!props.defaultValue }
  }

  render() {
    const {
      className,
      inputClassName,
      label,
      invalid,
      reff,
      onChange,
      context,
      disabled = false
    } = this.props

    let { value = this.state.value } = this.props

    value = !!value

    return (
      <div
        className={classnames('form_element form_element-checkbox', className, {
          'is-invalid': invalid
        })}
      >
        <div
          ref={el => {
            if (!el) return
            this.$checkbox = el
            if (reff) reff(el)
          }}
          onClick={() => {
            if (disabled) return
            if (isUndefined(this.props.value)) {
              this.setState({ value: !value })
            }
            this.$checkbox.value = !value
            if (onChange) onChange(!value, context)
          }}
          className={classnames(st.checkbox, inputClassName, {
            [`${st['is-invalid']}`]: invalid,
            disabled
          })}
        >
          {value ? <i className="i-check" /> : null}
        </div>
        {label ? <span className={st.checkbox_label}>{label}</span> : null}
      </div>
    )
  }
}

Checkbox.propTypes = propTypes

export { Checkbox }
