import React, { Component } from 'react'
import pubsub from 'sweet-pubsub'

const EmailGatePassModalHoc = Child => {
  class Hoc extends Component {
    constructor(props) {
      super(props)
      this.state = {
        show: false,
        booking: {}
      }
    }

    componentWillMount = () => {
      pubsub.on('portal/bookings/email/gatepass/open', 'email', booking => {
        this.setState(
          {
            booking
          },
          () => {
            this.open()
          }
        )
      })
    }

    componentWillUnmount = () => {
      pubsub.off('email')
    }

    open = () => {
      this.setState({ show: true })
    }

    close = () => {
      this.setState({ show: false })
    }

    render = () => {
      return this.state.show ? (
        <Child
          {...this.props}
          open={this.open}
          close={this.close}
          show={this.state.show}
          booking={this.state.booking}
        />
      ) : null
    }
  }

  return Hoc
}

export default EmailGatePassModalHoc
