import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Input } from 'shared/components'
import api from 'shared/api'
import { debounce } from 'lodash'
import { connect } from 'react-redux'

const mapStateToProps = ({ app: { user } }) => ({
  user
})

const propTypes = {
  setValidationMessages: PropTypes.func,
  personId: PropTypes.string,
  user: PropTypes.object,
  disabled: (PropTypes.bool = true)
}

class UsernameInput extends Component {
  constructor(props) {
    super(props)
    this.state = { icon: 'person_outline' }
  }

  validate = debounce(input => {
    const { setValidationMessages, personId } = this.props
    let params = {
      username: input,
      ...(personId && { personId: personId })
    }

    //todo: move to shared/state/actions
    if (input && input.trim().length > 0) {
      api.get(`link_accounts/suppliers/username/availability`, params).then(
        res => {
          setValidationMessages && setValidationMessages([])
          this.setState({ icon: 'check' })
        },
        ({ errors }) => {
          setValidationMessages && setValidationMessages(errors)
          this.setState({ icon: 'close' })
        }
      )
    }
  }, 300)

  controlEnabled = () => {
    const { user, disabled } = this.props
    if (disabled === false) {
      return true
    } else if (user && user.identity.is_elevated) {
      return true
    }
    return false
  }

  render() {
    // Excludes some items from props
    const { setValidationMessages, personId, user, dispatch, disabled, ...props } = this.props
    return (
      <Input
        {...props}
        disabled={!this.controlEnabled()}
        onKeyUp={e => this.validate(e.target.value)}
        iconLeft={this.state.icon}
      />
    )
  }
}

UsernameInput = connect(mapStateToProps)(UsernameInput)
UsernameInput.propTypes = propTypes

export { UsernameInput }
