import api, { handleError, handleSuccess } from 'shared/api/index'
import { push } from 'connected-react-router'
import { toApi } from './resource'

const path = 'link/suppliers'

export const fetchCompanyInfo = entityId => dispatch => {
  api.get(`${path}/${entityId}/init`).then(({ body: { data } }) => {
    dispatch({
      type: 'shared/form/FETCHED_ITEM',
      item: data
    })
  }, handleError)
}

export const updateCompanyInfo = (entityId, info) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.put(`${path}/${entityId}/update`, toApi(info)).then(
    () => {
      handleSuccess('Company Information updated successfully')
      dispatch(push(`/portal/${entityId}`))
    },
    ({ errors }) => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: errors
      })
    }
  )
}
