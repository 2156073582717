import { formatDateTime, formatDiffDuration } from 'shared/lib/dateTime'
import moment from 'moment'

export const toApi = item => ({
  ...item
})

export const toClient = item => ({
  ...item.data
})

export const toBooking = item => ({
  id: item.id,
  reference: item.reference,
  begin: formatDateTime(item.begin),
  end: formatDateTime(item.end),
  duration: moment.utc(moment(item.end).diff(moment(item.begin))).format('HH:mm'),
  bay: {
    id: item.bay ? item.bay.id : '',
    reference: item.bay ? item.bay.reference : ''
  },
  facility_name: item.facility_name,
  facility_id: item.facility_id,
  customer: item.customer,
  customer_id: item.customer_id,
  // consignors: item.consignors.join(', '),
  // consignorCount: item.consignors.length,
  consignmentsCount: item.transaction_count,
  consignments: item.consignments.join(', '),
  status: item.status,
  statusChangedAt: item.status_changed_at,
  transactionCount: item.transaction_count,
  hasReturns: item.has_returns ? 'Yes' : 'No',
  display: {
    bay:
      // eslint-disable-next-line no-nested-ternary
      (item.actuals && item.actuals === null) || (item.actuals && item.actuals.bay === null)
        ? item.bay
          ? item.bay.reference
          : ''
        : `${item.actuals ? item.actuals.bay.reference : ''} (${
            item.bay ? item.bay.reference : ''
          })`,
    date:
      (item.actuals && item.actuals === null) || (item.actuals && item.actuals.in_facility === null)
        ? formatDateTime(item.begin)
        : `${item.actuals && formatDateTime(item.actuals.in_facility)} (${item.actuals &&
            formatDateTime(item.begin)})`,
    duration: () => {
      if (item.actuals === null || item.actuals.in_facility === null)
        return moment.utc(moment(item.end).diff(moment(item.begin))).format('HH:mm')
      return `${formatDiffDuration(
        item.actuals.in_facility,
        item.actuals.out_facility === null ? moment() : item.actuals.out_facility
      )} (${moment.utc(moment(item.end).diff(moment(item.begin))).format('HH:mm')})`
    },
    isActualGreaterThanPlanned: () => {
      const plannedDuration = moment.utc(moment(item.end).diff(moment(item.begin))).format('HH:mm')
      const actualDuration = moment
        .utc(
          moment(item.actuals && item.actuals.out_facility).diff(
            moment(item.actuals && item.actuals.in_facility)
          )
        )
        .format('HH:mm')
      if (item.actuals) {
        return actualDuration > plannedDuration
      }

      return false
    }
  }
})

export const toReportParameters = (facilityId, bookingId) => ({
  format: 'rpt',
  arguments: [
    {
      name: 'facilityId',
      type: 'string',
      value: facilityId
    },
    {
      name: 'bookingId',
      type: 'string',
      value: bookingId
    }
  ]
})

export const bookingStatuses = [
  {
    value: 'planning',
    label: 'Planning'
  },
  {
    value: 'planned',
    label: 'Planned'
  },
  {
    value: 'in_facility',
    label: 'In Facility'
  },
  {
    value: 'in_bay',
    label: 'In Bay'
  },
  {
    value: 'out_bay',
    label: 'Out Bay'
  },
  {
    value: 'out_facility',
    label: 'Out Facility'
  },
  {
    value: 'in_sorting',
    label: 'In Sorting'
  },
  {
    value: 'out_sorting',
    label: 'Out Sorting'
  }
]
