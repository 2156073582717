import api from 'shared/api/index'
import { selectToken } from 'shared/state/actions'

export const submit = user => async dispatch => {
  dispatch({ type: 'shared/form/SUBMITTING' })
  try {
    const { data } = await api.post('link_accounts/suppliers/auth', user)

    if (data.master_entity.has_registered) {
      return dispatch(selectToken(data, `/portal/${data.master_entity.id}`))
    }

    return dispatch(selectToken(data, '/setup'))
  } catch ({ errors }) {
    console.log(errors)
    dispatch({
      type: 'shared/form/SUBMITTED',
      messages: errors
    })
  }
}
