import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import pubsub from 'sweet-pubsub'
import MapDirectionsHOC from './MapDirectionsHOC'
import MapDirectionsMenuItem from './MapDirectionsMenuItem'
import MapDirectionsPanel from './MapDirectionsPanel'

class MapDirections extends Component {

	static propTypes = {
		hideContextMenu: PropTypes.func,
		registerContextMenuItem: PropTypes.func.isRequired,
		addPoint: PropTypes.func.isRequired,
		removePoint: PropTypes.func.isRequired,
		showPanel: PropTypes.func.isRequired,
		setPanel: PropTypes.func.isRequired,
		close: PropTypes.func.isRequired,
		hidePanel: PropTypes.func.isRequired
	}

	componentDidMount = () => {
		this.props.registerContextMenuItem(<MapDirectionsMenuItem key={'directions'} onClick={this.addPoint} />)

		// events posted from MapAddressSearch
		pubsub.on('directionsPointAdded', (point) => { this.addPoint(point) })
	}

	addPoint = (position) => {
		this.props.hideContextMenu()
		this.props.addPoint(position, () => {
			this.props.showPanel(<MapDirectionsPanel {...this.props} close={this.close} panelRef={this.props.setPanel} removePoint={this.removePoint} />)
		})
	}

	removePoint = (position) => {
		this.props.removePoint(position, () => {
			this.props.showPanel(<MapDirectionsPanel {...this.props} close={this.close} panelRef={this.props.setPanel} removePoint={this.removePoint} />)
		})
	}

	close = () => {
		this.props.close(() => {
			this.props.hidePanel()
		})
	}

	render() {
		return (null)
	}
}

export default MapDirectionsHOC(MapDirections)