import api, { handleError } from 'shared/api/index'
import { push } from 'connected-react-router'
import { storePortalData } from 'shared/state/actions'
import { toApi } from './resource'

const path = 'link/suppliers'

export const storeWidgetData = (data, status, path = '') => dispatch => {
  dispatch({ type: 'account/STORE_WIZARD_DATA', data, status })

  if (path) {
    dispatch(push(`/setup/${path}`))
  }
}

export const storePortalSetup = (entityId, fields) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.put(`${path}/${entityId}/register`, toApi(fields)).then(
    ({ body: { data } }) => {
      dispatch({
        type: 'app/FETCHED_PORTAL_CONFIG',
        portal: data
      })
      dispatch(push(`/portal/${entityId}`))
    },
    ({ error }) => {
      console.log(error)
      dispatch(push(`/setup`))
    }
  )
}

export const uploadDocument = (entityId = 'dq5jm4clhayx5cbxa9nv0qc6o', fData) => dispatch => {
  dispatch({ type: 'shared/form/SUBMITTING' })
  return api.post(`${path}/${entityId}/logo/upload`, fData).then(
    ({ body }) => {
      dispatch({ type: 'shared/form/SUBMITTED' })
      return body
    },
    ({ error }) => {
      console.log(error)
    }
  )
}

export const getStoredImage = (entityId, logoId) => async dispatch => {
  const { res } = await api.get(`blob-storage/${entityId}/${logoId}`)
  storePortalData({ logoUrl: res.req.url })
  dispatch({
    type: 'app/FETCHED_PORTAL_CONFIG',
    portal: { logoUrl: res.req.url }
  })
}

export const fetchPortal = entityId => dispatch => {
  api.get(`${path}/${entityId}/init`).then(({ body: { data } }) => {
    storePortalData(data)
    dispatch({
      type: 'app/FETCHED_PORTAL_CONFIG',
      portal: data
    })
  }, handleError)
}
