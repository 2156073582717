export default {
  blue: '#00beef',
  green: '#00A54F',
  purple: '#e0b5f2',
  orange: '#f79226',
  yellow: '#FAC51C',
  lightred: '#ff9a93',
  red: '#DD584F',
  grey: '#6d6e70',
	lightest: '#fff',
	disabled: '#E6E7E9',
	textLight: '#b3b3b3',
}
