export const toApi = item => ({
  ...item
})

export const toClient = item => ({
  ...item.data
})


export const contactApi = {
  contact(item) {
    return {
      first_name: item.first_name,
      last_name: item.last_name,
      email: item.email,
      contact_no: item.contact_no,
      designation: item.designation
    }
  }
}
