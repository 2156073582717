import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GoogleMapsAsyncLoader from 'shared/higher-order-components/GoogleMapsAsyncLoader'
import Intl from 'shared/higher-order-components/Intl'
import { Input, Button } from 'shared/components'
import { geocode } from 'shared/lib/geo'
import AddressForm from './AddressForm'

const propTypes = {
  google: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  addressData: PropTypes.object.isRequired,
  copy: PropTypes.object.isRequired
}

class AvtmStructuredSearchTab extends Component {
  constructor(props) {
    super(props)
    this.submitSearch = this.submitSearch.bind(this)
  }

  submitSearch() {
    const { google, onChange } = this.props
    geocode(
      google,
      {
        address: [
          this.$street_no.value,
          this.$street.value,
          this.$suburb.value,
          this.$city.value,
          this.$province.value,
          this.$postal_code.value
        ].join(' ')
      },
      onChange
    )
  }

  render() {
    const { closeModal, onChange, addressData, copy } = this.props
    return (
      <div className="avtm_sst">
        <div className="avtm_sst-top">
          <div className="row">
            <div className="col-2">
              <Input reff={el => (this.$street_no = el)} label={copy.form('street_no')} />
            </div>
            <div className="col-6">
              <Input reff={el => (this.$street = el)} label={copy.form('street')} />
            </div>
            <div className="col-4">
              <Input reff={el => (this.$suburb = el)} label={copy.form('suburb')} />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <Input reff={el => (this.$city = el)} label={copy.form('city')} />
            </div>
            <div className="col-5">
              <Input reff={el => (this.$province = el)} label={copy.form('province')} />
            </div>
            <div className="col-2">
              <Input reff={el => (this.$postal_code = el)} label={copy.form('postal_code')} />
            </div>
          </div>
          <Button
            className="form_submit"
            size="small"
            iconRight="search"
            onClick={this.submitSearch.bind(this)}
          >
            {copy.shared('search')}
          </Button>
        </div>
        <AddressForm onChange={onChange} closeModal={closeModal} addressData={addressData} />
      </div>
    )
  }
}

AvtmStructuredSearchTab.propTypes = propTypes

AvtmStructuredSearchTab = GoogleMapsAsyncLoader(AvtmStructuredSearchTab)

export default Intl({ avt: 'shared.avt', form: 'shared.avt.form' })(AvtmStructuredSearchTab)
