import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import st from './Spinner.module.scss'

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  center: PropTypes.number,
  style: PropTypes.object
}

const Spinner = ({ className = '', size, center, style = {}, ...props }) => {
  let autoStyle = {}
  if (size) {
    autoStyle = {
      ...autoStyle,
      width: size,
      height: size,
      borderWidth: size / 8
    }
  }
  if (center) {
    autoStyle = {
      ...autoStyle,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginLeft: -center / 2,
      marginTop: -center / 2
    }
  }
  return (
    <div
      {...props}
      style={{ ...autoStyle, ...style }}
      className={classnames(st.spinner, className)}
    />
  )
}

Spinner.propTypes = propTypes

export { Spinner }
