export default {
  auth: {
    heading: 'supplier portal',
    trouble: 'Trouble signing in?',
    signIn: 'Sign In',
    signingIn: 'Signing In',
    rememberMe: 'Remember me',
    notRegistered: 'Not registered? Register now.',
    username: 'Username',
    password: 'Password',
    newPassword: 'New Password',
    changePassword: 'Change Password',
    confirmPass: 'Confirm Password',
    passwordsMustMatch: 'Passwords must match',
    passwordConfirmation: 'Confirm New Password',
    registration: 'Registration',
    name: 'Name',
    email: 'Email',
    mobile: 'Mobile',
    decline: 'Decline Request',
    accept: 'Accept Request',
    declineText: 'Are you sure you want to decline request?',
    genarateKey: 'Genarate Key',
    genaratingKey: 'Generating Key',
    verifyText: 'Enter Verification Code',
    verifyCode: 'Verify Code',
    verifyMobile: 'Verify Mobile',
    resetCode: 'Reset Code',
    submit: 'Submit',
    submitting: 'Submitting'
  },
  settings: {
    account: 'Account',
    accountSettings: 'Account Settings',
    changePassword: 'Change Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    middleName: 'Middle Name',
    preferredName: 'Prefered Name',
    mobileNumber: 'Mobile Number',
    telNumber: 'Tel Number',
    workNumber: 'Work Number',
    email: 'Email',
    idNumber: 'Identity No.',
    department: 'Department',
    position: 'Position',
    title: 'Title',
    gender: 'Gender',
    username: 'Username'
  },
  wizard: {
    heading: 'supplier portal',
    confirm: {
      vatNum: 'Vat No.',
      confirmVatNum: 'Re-Type Vat number to confirm',
      note: 'If the above number is incorrect please contact your administrator',
      numCorrect: 'I confirm the number is correct',
      verify: 'Please verify your company vat number.'
    },
    details: {
      verify: 'Please verify your company details.',
      carrierName: 'Company / Display Name *',
      carrierId: 'Registered Name',
      vatNo: 'Vat No.',
      regNo: 'Reg No.'
    },
    address: {
      desc: 'Supply your full address below',
      address: 'Address',
      city: 'City',
      province: 'Province',
      country: 'Country',
      zip: 'Zip Code'
    },
    display: {
      desc: `If you don’t want any custom branding our default theme will be applied. (You can update
        these settings at any time)`,
      pColor: 'Primary Color',
      sColor: 'Secondary Color',
      note: 'For best results please update a transparent .png file 250x75 pixels in size.'
    },
    account: {
      desc: 'Who will manage this portal?',
      name: 'Name',
      surname: 'Surname',
      idNum: 'ID Number',
      mobile: 'Mobile',
      email: 'Email',
      officeNum: 'Office Number'
    },
    users: {
      heading: 'Add some users',
      desc: 'Don’t worry you can skip this step and do it later if you prefer',
      modal: {
        heading: 'Add a new user',
        iconHeading: 'Add your first user',
        fName: 'First Name',
        lName: 'Last Name',
        email: 'Email Address (Username)',
        mobile: 'Mobile Number',
        jobTitle: 'Designation / Job Title',
        officeNum: 'Office Number',
        permission: 'Permissions',
        admin: 'Admin',
        user: 'User',
        view: 'View Only',
        idNum: 'Identity Number'
      }
    }
  }
}
