import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Intl from 'shared/higher-order-components/Intl'
import classnames from 'classnames'
import { Modal as ReactModal } from 'react-overlays'
import { Button, ValidationList, FormSubmit, Spinner, Input } from 'shared/components'
import pubsub from 'sweet-pubsub'
import Form, { isRequired, serverMessage } from 'shared/higher-order-components/Form'
import storage from 'shared/lib/storage'
import { emailGatePass } from '../../../state/bookings/bookingActions'

import EmailGatePassModalHoc from './EmailGatePassModalHoc'

class EmailGatePassModal extends Component {
  static propTypes = {
    copy: PropTypes.object.isRequired,
    orgId: PropTypes.string.isRequired,
    facilityId: PropTypes.string.isRequired,
    booking: PropTypes.object.isRequired,
    width: PropTypes.number,
    submit: PropTypes.func.isRequired,
    formStatus: PropTypes.string.isRequired,
    updateField: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired
  }

  state = {
    headerBg: ''
  }

  componentWillMount() {
    const headerBg = storage.local.getObj('portal_data', 'portal_settings.primary_color')
    this.setState({ headerBg })
  }

  static defaultProps = {
    width: 640
  }

  render() {
    const {
      width,
      copy,
      messages,
      booking,
      submit,
      formStatus,
      updateField,
      fields,
      emailGatePass,
      show,
      close
    } = this.props

    return (
      <ReactModal show={show} backdrop>
        <div
          className={classnames('modal_cont center-cont')}
          style={{
            padding: 0
          }}
        >
          <div
            className={classnames('modal center')}
            style={{
              width: width,
              background: '#FFF'
            }}
          >
            <i className="i-close modal_close" onClick={close} />
            <div style={{ background: this.state.headerBg }} className="modal_header">
              {copy.get('email.title', { reference: booking.reference })}
            </div>
            <div className="form" style={{ margin: 0 }}>
              <ValidationList messages={messages} />
              <Input iconLeft="mail_outline" {...fields.email} label={copy.get('Email')} />
              <Button className="form_cancel" type="secondary" onClick={close}>
                {copy.get('Cancel')}
              </Button>
              <FormSubmit
                size="medium"
                formType={'default'}
                formStatus={formStatus}
                onClick={submit}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}

EmailGatePassModal = Form({
  fields: ({ copy }) => ({
    email: [isRequired(copy.get('email'))]
  }),
  mapPropsToFields: props => false,
  isFormReady: ({ props }) => true,
  submit({ props, fields }) {
    const { orgId, booking, close, copy } = props

    emailGatePass(orgId, booking.id, fields).then(() => {
      pubsub.emit('toast', {
        title: copy.get('email.title', { reference: booking.reference }),
        message: copy.get('success'),
        duration: 5000,
        type: 'success'
      })
      close()
    })
  }
})(EmailGatePassModal)

EmailGatePassModal = EmailGatePassModalHoc(EmailGatePassModal)

export default Intl({
  get: 'portal.bookings'
})(EmailGatePassModal)
