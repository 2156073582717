import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import classnames from 'classnames'

class ModalContainer extends Component {
  static propTypes = {
    modalTrigger: PropTypes.node,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    shouldCloseOnOverlayClick: PropTypes.bool,
    shouldCloseOnEsc: PropTypes.bool,
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    modalClassName: PropTypes.string,
    contentLabel: PropTypes.string.isRequired,
    modalStyle: PropTypes.object,
    contClassName: PropTypes.string,
    contStyle: PropTypes.object,
    onShow: PropTypes.func
  }

  componentWillReceiveProps = props => {
    if (props.isOpen && !this.props.isOpen) {
      const { onShow } = this.props
      onShow && onShow()
    }
  }

  render() {
    const {
      isOpen,
      children,
      modalClassName,
      modalStyle,
      contClassName,
      contStyle,
      contentLabel,
      shouldCloseOnOverlayClick,
      shouldCloseOnEsc
    } = this.props

    return (
      <Modal
        onShow={() => {}}
        contentLabel={contentLabel}
        style={{ overlay: { position: 'absolute' }, content: { ...contStyle } }}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        isOpen={isOpen}
        className={classnames('modal_cont center-cont', contClassName)}
        {...this.props}
      >
        <div className={classnames('modal center', modalClassName)} style={modalStyle}>
          {children}
        </div>
      </Modal>
    )
  }
}

export default ModalContainer
