export const toApi = item => ({
  name: item.name,
  registered_name: item.registered_name,
  vat_no: item.vat_no,
  address: item.address,
  portal_settings: {
    primary_color: item.primary_color,
    secondary_color: item.secondary_color,
    logo_url: item.logo_url
  },
  users:
    item.users &&
    item.users.map(user => ({
      names: {
        first: user.first,
        last: user.last,
        middle: '',
        preferred: '',
        user_name: user.user_name
      },
      numbers: {
        tel: user.tel,
        mobile: user.mobile,
        work: user.work
      },
      email: user.email,
      identity_number: user.identity_number,
      role: user.role
    }))
})
