import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Input } from 'shared/components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { storeWidgetData } from '../../state/wizard/WizardActions'
import AddUsersModal from './AddUsersModal'

const AddUsers = ({ copy, storeWidgetData }) => {
  const [userData, updateUser] = useState([])

  const removeUser = key => updateUser(userData.filter(pred => pred.mobile !== key))

  const transform = item => ({
    users: [...item]
  })

  return (
    <div className="form form-widget text-left">
      <div className="form_heading">{copy.get('users.heading')}</div>
      <div className="form_sub-heading">{copy.get('users.desc')}</div>

      {userData && (
        <div className="row">
          {userData.map(item => (
            <div className="col-12">
              <Input
                readonly
                iconRight="trash"
                value={`${item.first} ${item.last} (${item.email})`}
                iconClicked={() => removeUser(item.mobile)}
              />
            </div>
          ))}
        </div>
      )}

      <AddUsersModal
        classN={userData.length ? 'modified' : ''}
        copy={copy}
        onSave={user => updateUser([...userData, user])}
      />

      <Link to="/setup/finish">
        <Button className="form_cancel" size="large" type="secondary">
          Skip
        </Button>
      </Link>

      <Link to="/setup/finish">
        <Button
          onClick={storeWidgetData(transform(userData), true)}
          className="form_cancel"
          style={{ float: 'right', width: '50%' }}
          size="large"
          type="primary"
        >
          Finish
        </Button>
      </Link>
    </div>
  )
}

AddUsers.propTypes = {
  copy: PropTypes.object.isRequired,
  storeWidgetData: PropTypes.func.isRequired
}

export default connect(
  null,
  { storeWidgetData }
)(AddUsers)
