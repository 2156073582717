/* eslint react/no-multi-comp: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'shared/components'
import { isNaN } from 'lodash'
import moment from 'moment'

const propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  iconRight: PropTypes.string,
  iconLeft: PropTypes.string,
  type: PropTypes.string,
  styleType: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  incHours: PropTypes.number,
  disabled: PropTypes.bool,
  incMinutes: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  reff: PropTypes.func,
  onKeyDown: PropTypes.func
}

const BACKSPACE = 8
const TAB = 9
const SPACE = 32
const LEFT = 37
const UP = 38
const RIGHT = 39
const DOWN = 40
const DELETE = 46
const C_KEY = 67
const V_KEY = 86

class TimePicker extends React.Component {
  static defaultProps = {
    value: new Date(),
    incHours: 1,
    incMinutes: 1,
    onBlur: () => false
  }

  constructor(props) {
    super(props)

    this.state = {
      displayTime: props.value ? moment(props.value).format('HH:mm') : '00:00',
      value: props.value,
      units: 'hours',
      hours: 0,
      minutes: 0,
      seconds: 0,
      overwrite: true,
      highlight: true
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (!nextProps.value) {
      this.setDate(moment().startOf('day'))
      return
    }

    const dateFromState = moment(this.state.value)
    const nextDateFromProps = moment(nextProps.value)

    if (!this.state.initialized) {
      this.setDate(nextDateFromProps)
      this.setState({ initialized: true })
      return
    }

    if (dateFromState.isSame(nextDateFromProps)) return

    if (!moment(this.state.value).isSame(moment(nextProps.value), 'day')) {
      const value = nextDateFromProps.clone()

      const hours = dateFromState.hours()
      const minutes = dateFromState.minutes()
      const seconds = dateFromState.seconds()

      value.hours(hours)
      value.minutes(minutes)
      value.seconds(seconds)

      this.setDate(value)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.isEditingHours() ? this.input.setSelectionRange(0, 2) : this.input.setSelectionRange(3, 5)

    const prevDate = moment(prevState.value)
    const nextDate = moment(this.state.value)

    if (!prevDate.isSame(nextDate)) {
      this.props.onChange(
        nextDate
          .utc()
          .toDate()
          .toISOString()
      )
    }
  }

  componentWillUnmount() {
    this.setState({
      displayTime: '00:00',
      value: null,
      units: 'hours',
      hours: 0,
      minutes: 0,
      seconds: 0,
      overwrite: true,
      highlight: true,
      initialized: false
    })
  }

  setDate = momentDate => {
    const value = momentDate.toDate()
    const hours = momentDate.hours()
    const minutes = momentDate.minutes()
    const seconds = momentDate.seconds()
    const displayTime = momentDate.format('HH:mm')
    this.setState({ value, hours, minutes, seconds, displayTime })
  }

  getCurrentUnitValue = () => {
    const { units } = this.state
    return this.state[units]
  }

  setCurrentUnitValue = (value, isIncrement = false) => {
    const editingHours = this.isEditingHours()

    const isValid = editingHours ? this.isValidHour : this.isValidMinute

    // check if the current input is valid
    if (isValid(value)) {
      const unit = this.getCurrentUnit()
      let newState = {
        [unit]: value,
        overwrite: false
      }
      // if any further input would not be valid then skip and select the next units
      if (!isIncrement && !isValid(value * 10) && editingHours) {
        newState.units = 'minutes' // todo
        newState.overwrite = true
        newState.highlight = true
      }

      this.setState(prevState => newState)
      const date = moment(this.state.value)
      date[unit](value)
      this.setDate(date)
    }
  }

  getIncrementValue = () => {
    // increment to next multiple of specified valud
    const { incHours, incMinutes } = this.props
    const value = this.getCurrentUnitValue()
    let inc = this.isEditingHours() ? incHours : incMinutes
    let mod = value % inc
    return mod === 0 ? inc : inc - mod
  }

  getDecrementValue = () => {
    // decrement to next multiple of specified valud
    const { incHours, incMinutes } = this.props
    const value = this.getCurrentUnitValue()
    let dec = this.isEditingHours() ? incHours : incMinutes
    return value % dec || dec
  }

  getCurrentUnit = () => this.state.units

  isEditingHours = () => this.getCurrentUnit() === 'hours'

  isEditingMinutes = () => this.getCurrentUnit() === 'minutes'

  inc = () => {
    let val = this.getCurrentUnitValue() + this.getIncrementValue()
    if (this.isEditingHours() && !this.isValidHour(val)) val = 23
    else if (this.isEditingMinutes() && !this.isValidMinute(val)) val = 59
    this.setCurrentUnitValue(val, true)
  }

  dec = () => {
    const unit = this.getCurrentUnit()
    let val = this.getCurrentUnitValue() - this.getDecrementValue()
    this.setCurrentUnitValue(val > 0 ? val : 0, true)
  }

  onKeyDown = e => {
    const { ctrlKey, keyCode, shiftKey, key } = e
    let preventDefault = true

    switch (keyCode) {
      case BACKSPACE:
      case DELETE:
        this.backspaceUnit()
        break
      case LEFT:
      case RIGHT:
        this.isEditingHours() ? this.setUnits('minutes') : this.setUnits('hours')
        break
      case UP:
        this.inc()
        break
      case DOWN:
        this.dec()
        break
      case TAB:
      case SPACE:
        this.next(shiftKey)
        break
      case C_KEY:
      case V_KEY:
        preventDefault = !ctrlKey // copy or paste
        break
      default:
        const num = +e.key
        !isNaN(num) && this.onNumberTyped(num)
    }

    preventDefault && e.preventDefault()
  }

  onNumberTyped = num => {
    const { overwrite } = this.state

    const currVal = this.getCurrentUnitValue()
    let nextVal = currVal * 10 + num

    this.setCurrentUnitValue(overwrite ? num : nextVal)
  }

  isValidHour = num => {
    return num >= 0 && num <= 23
  }

  isValidMinute = num => {
    return num >= 0 && num <= 59
  }

  backspaceUnit = () => {
    const currentValue = this.getCurrentUnitValue()
    if (currentValue === 0 && this.isEditingMinutes())
      // todo make more robust
      this.setUnits('hours')
    else this.setCurrentUnitValue(Math.floor(currentValue / 10))
  }

  next = (backwards = false) => {
    if (this.isEditingHours()) backwards ? this.input.blur() : this.setUnits('minutes')
    else backwards ? this.setUnits('hours') : this.input.blur()
  }

  onClick = e => {
    const pos = e.target.selectionStart
    this.setUnits(pos < 3 ? 'hours' : 'minutes', true)

    e.preventDefault()
  }

  setUnits = (units, force = false) => {
    if (force || this.state.units !== units) {
      this.setState({ units, overwrite: true, highlight: true })
    }
  }

  onFocus = e => {
    this.setState({
      overwrite: true,
      highlight: true,
      units: 'hours'
    })
  }

  render() {
    const { incHours, incMinutes, onChange, ...inputProps } = this.props

    return (
      <Input
        reff={el => (this.input = el)}
        {...inputProps}
        onFocus={this.onFocus}
        placeholder="hh:mm"
        value={this.state.displayTime}
        onMouseDown={this.onMouseDown}
        onKeyDown={this.onKeyDown}
        onClick={this.onClick}
      />
    )
  }
}

TimePicker.propTypes = propTypes

export { TimePicker }
