import superagent from 'superagent'
import pubsub from 'sweet-pubsub'
import { each, isArray, get } from 'lodash'
import { selectToken } from 'shared/state/actions'
import { copy } from 'shared/lib/intl'
import { toCamelCaseKeys, toSnakeCaseKeys } from 'shared/lib/utils'
import { decodeTokenString, getToken, getTokenForOrganisation } from 'shared/api/tokens'
import { push } from 'connected-react-router'

const baseURL = 'https://rest.trackmatic.co.za/api/v2'
const errors = () => [{ message: copy('shared.errors.defaultApi') }]
const defaultOpts = { redirectOnError: true }

const createAuthorizationHeader = tokenString => {
  let set = { Accept: 'application/json' }
  if (tokenString) {
    set['Authorization'] = `Bearer ${tokenString}`
  }
  return set
}

/**
 * Refreshes the token if a new token is returned in the request header
 */
const refreshTokenFromHeader = header => {
  if (!header) {
    return
  }

  const token = header.replace('Bearer ', '')
  return window.__STORE__.dispatch(selectToken({ ...decodeTokenString(token), token: token }))
}

const shouldFetchNewToken = ({ status }) => {
  return status === 412
}

const handleHttpError = ({ res, opts, reject, err }) => {
  if (opts.redirectOnError && [401, 403].includes(err.status)) {
    push(`/${err.status}`)
  }

  if (isArray(res.body)) return reject({ errors: res.body, res, status: err.status })
  else if (!res.body) return reject({ errors: errors(), res, status: err.status })
  else return reject({ errors: [{}], res, status: err.status })
}

const handleHttpResponse = ({ err, opts, res, startedAt, reject, resolve }) => {
  if (err) {
    return handleHttpError({
      opts,
      res,
      reject,
      err
    })
  } else {
    refreshTokenFromHeader(res.header.authorization)
    return resolve(createResponse({ res, opts, startedAt }))
  }
}

const createResponse = ({ res, opts, startedAt }) => {
  return {
    res,
    startedAt,
    finishedAt: new Date().getTime(),
    body: opts.transformObjectKeys ? toCamelCaseKeys(res.body) : res.body,
    data: opts.transformObjectKeys ? toCamelCaseKeys(get(res, 'body.data')) : get(res, 'body.data')
  }
}

const createRequest = ({ method, url, token, props, opts, query }) => {
  const agent = superagent[method](`${baseURL}/${url}`).set(createAuthorizationHeader(token))

  if (method === 'get') {
    agent['query'](props)
  } else {
    agent['send'](opts.transformObjectKeys ? toSnakeCaseKeys(props) : props)
    agent['query'](query)
  }

  return agent
}

let api = {}

each(['get', 'post', 'put', 'delete', 'patch'], method => {
  api[method] = (url, props = {}, opts = {}, query = {}) => {
    opts = { ...defaultOpts, ...opts }

    pubsub.emit('ajax/start', method)
    const startedAt = new Date().getTime()

    if (opts.progress) {
      pubsub.emit('progress/start', 'large', opts.progress)
    }

    return new Promise((resolve, reject) => {
      return getToken(token => {
        const request = createRequest({
          method,
          url,
          token,
          props,
          opts,
          query
        })

        request.end((err, res) => {
          pubsub.emit('ajax/end', method)

          if (opts.progress) {
            pubsub.emit('progress/end', 'large')
          }

          const responseOpts = {
            opts,
            startedAt,
            reject,
            resolve
          }

          if (err && shouldFetchNewToken(err)) {
            return getTokenForOrganisation(res.body[0].organisation_id).then(res => {
              return resolve(api[method](url, props, opts, query))
            })
          } else {
            return handleHttpResponse({ ...responseOpts, res, err })
          }
        })
      })
    })
  }
})

export default api

export const handleError = ({ errors }) => {
  if (!errors || !errors.length) return
  pubsub.emit('toast', {
    type: 'danger',
    title: copy('shared.errors.error'),
    message: errors.map(e => `- ${e.message}`),
    duration: 10000
  })
}

export const handleSuccess = message => {
  if (!message) return
  pubsub.emit('toast', {
    type: 'success',
    title: copy('shared.success.success'),
    message,
    duration: 10000
  })
}

export const createUrl = url => {
  return `${baseURL}/${url}`
}
