import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ValidationList, Button, Avt } from 'shared/components'
import Form, { isRequired } from 'shared/higher-order-components/Form'
import * as actions from '../../state/wizard/WizardActions'

let Address = ({ fields, messages, submit, copy }) => {
  return (
    <div className="address-container form form-widget text-left">
      <div className="form_heading">Address</div>
      <div className="form_sub-heading">{copy.get('address.desc')}</div>
      <ValidationList messages={messages} />

      <div className="row">
        <div className="col-12">
          <Avt {...fields.address} />
        </div>
      </div>

      <Button
        onClick={submit}
        className="form_cancel"
        style={{ float: 'right', width: '50%' }}
        size="large"
        type="primary"
      >
        Next
      </Button>
    </div>
  )
}

Address.propTypes = {
  fields: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

Address = Form({
  fields: ({ copy }) => ({
    address: [isRequired(copy.get('address.address'))]
  }),
  mapPropsToFields: props => props.data || false,
  submit: ({ props, fields }) => {
    const toAddress = item => ({
      address: {
        ...item
      }
    })
    props.storeWidgetData(toAddress(fields.address), false, 'options')
  }
})(Address)

const mapStateToProps = ({ account: { wizard } }) => ({ ...wizard })
const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Address)
