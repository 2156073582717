import React from 'react'
import PropTypes from 'prop-types'
import { NavItem } from 'shared/components'

const propTypes = {
  links: PropTypes.array.isRequired
}

const PageNav = ({ links }) => {
  return (
    <span>
      {links.map((link, i) => (
        <NavItem
          key={i}
          to={link.to}
          position={link.position}
          visible={link.visible}
          clickable={link.clickable}
        >
          {link.text}
        </NavItem>
      ))}
    </span>
  )
}

PageNav.propTypes = propTypes

export { PageNav }
