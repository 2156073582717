import { combineReducers } from 'redux'
import { cloneDeep } from 'lodash'
import * as create from './reducerFactories'

const stashInitialState = {}

const stash = (state = stashInitialState, { type, stashKey, stashItem, maxTransitions }) => {
  switch (type) {
    case 'ITEM_STASHED': {
      const newState = cloneDeep(state)
      newState[stashKey] = { stashItem, maxTransitions, transitions: 0 }
      return newState
    }

    case 'STASH_ITEM_REMOVED': {
      const newState = cloneDeep(state)
      delete newState[stashKey]
      return newState
    }

    case 'ROUTE_CHANGED': {
      const newState = cloneDeep(state)
      Object.keys(newState).forEach(k => {
        if (++newState[k].transitions >= (newState[k].maxTransitions || 2)) delete newState[k]
      })
      return newState
    }

    case 'STASH_CLEARED':
      return stashInitialState

    default:
      return state
  }
}

export default combineReducers({
  list: create.list({ path: 'shared' }),
  form: create.form({ path: 'shared' }),
  item: create.item({ path: 'shared' }),
  items: create.items({ path: 'shared' }),
  stash
})
