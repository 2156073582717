/*
	Panel to right of map displaying dropped points and directions
	Don't manage state in here
*/
import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { formatLat, formatLng } from 'shared/lib/geo'
import { Button } from 'shared/components'
import MapContextMenu from '../MapContextMenu'
import MapDirectionsMenuItem from './MapDirectionsMenuItem'

class MapDirectionsPanel extends Component {

	static propTypes = {
		points: PropTypes.array.isRequired,
		close: PropTypes.func.isRequired,
		removePoint: PropTypes.func.isRequired,
		panelRef: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string, PropTypes.object]),
		addPoint: PropTypes.func,
		contextMenuPosition: PropTypes.array
	}


	close = () => {
		this.props.close()
	}


	render() {

		const { points, removePoint } = this.props
		return (
			<div style={{
				overflowY: 'auto',
				height: '100%',
				position: 'relative',
				width: 320
			}}>
				<div style={{
					position: 'absolute',
					padding: 10
				}}>
					{points.length < 2 ? <div className="map_panel_waypoints-wrapper">
						<div className="title">Drop Points</div>
						<p>Drop another point to calculate directions</p>
					</div> : null}
					{points.length >= 1 ? <div className="map_panel_waypoints-wrapper">
						<div className="title">Waypoints</div>
						<div>
							{points.map((point, index) => (
								<div key={index} className="waypoint-num-container">
									<span className="waypoint-num">{String.fromCharCode(65 + index)}</span>
									<span className="lat">{formatLat(point[1])}</span>
									<span className="lng">{formatLng(point[0])}</span>
									<span onClick={() => removePoint(point)}><i className="i-close" /></span>
								</div>
							))}
						</div>
					</div> : null}
					{points.length >= 1 ? <div className="map_panel_directions-wrapper">
						<div className="title">Directions</div>
						<div ref={this.props.panelRef} />
					</div> : null}
					<div className="map_panel_close" onClick={this.close}>
						<i className="i-close" />
					</div>
				</div>
				<MapContextMenu {...this.props} position={this.props.contextMenuPosition} />
			</div>
		)
	}
}

export default MapDirectionsPanel