import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Page, Spinner } from 'shared/components'
import { fetchPortal } from '../../state/dashboard/dashboardActions'

const Dashboard = ({ orgId, fetchPortal, portalLoaded }) => {
  useEffect(() => {
    fetchPortal(orgId)
  }, [])

  return (
    <Page title="Dashboard">
      {!portalLoaded ? (
        <Spinner size={50} center={50} />
      ) : (
        <span className="listTable">
          {/* <div className="listTable_top-bar">Hi there from Dashboard</div> */}
        </span>
      )}
    </Page>
  )
}

Dashboard.propTypes = {
  fetchPortal: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  portalLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = ({ app: { portal } }, { match }) => ({
  ...match.params,
  ...portal
})

export default connect(
  mapStateToProps,
  { fetchPortal }
)(Dashboard)
