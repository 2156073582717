import React from 'react'
import logo from 'shared/assets/TrackmaticLogo.png'

const Footer = () => {
  return (
    /*
    <footer
      style={{
        position: 'fixed',
        bottom: '0',
        padding: '10px',
        background: 'rgb(223, 226, 229)',
        width: '100%',
        fontSize: '10px'
      }}
    >
      <span>Copyright Trackmatic (Pty) Ltd 2019. All Rights Reserved. Version 3.0</span>
      <span className="float-right">
        Powered by{' '}
        <img
          style={{ marginLeft: '5px', marginTop: '-5px' }}
          className="float-right"
          src={logo}
          alt="Trackmatic"
        />
      </span>
    </footer>*/
    <div></div>
  )
}

export default Footer
