/* eslint react/no-multi-comp: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'shared/components'
import { isNaN } from 'lodash'

const propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  iconRight: PropTypes.string,
  iconLeft: PropTypes.string,
  type: PropTypes.string,
  styleType: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  incHours: PropTypes.number,
  disabled: PropTypes.bool,
  incMinutes: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reff: PropTypes.func,
  onKeyDown: PropTypes.func,
  mode: PropTypes.string
}

const BACKSPACE = 8
const TAB = 9
const SPACE = 32
const LEFT = 37
const UP = 38
const RIGHT = 39
const DOWN = 40
const DELETE = 46
const C_KEY = 67
const V_KEY = 86

/** TODO
 * regex for valid timespan format
 * mode to introduce days, months, years
 * extract logic that is independent of this Component
 *
 */

class TimeSpanInput extends React.Component {
  static defaultProps = {
    mode: 'hours',
    incHours: 1,
    incMinutes: 1
  }

  constructor(props) {
    super(props)

    this.state = {
      mode: props.mode,
      units: props.mode,
      hours: 0,
      minutes: 0,
      seconds: 0,
      overwrite: true,
      highlight: true,
      initialized: false
    }
  }

  componentDidMount = () => {
    const { value } = this.props
    value && this.initValue(value)
  }

  componentWillReceiveProps = nextProps => {
    const { value } = nextProps
    value && this.initValue(value)
  }

  componentDidUpdate(prevProps, prevState) {
    this.isEditingHours() ? this.input.setSelectionRange(0, 2) : this.input.setSelectionRange(3, 5)

    if (prevState.hours !== this.state.hours || prevState.minutes !== this.state.minutes) {
      this.props.onChange(this.getFullTimeSpan())
    }
  }

  componentWillUnmount() {
    this.setState({
      hours: 0,
      minutes: 0,
      seconds: 0,
      overwrite: true,
      highlight: true,
      initialized: false
    })
  }

  initValue = value => {
    let values = value.split(':') // this needs to be more robust. switch out for regex test
    this.setState({
      initialized: true,
      hours: this.getDefaultValue(values[0], 'hours'),
      minutes: this.getDefaultValue(values[1], 'minutes'),
      seconds: this.getDefaultValue(values[2], 'seconds')
    })
  }

  getDefaultValue = (val, type) => {
    const num = +val

    if (type === 'hours' && num > 23) return 0
    if ((type === 'minutes' || type === 'seconds') && num > 59) return 0

    return isNaN(num) ? 0 : num
  }

  getCurrentUnitValue = () => {
    const { units } = this.state
    return this.state[units]
  }

  setCurrentUnitValue = (value, isIncrement = false) => {
    const editingHours = this.isEditingHours()

    const isValid = editingHours ? this.isValidHour : this.isValidMinute

    // check if the current input is valid
    if (isValid(value)) {
      const unit = this.getCurrentUnit()
      let newState = {
        [unit]: value,
        overwrite: false
      }
      // if any further input would not be valid then skip and select the next units
      if (!isIncrement && !isValid(value * 10) && editingHours) {
        newState.units = 'minutes' // todo
        newState.overwrite = true
        newState.highlight = true
      }

      this.setState(prevState => newState)
    }
  }

  getIncrementValue = () => {
    // increment to next multiple of specified valud
    const { incHours, incMinutes } = this.props
    const value = this.getCurrentUnitValue()
    let inc = this.isEditingHours() ? incHours : incMinutes
    let mod = value % inc
    return mod === 0 ? inc : inc - mod
  }

  getDecrementValue = () => {
    // decrement to next multiple of specified valud
    const { incHours, incMinutes } = this.props
    const value = this.getCurrentUnitValue()
    let dec = this.isEditingHours() ? incHours : incMinutes
    return value % dec || dec
  }

  getCurrentUnit = () => this.state.units

  isEditingHours = () => this.getCurrentUnit() === 'hours'

  isEditingMinutes = () => this.getCurrentUnit() === 'minutes'

  inc = () => {
    let val = this.getCurrentUnitValue() + this.getIncrementValue()
    if (this.isEditingHours() && !this.isValidHour(val)) val = 23
    else if (this.isEditingMinutes() && !this.isValidMinute(val)) val = 59
    this.setCurrentUnitValue(val, true)
  }

  dec = () => {
    let val = this.getCurrentUnitValue() - this.getDecrementValue()
    this.setCurrentUnitValue(val > 0 ? val : 0, true)
  }

  onKeyDown = e => {
    const { ctrlKey, keyCode, shiftKey } = e
    let preventDefault = true

    switch (keyCode) {
      case BACKSPACE:
      case DELETE:
        this.backspaceUnit()
        break
      case LEFT:
      case RIGHT:
        this.isEditingHours() ? this.setUnits('minutes') : this.setUnits('hours')
        break
      case UP:
        this.inc()
        break
      case DOWN:
        this.dec()
        break
      case TAB:
      case SPACE:
        this.next(shiftKey)
        break
      case C_KEY:
      case V_KEY:
        preventDefault = !ctrlKey // copy or paste
        break
      default:
        const num = +e.key
        !isNaN(num) && this.onNumberTyped(num)
    }

    preventDefault && e.preventDefault()
  }

  onNumberTyped = num => {
    const { overwrite } = this.state

    const currVal = this.getCurrentUnitValue()
    let nextVal = currVal * 10 + num

    this.setCurrentUnitValue(overwrite ? num : nextVal)
  }

  isValidHour = num => {
    return num >= 0 && num <= 23
  }

  isValidMinute = num => {
    return num >= 0 && num <= 59
  }

  backspaceUnit = () => {
    const currentValue = this.getCurrentUnitValue()
    if (currentValue === 0 && this.isEditingMinutes())
      // todo make more robust
      this.setUnits('hours')
    else this.setCurrentUnitValue(Math.floor(currentValue / 10))
  }

  next = (backwards = false) => {
    if (this.isEditingHours()) backwards ? this.input.blur() : this.setUnits('minutes')
    else backwards ? this.setUnits('hours') : this.input.blur()
  }

  onClick = e => {
    const pos = e.target.selectionStart
    this.setUnits(pos < 3 ? 'hours' : 'minutes', true)
  }

  onPaste = e => {
    // todo
  }

  setUnits = (units, force = false) => {
    if (force || this.state.units !== units) {
      this.setState({ units, overwrite: true, highlight: true })
    }
  }

  getTimeSpan = () => {
    const { hours, minutes } = this.state
    return `${this.insertLeadingZero(hours)}:${this.insertLeadingZero(minutes)}`
  }

  getFullTimeSpan = () => {
    const { hours, minutes } = this.state
    if (hours === 0 && minutes === 0) {
      this.setUnits('hours')
      return
    }
    return `${this.getTimeSpan()}:${this.insertLeadingZero(0)}`
  }

  insertLeadingZero = num => {
    return +num <= 9 ? `${0}${num}` : num
  }

  onFocus = e => {
    this.setState({
      overwrite: true,
      highlight: true,
      units: 'hours'
    })
  }

  render() {
    const { value, incHours, incMinutes, mode, onChange, ...inputProps } = this.props
    return (
      <Input
        reff={el => (this.input = el)}
        {...inputProps}
        placeholder="hh:mm"
        onFocus={this.onFocus}
        onChange={() => onChange(this.getFullTimeSpan())}
        value={value ? this.getTimeSpan() : ''}
        onMouseDown={this.onMouseDown}
        onKeyDown={this.onKeyDown}
        onClick={this.onClick}
        onPaste={this.onPaste}
      />
    )
  }
}

TimeSpanInput.propTypes = propTypes

export { TimeSpanInput }
