/* eslint react/no-multi-comp: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import colors from 'shared/static/colors'
import { isNil, isString } from 'lodash'
import ReactTooltip from 'react-tooltip'
import classnames from 'classnames'
import st from './Input.module.scss'

const testValue = (regex, value) => {
  if (!isNil(regex)) {
    const r = isString(regex) ? new RegExp(regex) : regex
    return r.test(value)
  }
  return true
}

const testKey = (regex, value, keyCode, which) => {
  if (!isNil(regex)) {
    const key = keyCode || which
    const keyValue = String.fromCharCode(key)
    return testValue(regex, value + keyValue)
  }
  return true
}

const Input = props => {
  const {
    className,
    inputClassName,
    label,
    name,
    invalid,
    iconLeft,
    iconRight,
    errorIcon,
    errorDataTip,
    disabled,
    style = {},
    styleType = 'line',
    type = 'text',
    reff,
    regex,
    toUpper,
    hint,
    iconClicked = () => {},
    onKeyPress = () => {},
    onChange = () => {},
    onKeyDown = () => {},
    onEnter = () => {},
    onPaste = () => {},
    ...inputProps
  } = props

  if (toUpper) style.textTransform = 'uppercase'

  if (disabled) style.color = colors.textLight

  const renderInput = () => (
    <>
      <input
        {...inputProps}
        ref={el => {
          if (reff) reff(el)
        }}
        type={type}
        disabled={disabled}
        style={style}
        id={`fe-${name}`}
        onChange={e => onChange(e.target.value)}
        onKeyPress={e => {
          onKeyPress(e)
          if (!testKey(regex, e.target.value, e.keyCode, e.which)) {
            e.preventDefault()
          }
        }}
        onPaste={e => {
          onPaste()
          if (!testValue(regex, e.clipboardData.getData('Text'))) {
            e.preventDefault()
          }
        }}
        onKeyDown={e => {
          onKeyDown(e)
          if (e.keyCode === 13) onEnter(e.target.value)
        }}
        className={classnames(st.input, st[`input_${styleType}`], inputClassName, {
          [`${st['is-invalid']}`]: invalid
        })}
      />
      {hint ? <small>{hint}</small> : ''}
    </>
  )

  const iconOrNothing = icon => (icon ? <i onClick={iconClicked} className={`i-${icon}`} /> : null)

  const errorIconRight = icon =>
    icon ? (
      <div>
        <i className={classnames(`i-${icon}`, st['input_error-icon'])} data-tip={errorDataTip} />
        <ReactTooltip />
      </div>
    ) : null

  return (
    <div
      className={classnames('form_element form_element-input', className, {
        [`${st['is-invalid']}`]: invalid
      })}
    >
      {label ? (
        <label
          className="form_label"
          style={{ color: disabled ? colors.textLight : undefined }}
          htmlFor={`fe-${name}`}
        >
          {label}
        </label>
      ) : null}
      {iconLeft || iconRight ? (
        <div
          className={classnames(st['input_icon-wrap'], st[`input_icon-wrap-${styleType}`], {
            [`${st['input_icon-left']}`]: !!iconLeft,
            [`${st['input_icon-right']}`]: !!iconRight
          })}
        >
          {iconOrNothing(iconLeft)}
          {renderInput()}
          {iconOrNothing(iconRight)}
          {errorIconRight(errorIcon)}
        </div>
      ) : (
        renderInput()
      )}
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  hint: PropTypes.bool,
  iconRight: PropTypes.string,
  iconLeft: PropTypes.string,
  errorIcon: PropTypes.string,
  errorDataTip: PropTypes.string,
  type: PropTypes.string,
  styleType: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reff: PropTypes.func,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  regex: PropTypes.oneOfType([PropTypes.instanceOf(RegExp), PropTypes.string]),
  toUpper: PropTypes.bool
}

export { Input }
