import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Input, InputFile, ValidationList, Button } from 'shared/components'
import Form from 'shared/higher-order-components/Form'
import { Link } from 'react-router-dom'
import storage from 'shared/lib/storage'
import * as actions from '../../state/wizard/WizardActions'

let DisplayOptions = ({ fields, messages, copy, storeWidgetData, uploadDocument }) => {
  const [logourl, updateUrl] = useState('')

  const performUpload = async data => {
    const entityId = storage.local.getObj('user', 'master_entity.id')
    const { id } = await uploadDocument(entityId, data)
    updateUrl(id)
  }

  const submit = () => {
    const data = {
      logo_url: logourl,
      primary_color: fields.primary_color.value,
      secondary_color: fields.secondary_color.value
    }

    storeWidgetData(data, false, 'users')
  }

  return (
    <div className="form form-widget text-left">
      <div className="form_heading">Setup your display options</div>
      <div className="form_sub-heading">{copy.get('display.desc')}</div>

      <ValidationList messages={messages} />
      <div className="row">
        <div className="col-6">
          <Input
            iconLeft="format_color_fill"
            type="color"
            {...fields.primary_color}
            label={copy.get('display.pColor')}
          />
        </div>
        <div className="col-6">
          <InputFile label onFileSelect={({ data }) => performUpload(data)} />
          {/* <Input
            iconLeft="format_color_fill"
            {...fields.secondary_color}
            type="color"
            label={copy.get('display.sColor')}
          /> */}
        </div>
      </div>

      <div className="row">
        {/* <div className="col-6">
          <InputFile label onFileSelect={({ data }) => performUpload(data)} />
        </div> */}
        <div className="col-6 float-right">{copy.get('display.note')}</div>
      </div>

      <Link to="/setup/users">
        <Button className="form_cancel" size="large" type="secondary">
          Skip
        </Button>
      </Link>

      <Button
        onClick={submit}
        className="form_cancel"
        style={{ float: 'right', width: '50%' }}
        size="large"
        type="primary"
      >
        Next
      </Button>
    </div>
  )
}

DisplayOptions.propTypes = {
  fields: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

DisplayOptions = Form({
  fields: () => ({
    primary_color: [],
    secondary_color: [],
    logo_url: []
  }),
  mapPropsToFields: props => (props.data ? props.data : false)
})(DisplayOptions)

const mapStateToProps = ({ account: { wizard }, app: { user } }) => ({
  ...wizard,
  user: user.master_entity
})
const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayOptions)
