import api, { handleError } from 'shared/api/index'

export const fetchMeta = () => dispatch => {
  api.get('organisations/static').then(({ body }) => {
    dispatch({
      type: 'app/FETCHED_META',
      meta: body
    })
  }, handleError)
}
