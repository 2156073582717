import React from 'react'
import { PropTypes } from 'prop-types'
import cx from 'classnames'
import st from './Box.module.css'

export const Box = ({ children, mode, customClass = '', style }) => (
  <div className={cx(st.box, st[`box-${mode}`], customClass)} style={style}>
    {children}
  </div>
)

Box.propTypes = {
  mode: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string,
  style: PropTypes.object
}

export const BoxPanel = ({ children, stretch = '', style }) => {
  return (
    <div
      className={`
        ${st['box-panel']}
        ${stretch && st['box-panel-stretch']}
        `}
      style={style}
    >
      {children}
    </div>
  )
}

BoxPanel.propTypes = {
  children: PropTypes.node,
  stretch: PropTypes.bool,
  style: PropTypes.object
}

export const BoxContent = ({
  children,
  style,
  innerStyle,
  innerClass,
  marginStyle,
  outerClass = ''
}) => (
  <div className={st['box-content']} style={style}>
    <div className={cx(st['box-content-margin'], outerClass)} style={marginStyle}>
      <div className={cx(st['box-content-inner'], innerClass)} style={innerStyle}>
        {children}
      </div>
    </div>
  </div>
)

BoxContent.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  innerStyle: PropTypes.object,
  innerClass: PropTypes.string,
  outerClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  marginStyle: PropTypes.object
}

export const BoxHeader = ({ children }) => (
  <div className={st['box-content-heading']}>{children}</div>
)

BoxHeader.propTypes = {
  children: PropTypes.node.isRequired
}
