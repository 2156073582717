import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ListPage from 'shared/higher-order-components/ListPage'
import Intl from 'shared/higher-order-components/Intl'

import { Page, Table, Input, Button, Select } from 'shared/components'

class TransactionsList extends PureComponent {
  render() {
    const { copy } = this.props
    const headers = [
      { width: 2 },
      { text: copy.get('item.reference') },
      { text: copy.get('item.status') },
      { text: copy.get('item.transactionDate') },
      { text: copy.get('item.transactionType') }
    ]

    const rows = [
      [
        {
          className: 'is-single-icon',
          content: <i className="i-edit" />
        },
        { text: 'POHGAUDC00000003562' },
        { text: 'Pending' },
        { text: '03/05/2018 15:22' },
        { text: 'Drop Off' }
      ]
    ]

    const renderSearchOpt = () => {
      return (
        <>
          <Input
            reff={el => (this.$termInput = el)}
            styleType="box"
            name="filter"
            placeholder={copy.get('transaction.term')}
            defaultValue=""
            style={{ width: 180 }}
          />

          <Select
            reff={el => (this.$unassigned = el)}
            styleType="box"
            placeholder={copy.get('transaction.filter.unassigned')}
            options={[
              {
                value: 'true',
                label: copy.get('transaction.filter.unassigned')
              },
              {
                value: 'false',
                label: copy.get('transaction.filter.assignedall')
              }
            ]}
            defaultValue="true"
            style={{ width: 150 }}
          />

          <Select
            reff={el => (this.$dateTypeSelect = el)}
            styleType="box"
            placeholder={copy.get('transaction.filter.datetype')}
            options={[
              {
                value: 'false',
                label: copy.get('transaction.filter.expecteddate')
              },
              {
                value: 'true',
                label: copy.get('transaction.filter.transactiondate')
              }
            ]}
            defaultValue={false}
            style={{ width: 170 }}
          />

          <Button
            className="listTable_top-bar-filter"
            onClick={() => console.info('clicked Filter')}
            style={{ marginRight: '0px', width: 140 }}
          >
            {copy.get('transaction.search')}
          </Button>
        </>
      )
    }
    const fetching = () => true

    return (
      <Page title={copy.get('item.transationCount')}>
        <span className="listTable">
          <div className="listTable_top-bar">{renderSearchOpt()}</div>
          <Table headers={headers} rows={rows} isLoading={fetching()} />
        </span>
      </Page>
    )
  }
}

TransactionsList.propTypes = {
  copy: PropTypes.object.isRequired
}

TransactionsList = ListPage()(TransactionsList)

const mapDispatchToProps = null

TransactionsList = connect(
  null,
  mapDispatchToProps
)(TransactionsList)

export default Intl({
  get: 'portal.bookings'
})(TransactionsList)
