import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Button from 'shared/components'

class SplitButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.array,
    align: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      isVisible: false
    }
  }

  handleClick = () => {
    this.setState(state => ({
      isVisible: !state.isVisible
    }))
  }

  close = () => {
    this.setState({
      isVisible: false
    })
  }

  renderSplitButtonItems() {
    return this.props.children
      ? React.Children.map(this.props.children, child => {
          return React.cloneElement(child, { close: this.close })
        })
      : null
  }

  render() {
    const { label, align } = this.props

    return (
      <div className={classnames('splitbutton', `splitbutton-${align}`)}>
        <div className="splitbutton-wrapper">
          <div className="splitbutton-container-button">
            <Button {...this.props} onClick={this.handleClick} iconRight="chevron-down">
              {label}
            </Button>
          </div>
          <div
            className={classnames(
              'splitbutton-container-items',
              `splitbutton-container-items-${align}`
            )}
          >
            {this.state.isVisible ? this.renderSplitButtonItems() : null}
          </div>
        </div>
      </div>
    )
  }
}

export { SplitButton }
