import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ListPage from 'shared/higher-order-components/ListPage'
import Intl from 'shared/higher-order-components/Intl'
import { Page, Table, Button, WarningDialog } from 'shared/components'
import * as actions from '../../../state/customers/contacts/contactActions'
import Nav from '../Nav'

class CustomerContactsList extends PureComponent {
  componentDidMount() {
    const { orgId, customerId, getCustomerContactsByEntityAndId } = this.props
    getCustomerContactsByEntityAndId(orgId, customerId)
  }

  get perPage() {
    return 25
  }

  render() {
    let rows = []
    const {
      copy,
      fetching,
      listItems,
      orgId,
      customerId,
      deleteCustomerContactByEntity
    } = this.props
    const headers = [
      { text: copy.CustomerContactsList('id') },
      { text: copy.CustomerContactsList('first_name') },
      { text: copy.CustomerContactsList('last_name') },
      { text: copy.CustomerContactsList('email') },
      { text: copy.CustomerContactsList('contact_no') },
      { text: copy.CustomerContactsList('designation') },
      { width: 2 },
      { width: 2 }
    ]

    const contactList = listItems[0]
    if (contactList) {
      rows = Object.values(contactList).map(contact => [
        { text: contact.data.id },
        { text: contact.data.first_name },
        { text: contact.data.last_name },
        { text: contact.data.email },
        { text: contact.data.contact_no },
        { text: contact.data.designation },
        {
          className: 'is-single-icon',
          content: (
            <WarningDialog
              message={copy.CustomerContactsList('deleteWarning')}
              onConfirm={() => deleteCustomerContactByEntity(orgId, customerId, contact.data.id)}
              modalTrigger={<i className="i-delete" />}
            />
          )
        },
        {
          className: 'is-single-icon',
          content: (
            <Link to={`/portal/${orgId}/customers/${customerId}/contacts/edit/${contact.data.id}`}>
              <i className="i-edit" />
            </Link>
          )
        }
      ])
    }

    return (
      <Page title="CustomerContacts" navContent={<Nav {...this.props} />}>
        <span className="listTable">
          <div className="listTable_top-bar">
            <Link to={`/portal/${orgId}/customers/${customerId}/contacts/create`}>
              <Button className="listTable_top-bar-create" iconLeft="plus">
                {copy.CustomerContactsList('addNewContact')}
              </Button>
            </Link>
          </div>
          <Table headers={headers} rows={rows} isLoading={fetching} />
        </span>
      </Page>
    )
  }
}

CustomerContactsList.propTypes = {
  copy: PropTypes.object.isRequired
}

CustomerContactsList = ListPage()(CustomerContactsList)

const mapStateToProps = ({ shared }, { params, location, match }) => ({
  query: location.query,
  ...params,
  ...shared.list,
  ...match.params
})

const mapDispatchToProps = actions

CustomerContactsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContactsList)

export default Intl({
  CustomerContactsList: 'portal.customers.contacts.list',
  search: 'portal.customers.search'
})(CustomerContactsList)
