import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchPortalData } from 'shared/state/actions'
import cx from 'classnames'
import { throttle } from 'lodash'
import { Spinner } from 'shared/components'
import { nav } from 'shared/components/Nav/Nav.module.scss'
import st from './Page.module.scss'

class Page extends Component {
  constructor(props) {
    super(props)
    this.updateLayoutStyles = this.updateLayoutStyles.bind(this)
  }

  componentDidMount() {
    const { fetchPortalData } = this.props
    fetchPortalData()
    this.updateLayoutStyles()
    this.updateLayoutStyles = throttle(this.updateLayoutStyles, 50)   
    window.addEventListener('resize', this.updateLayoutStyles)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateLayoutStyles)
  }

  updateLayoutStyles() {
    const clientHeight = window.innerHeight
    const navHeight = (document.querySelector(`.${nav}`) || {}).offsetHeight || 0
    const pageNavHeight = this.$pageNav.offsetHeight
    this.$pageNav.style.top = `${navHeight}px`
    this.$content.style.marginTop = `${navHeight + pageNavHeight}px`
    this.$content.style.height = `${clientHeight - navHeight - pageNavHeight}px`
  }

  render() {
    const { className, title, navContent, loading, children, portal_settings } = this.props

    return (
      <div className={st.page}>
        <div
          style={{ background: portal_settings && portal_settings.primary_color }}
          className={st.page_nav}
          ref={el => (this.$pageNav = el)}
        >
          {navContent || <div className={cx(st['page_nav-item'], st['is-active'])}>{title}</div>}
        </div>
        <div className={cx(st.page_content, className)} ref={el => (this.$content = el)}>
          {loading ? <Spinner size={64} center={64} /> : children}
        </div>
      </div>
    )
  }
}

Page.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  navContent: PropTypes.node,
  loading: PropTypes.bool,
  children: PropTypes.node,
  portal_settings: PropTypes.object.isRequired
}

const mapStateToProps = ({ app: { portal } }) => ({
  ...portal
})

Page = connect(
  mapStateToProps,
  { fetchPortalData }
)(Page)

export { Page }
