/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint react/no-multi-comp: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import st from './Button.module.scss'

const propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseOver: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconSimple: PropTypes.bool,
  progress: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disableClickOnProgress: PropTypes.bool,
  kind: PropTypes.any
}

const IconOrNothing = ({ icon }) => (icon ? <i className={`i-${icon}`} /> : null)

IconOrNothing.propTypes = {
  icon: PropTypes.string
}

const Button = ({
  className,
  style,
  type,
  size,
  kind,
  onClick,
  iconLeft,
  disabled,
  iconRight,
  iconSimple,
  progress,
  disableClickOnProgress = true,
  children,
  title,
  onMouseDown,
  onMouseUp,
  onMouseOut,
  onMouseOver
}) => (
  // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    title={title}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
    onMouseOut={onMouseOut}
    onMouseOver={onMouseOver}
    onClick={() => {
      if (disableClickOnProgress && progress) return
      if (!disabled && onClick) onClick()
    }}
    className={classnames(className, st.button, {
      [`${st[`button_${kind}`]}`]: kind,
      [`${st['button_icon-left']}`]: iconLeft,
      [`${st['button_icon-right']}`]: iconRight,
      [`${st['button_icon-block']}`]: !iconSimple && (iconLeft || iconRight),
      [`${st[`button_${type}`]}`]: type,
      [`${st['button_default-type']}`]: !type && !disabled,
      [`${st[`button_${size}`]}`]: size,
      [`${st.button_disabled}`]: disabled,
      [`${st['button_has-progress']}`]: progress
    })}
    style={style}
  >
    <div className={classnames(st.button_progress, { [`${st['is-active']}`]: progress })} />
    <IconOrNothing icon={iconLeft} />
    <span className={st.button_content}>{children}</span>
    <IconOrNothing icon={iconRight} />
  </div>
)

Button.propTypes = propTypes

export { Button }
