import React from 'react'
import PropTypes from 'prop-types'
import Intl from 'shared/higher-order-components/Intl'
import cx from 'classnames'
import st from './ValidationList.module.scss'

const propTypes = {
  messages: PropTypes.array,
  copy: PropTypes.object.isRequired,
  icon: PropTypes.string,
  customIconClass: PropTypes.string
}

let ValidationList = ({ messages, copy, icon, customIconClass = '' }) =>
  messages && messages.length > 0 ? (
    <div className={st.validationList}>
      {icon && customIconClass && (
        <span className={cx({ [`${st['validationList-round']}`]: !!customIconClass })}>
          <i className={icon} />
        </span>
      )}
      <div className={st.validationList_heading}>{copy.shared('validations.title')}</div>
      <ul className={st.validationList_items}>
        {messages.map((message, i) => (
          <li key={`message-${i}`}>-{message}</li>
        ))}
      </ul>
    </div>
  ) : null

ValidationList.propTypes = propTypes

ValidationList = Intl()(ValidationList)

export { ValidationList }
