/* eslint-disable camelcase */
import api, { handleError } from 'shared/api/index'
import { push } from 'connected-react-router'

const path = 'link_accounts/suppliers/credentials_request'

export const requestAccess = token => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.get(`${path}?tokenId=${token}`).then(
    ({ data }) => {
      dispatch({
        type: 'shared/form/SUBMITTED'
      })

      if (!data) {
        dispatch(push('/no-memberships'))
      }

      if (data.user_id && data.status === 'Completed') {
        dispatch(push('/login'))
      }

      if (data.status === 'Activated' && !data.key_verified) {
        dispatch({
          type: 'account/credentials/REGISTER_FETCH_SUCCESS',
          data
        })
      }

      if (data.key_verified && data.status === 'Activated') {
        dispatch({
          type: 'account/credentials/REGISTER_FETCH_SUCCESS',
          data
        })
        dispatch(push('/credentials-complete'))
      }
    },
    ({ errors }) => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: errors
      })
    }
  )
}

export const requestKey = id => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  return api.put(`${path}/${id}/request_key`).then(
    data => {
      dispatch({
        type: 'shared/form/SUBMITTED'
      })
      return data
    },
    handleError
    // ({ errors }) => {
    //   dispatch({
    //     type: 'shared/form/SUBMITTED',
    //     messages: errors
    //   })
    // }
  )
}

export const verifyKey = (id, mobile) => dispatch => {
  dispatch({ type: 'shared/form/SUBMITTING' })
  api.get(`${path}/${id}/verify_key?key=${mobile}`).then(
    ({ data }) => {
      dispatch({
        type: 'account/credentials/REGISTER_FETCH_SUCCESS',
        data
      })
      dispatch(push('/credentials-complete'))
    },
    ({ errors }) => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: errors
      })
    }
  )
}

export const complete = (id, fields) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.put(`${path}/complete/${id}`, fields).then(
    () => {
      dispatch(push('/login'))
    },
    ({ errors }) => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: errors
      })
    }
  )
}
