import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchStoredUser, routeChanged } from 'shared/state/actions'
import { PageTitle } from 'shared/components'
import { fetchMeta } from '../state/actions'
import Toasts from '../components/Toasts'

const mapDispatchToProps = {
  fetchStoredUser,
  fetchMeta,
  routeChanged
}

const propTypes = {
  children: PropTypes.node.isRequired,
  fetchStoredUser: PropTypes.func.isRequired,
  routeChanged: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  fetchMeta: PropTypes.func.isRequired
}

class App extends Component {
  componentWillMount() {
    const { fetchStoredUser, fetchMeta } = this.props
    fetchStoredUser()
    fetchMeta()
  }

  componentWillReceiveProps({ location: { pathname: nextPath, state } }) {
    const {
      location: { pathname: prevPath },
      routeChanged
    } = this.props

    if (prevPath !== nextPath) {
      routeChanged({
        prevModule: prevPath.split('/')[1],
        nextModule: nextPath.split('/')[1],
        prevPath,
        nextPath,
        state
      })
    }
  }

  render() {
    const { children } = this.props
    return (
      <PageTitle>
        <Toasts />
        {children}
      </PageTitle>
    )
  }
}

App.propTypes = propTypes

const mapStateToProps = ({ router, app }) => ({
  ...router,
  ...app
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
