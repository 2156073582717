import { push, replace, goBack } from 'connected-react-router'
import { batchActions } from 'redux-batched-actions'
import { get } from 'lodash'
import { buildUrl } from 'shared/api/utils'
import { updateLocale } from 'shared/lib/intl'
import storage from 'shared/lib/storage'
import { storeToken, deleteToken } from 'shared/api/tokens'

export const updateHash = hash => (dispatch, getState) => {
  const currentUrl = getState().routing.locationBeforeTransitions
  dispatch(replace(`${currentUrl.pathname}${hash}`))
}

export const updateQuery = query => (dispatch, getState) => {
  const currentUrl = getState().router.location
  query = {
    ...currentUrl.query,
    ...query
  }
  dispatch(replace(buildUrl(currentUrl.pathname, query)))
  return query
}

export const fetchStoredUser = () => {
  const user = storage.local.getObj('user')
  const lang = get(user, 'preferences.regional_settings.language')
  if (lang) updateLocale(lang.split('-')[0])
  return { type: 'app/FETCHED_USER', user }
}

export const selectToken = (token, redirectPath) => dispatch => {
  storage.local.setObj('user', () => token)
  storeToken(token.token)
  dispatch(fetchStoredUser())
  if (redirectPath) {
    dispatch(push(redirectPath))
  }
}

export const storeAccessRequestData = (data, redirectPath) => dispatch => {
  storage.local.setObj('access_data', () => data)
  if (redirectPath) {
    dispatch(push(redirectPath))
  }
}

export const storePortalData = data => {
  const temp = storage.local.getObj('portal_data')
  const mod = { ...temp, ...data }
  storage.local.setObj('portal_data', () => mod)
}

export const fetchPortalData = () => dispatch => {
  const data = storage.local.getObj('portal_data')
  dispatch({
    type: 'app/FETCHED_PORTAL_CONFIG',
    portal: data
  })
}

export const logOut = () => dispatch => {
  deleteToken()
  storage.local.clear()
  dispatch(push('/login'))
}

export const tryStashItem = ({ stashKey, stashItem, maxTransitions }) => dispatch => {
  if (stashKey && stashItem) {
    dispatch({ type: 'ITEM_STASHED', stashKey, stashItem, maxTransitions })
    return true
  }
  return false
}

export const routeChanged = ({ prevModule, nextModule, prevPath, nextPath, state }) => dispatch => {
  if (prevModule !== nextModule) {
    dispatch(
      batchActions([
        { type: `${prevModule}/MODULE_UNMOUNT` },
        { type: `${nextModule}/MODULE_MOUNT` },
        { type: `${prevModule}/ROUTE_CHANGED` },
        { type: `${nextModule}/ROUTE_CHANGED` },
        { type: 'ROUTE_CHANGED' }
      ])
    )
  } else {
    dispatch(
      batchActions([
        { type: `${nextModule}/ROUTE_CHANGED` },
        { type: 'ROUTE_CHANGED', paths: { prevPath, nextPath }, state }
      ])
    )
  }

  if (state) {
    dispatch(tryStashItem(state))
  }
}

export const removeStashItem = stashKey => dispatch => {
  dispatch({ type: 'STASH_ITEM_REMOVED', stashKey })
}

export const back = goBack

export const setPageTitle = title => dispatch => {
  dispatch({
    type: 'SET_PAGE_TITLE',
    title
  })
}
