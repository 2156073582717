import api, { handleError } from 'shared/api/index'
import { storePortalData } from 'shared/state/actions'

const path = 'link/suppliers'

export const getStoredImage = (entityId, logoId) => async dispatch => {
  const { res } = await api.get(`blob-storage/${entityId}/${logoId}`)
  storePortalData({ logoUrl: res.req.url })
  dispatch({
    type: 'app/FETCHED_PORTAL_CONFIG',
    portal: { logoUrl: res.req.url }
  })
}

export const fetchPortal = entityId => dispatch => {
  api.get(`${path}/${entityId}/init`).then(({ body: { data } }) => {
    dispatch(getStoredImage(entityId, data.portal_settings.logo_url))
    storePortalData(data)
    dispatch({
      type: 'app/FETCHED_PORTAL_CONFIG',
      portal: data
    })
  }, handleError)
}
