import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import storage from 'shared/lib/storage'

// import App from 'app/containers/App'
import NotFound from 'app/components/NotFound'
import Dashboard from 'modules/portal/containers/dashboard/Dashboard'

// Account
import AccountModule from 'account/components/Module'
import AccountRegister from 'account/containers/Register'
import AccountCredentials from 'account/containers/CredentialsAuth'
import AccountCredentialsComplete from 'account/containers/CredentialsComplete'
import AccountComplete from 'account/containers/Complete'
import AccountLogin from 'account/containers/Login'
import AccountResetPassword from 'account/containers/ResetPassword'
import AccountSettings from 'modules/portal/containers/settings/Form'
import AccountInformation from 'modules/portal/containers/organisation/Form'
import AccountNoMemberships from 'account/components/NoMembership'
import AccountChangePassword from 'account/components/ChangePassword'

// import AccountSetup from 'account/containers/wizard/Setup'
import WizardModule from 'account/containers/wizard/WizardModule'
import Confirm from 'account/containers/wizard/Confirm'
import Address from 'account/containers/wizard/Address'
import DisplayOptions from 'account/containers/wizard/DisplayOptions'
import AccountManage from 'account/containers/wizard/AccountManage'
import AddUsers from 'account/containers/wizard/AddUsers'
import WizardFinish from 'account/containers/wizard/Finish'

// Portal Views
import SupplierPortalModule from 'modules/portal/components/Module'
import UsersList from 'modules/portal/containers/users/List'
import BookingsList from 'modules/portal/containers/bookings/List'
import TransactionsList from 'modules/portal/containers/transactions/List'
import CustomersList from 'modules/portal/containers/customers/List'
import FacilitiesList from 'modules/portal/containers/customers/facilities/List'
import ContactsList from 'modules/portal/containers/customers/contacts/List'
import CarriersList from 'modules/portal/containers/carriers/List'
import LinkedCustomerList from 'modules/portal/containers/users/LinkedCustomer'
import LinkedFacilitiesList from 'modules/portal/containers/users/LinkedFacilities'

// Portal Forms
import UserForm from 'modules/portal/containers/users/Form'
import BookingsForm from 'modules/portal/containers/bookings/Form'
import CustomersForm from 'modules/portal/containers/customers/Form'
import ContactsForm from 'modules/portal/containers/customers/contacts/Form'
import CarriersForm from 'modules/portal/containers/carriers/Form'
import VerifyDetails from 'account/containers/wizard/VerifyDetails'

const form = (Form, type, options) => props => <Form {...props} formType={type} {...options} />

const ModuleWrapper = ({ wrapper: Wrapper, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Wrapper>
        <Component {...props} />
      </Wrapper>
    )}
  />
)

const isUserAuth = path => {
  const isUser = ['user'].includes(...storage.local.getObj('user', 'roles'))
  const authPath = [`/portal/:orgId`, `/portal/:orgId/bookings`]
  return isUser && !authPath.includes(path)
}

const PrivateRoute = ({ wrapper: Wrapper, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      // eslint-disable-next-line no-nested-ternary
      storage.local.get('tokenString') ? (
        isUserAuth(rest.path) ? (
          <Wrapper>
            <AccountNoMemberships {...props} />
          </Wrapper>
        ) : (
          <Wrapper>
            <Component {...props} />
          </Wrapper>
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
)

const Routes = ({ history }) => (
  <Switch history={history}>
    <Redirect from="/" exact to="/login" />
    <Route exact path="/register/:token" component={AccountRegister} />
    <Route exact path="/credentials/:token" component={AccountCredentials} />
    <Route exact path="/complete" component={AccountComplete} />
    <Route exact path="/credentials-complete" component={AccountCredentialsComplete} />
    <Route exact path="/login" component={AccountLogin} />
    <Route exact path="/no-memberships" component={AccountNoMemberships} />

    <ModuleWrapper
      path="/password-reset"
      wrapper={AccountModule}
      component={AccountResetPassword}
    />
    <ModuleWrapper
      path="/password-reset/:token"
      wrapper={AccountModule}
      component={AccountResetPassword}
    />

    <Redirect from="/setup" exact to="/setup/confirm" />
    <ModuleWrapper path="/setup/confirm" wrapper={WizardModule} component={Confirm} />
    <ModuleWrapper path="/setup/details" wrapper={WizardModule} component={VerifyDetails} />
    <ModuleWrapper path="/setup/address" wrapper={WizardModule} component={Address} />
    <ModuleWrapper path="/setup/options" wrapper={WizardModule} component={DisplayOptions} />
    <ModuleWrapper path="/setup/manage" wrapper={WizardModule} component={AccountManage} />
    <ModuleWrapper path="/setup/users" wrapper={WizardModule} component={AddUsers} />
    <ModuleWrapper path="/setup/finish" wrapper={WizardModule} component={WizardFinish} />

    <PrivateRoute
      exact
      path="/portal/:orgId"
      wrapper={SupplierPortalModule}
      component={Dashboard}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/bookings"
      wrapper={SupplierPortalModule}
      component={BookingsList}
    />
    <PrivateRoute
      path="/portal/:orgId/bookings/create"
      wrapper={SupplierPortalModule}
      component={form(BookingsForm, 'create')}
    />

    <PrivateRoute
      path="/portal/:orgId/transactions"
      wrapper={SupplierPortalModule}
      component={TransactionsList}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/customers"
      wrapper={SupplierPortalModule}
      component={CustomersList}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/customers-facilities/:customerId"
      wrapper={SupplierPortalModule}
      component={FacilitiesList}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/customers/edit/:customerId"
      wrapper={SupplierPortalModule}
      component={form(CustomersForm, 'edit')}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/customers-contacts/:customerId/contacts"
      wrapper={SupplierPortalModule}
      component={ContactsList}
    />

    <PrivateRoute
      path="/portal/:orgId/customers/:customerId/contacts/create"
      wrapper={SupplierPortalModule}
      component={form(ContactsForm, 'create')}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/customers/:customerId/contacts/edit/:contactId"
      wrapper={SupplierPortalModule}
      component={form(ContactsForm, 'edit')}
    />
    <PrivateRoute
      exact
      path="/portal/:orgId/carriers"
      wrapper={SupplierPortalModule}
      component={CarriersList}
    />

    <PrivateRoute
      path="/portal/:orgId/carriers/create"
      wrapper={SupplierPortalModule}
      component={form(CarriersForm, 'create')}
    />
    <PrivateRoute
      path="/portal/:orgId/carriers/edit/:carrierId"
      wrapper={SupplierPortalModule}
      component={form(CarriersForm, 'edit')}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/users"
      wrapper={SupplierPortalModule}
      component={UsersList}
    />

    <PrivateRoute
      path="/portal/:orgId/users/create"
      wrapper={SupplierPortalModule}
      component={form(UserForm, 'create')}
    />

    <PrivateRoute
      path="/portal/:orgId/users/edit/:userId"
      wrapper={SupplierPortalModule}
      component={form(UserForm, 'edit')}
    />

    <PrivateRoute
      path="/portal/:orgId/linked/edit/:userId/linked-customer"
      wrapper={SupplierPortalModule}
      component={LinkedCustomerList}
    />

    <PrivateRoute
      path="/portal/:orgId/linked/edit/:userId/linked-facilities"
      wrapper={SupplierPortalModule}
      component={LinkedFacilitiesList}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/info"
      wrapper={SupplierPortalModule}
      component={AccountInformation}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/settings"
      wrapper={SupplierPortalModule}
      component={AccountSettings}
    />

    <PrivateRoute
      exact
      path="/portal/:orgId/change-password/:userId"
      wrapper={SupplierPortalModule}
      component={AccountChangePassword}
    />

    <Route component={NotFound} />
  </Switch>
)

Routes.propTypes = {
  history: PropTypes.object.isRequired
}

export default Routes
