export const register = (
  state = {
    data: [],
    registerLoaded: false
  },
  action
) => {
  switch (action.type) {
    case 'account/access/REGISTER_FETCH_SUCCESS':
      return {
        ...state,
        data: action.data,
        registerLoaded: true
      }
    case 'account/ROUTE_CHANGED':
      return state
    default:
      return state
  }
}

export const credentials = (
  state = {
    data: [],
    registerLoaded: false
  },
  action
) => {
  switch (action.type) {
    case 'account/credentials/REGISTER_FETCH_SUCCESS':
      return {
        ...state,
        data: action.data,
        registerLoaded: true
      }
    case 'account/ROUTE_CHANGED':
      return state
    default:
      return state
  }
}
