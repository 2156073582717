import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import account from 'account/state/index'
import app from './app/state/appReducer'
import shared from './shared/state/sharedReducer'
// import portal from 'modules/portal/state/reducers';

export default history =>
  combineReducers({
    router: connectRouter(history),
    app,
    shared,
    account
  })
