export default {
  add: 'Add',
  update: 'Update',
  ok: 'OK',
  adding: 'Adding',
  cancel: 'Cancel',
  print: 'Print',
  change: 'Change',
  changing: 'Changing',
  create: 'Create',
  creating: 'Creating',
  filter: 'Filter',
  applyFilter: 'Apply Filters',
  close: 'Close',
  loading: 'Loading...',
  password: 'Password',
  save: 'Save',
  saveAndBack: 'Save and Back',
  saved: 'Saved',
  saving: 'Saving',
  search: 'Search',
  back: 'Back',
  next: 'Next',
  skip: 'Skip',
  pleaseSelect: 'Select...',
  time: 'Time',
  date: 'Date',
  busy: 'Busy',
  submit: 'Submit',

  dateTime: {
    sun: 'Sun',
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    day: 'Day',
    days: 'Days',
    hour: 'Hour',
    hours: 'Hours',
    minute: 'Minute',
    minutes: 'Minutes',
    range: '{from} to {to}'
  },

  assignment: {
    assign: 'Assign',
    assigned: 'Assigned',
    availableMoveAll: 'Select All >',
    selectedMoveAll: '< Deselect All'
  },

  modules: {
    organisations: {
      title: 'Organisation',
      subtitle: 'system configuration'
    },
    portal: {
      title: 'Portal',
      subtitle: 'supplier portal'
    }
  },

  nav: {
    options: 'Options',
    facilities: 'Facilities',
    help: 'Help',
    call: 'Call',
    working: 'Working',
    userSettings: 'User Settings',
    organisations: 'Organisations',
    logout: 'Sign Out'
  },

  splashNav: {
    signIn: 'Sign In'
  },

  pagination: {
    text: {
      '=0': 'No items to display',
      one: 'Showing the only item',
      other: 'Showing {pageStart} to {pageEnd} of {count} items'
    },
    prev: 'Prev',
    next: 'Next'
  },

  table: {
    noResults: 'No items to display'
  },

  select: {
    noResults: 'No results found',
    noResultsWithAdhocEnabled: 'No results found, press enter to add value'
  },

  progress: {
    adding: 'Adding...',
    removing: 'Removing...',
    assigning: 'Assigning...',
    unassigning: 'Unssigning...'
  },

  validations: {
    title: 'Invalid Details',
    isRequired: '{label} is required',
    isRequiredAssignment: 'You need to assign at least one {label}',
    isEmail: '{label} must be a valid email address',
    isNumber: '{label} must be a valid number',
    isFormattedDate: '{label} must be a properly formatted date ({dateFormat})'
  },

  errors: {
    defaultApi: 'Something strange happened. Please contact us or try again later.',
    error: 'Error'
  },

  success: {
    success: 'Success'
  },

  warningDialog: {
    warning: 'Warning!',
    confirm: 'Confirm'
  },

  idNumberInput: {
    label: 'ID Number',
    error: 'This is not a valid RSA ID number'
  },

  timeRestrictions: {
    configureTimeRestrictions: 'Configure Time Restrictions',
    addRestriction: 'Add Restriction',
    dayOfWeek: 'Day of week',
    from: 'From Hour',
    to: 'To Hour',
    minute: 'Minute',
    selectValueForEachField: 'Please select a value for each field',
    emptyLabel: 'There are no time restrictions to display'
  },
  avt: {
    address: 'Address',
    avtAddressManagement: 'AVT Address Management',
    singleLineSearch: 'Single Line Search',
    structuredSearch: 'Structured Address Search',
    gpsCoordinates: 'GPS Coordinates',
    searchOptions: {
      currentViewOnly: 'Only search in current map bounds',
      wholeWorld: 'Search all over the world',
      orgCountry: 'Only search in {country}'
    },
    searchSettings: {
      currentViewOnly:
        'Searching locations within current map bounds. Open settings to edit the search parameters.',
      wholeWorld:
        'Searching addresses around the world. Open settings to edit the search parameters.',
      orgCountry:
        'Searching addresses in {country} only. Open settings to edit the search parameters.'
    },
    searchAddresses: 'Search addresses...',
    searchRadius: 'Preferred Search Radius (around the site)',
    latDecimal: 'Lat decimal',
    lngDecimal: 'Lng decimal',
    latDeg: 'Lat deg',
    latMin: 'Lat min',
    latSec: 'Lat sec',
    lngDeg: 'Lng deg',
    lngMin: 'Lng min',
    lngSec: 'Lng sec',
    form: {
      unit_no: 'Unit No.',
      building_name: 'Building Name',
      street_no: 'Street No.',
      sub_division_number: 'Sub-Division No.',
      street: 'Street Name',
      suburb: 'Suburb',
      city: 'City',
      province: 'Province',
      postal_code: 'Postal Code',
      coords: 'GPS Coordinates'
    }
  },

  geo: {
    geocodeFailed: 'Address lookup failed, please try a different location.',
    addressNotFound: 'Address not found'
  }
}
