import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Intl from 'shared/higher-order-components/Intl'
import { Button } from 'shared/components'

let FormSubmit = ({
  formStatus,
  formType = 'edit',
  icons = {},
  text = {},
  copy,
  className,
  ...props
}) => {
  const defaultIcons = {
    create: { loading: 'download', clean: 'plus', touched: 'plus', working: 'dots' },
    add: { loading: 'download', clean: 'plus', touched: 'plus', working: 'dots' },
    change: { loading: 'download', clean: 'upload', touched: 'upload', working: 'dots' },
    edit: { loading: 'download', clean: 'check', touched: 'upload', working: 'dots' },
    default: { loading: 'download', clean: 'check', touched: 'upload', working: 'dots' },
    reports: { loading: 'download', clean: '', touched: '', working: '' }
  }

  const defaultText = {
    create: { loading: 'loading', clean: 'create', touched: 'create', working: 'creating' },
    add: { loading: 'loading', clean: 'add', touched: 'add', working: 'adding' },
    change: { loading: 'loading', clean: 'change', touched: 'change', working: 'changing' },
    edit: { loading: 'loading', clean: 'save', touched: 'save', working: 'saving' },
    default: { loading: 'loading', clean: 'submit', touched: 'submit', working: 'busy' },
    reports: { loading: 'loading', clean: 'create', touched: 'create', working: 'creating' }
  }

  icons = icons && { ...defaultIcons[formType], ...icons }

  return (
    <Button
      kind={className}
      className={cx('form_submit', className)}
      iconRight={icons[formStatus]}
      size="large"
      progress={formStatus === 'working'}
      {...props}
    >
      {text[formStatus] || copy.shared(defaultText[formType][formStatus])}
    </Button>
  )
}

FormSubmit.propTypes = {
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

FormSubmit = Intl()(FormSubmit)

export { FormSubmit }
