import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Intl from 'shared/higher-order-components/Intl'
import { WarningDialog, Button, Input, ValidationList } from 'shared/components'
import imageLogo from 'shared/assets/logo-light.svg'
import * as actions from '../state/credentials/credentialActions'

class Credentials extends Component {
  state = {
    showVerifcation: false
  }
  componentDidMount() {
    const {
      requestAccess,
      match: {
        params: { token }
      }
    } = this.props
    requestAccess(token)
  }

  verifyKey = () => {
    const { verifyKey, data } = this.props
    verifyKey(data.id, this.$verification.value)
  }

  onSubmit = async () => {
    const { requestKey, data } = this.props
    const res = await requestKey(data.id)
    if (!!res) {
      this.setState(({ showVerifcation }) => ({
        showVerifcation: !showVerifcation
      }))
    }
  }

  render() {
    const { copy, messages } = this.props
    const { showVerifcation } = this.state

    return (
      <div className="splash center-cont" style={{ height: '100%' }}>
        <div className="account__login splash-box center">
          <div className="splash-logo-holder">
            <img className="splash-logo" src={imageLogo} alt="trackmatic" />
            <div className="splash-heading">{copy.get('heading')}</div>
          </div>

          <ValidationList messages={messages} icon="i-exclamation" customIconClass="splash-round" />

          {showVerifcation && (
            <>
              <Input
                reff={el => (this.$verification = el)}
                styleType="line"
                iconLeft="mobile"
                placeholder={copy.get('verifyText')}
              />
              <Button
                size="large"
                type="secondary"
                style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}
                onClick={this.verifyKey}
              >
                {copy.get('verifyCode')}
              </Button>
            </>
          )}

          {!showVerifcation && (
            <>
              <Button
                type="success"
                size="large"
                style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}
                onClick={this.onSubmit}
              >
                {copy.get('accept')}
              </Button>

              <WarningDialog
                message={copy.get('declineText')}
                onConfirm={() => console.log('decline')}
                modalTrigger={
                  <Button
                    type="danger"
                    size="large"
                    style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}
                  >
                    {copy.get('decline')}
                  </Button>
                }
              />
            </>
          )}
        </div>
      </div>
    )
  }
}

Credentials.propTypes = {
  copy: PropTypes.object.isRequired
}

const mapStateToProps = ({ shared, account: { credentials } }) => ({
  ...shared.form,
  ...credentials
})
const mapDispatchToProps = actions

Credentials = connect(
  mapStateToProps,
  mapDispatchToProps
)(Credentials)

export default Intl({ get: 'account.auth' })(Credentials)
