/* eslint react/no-multi-comp: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CustomScroll from 'react-custom-scroll'
import Intl from 'shared/higher-order-components/Intl'
import { Spinner } from 'shared/components'
import st from './Table.module.scss'

let Table = ({
	className,
	headers,
	contentHeight,
	title,
	rows,
	height,
	withoutScroll,
	allowOuterScroll,
	isLoading,
	copy,
	style,
	flex,
	emptyMessage
}) => {
	const unassignedWidth = 100 - headers.reduce((sum, { width = 0 }) => sum + width, 0)
	const defaultWidth = unassignedWidth / headers.filter(h => !h.width).length

	headers = headers.map(header => ({
		width: defaultWidth,
		...header
	}))

	const renderHead = () => (
		<div className={st.table_head}>
			{headers.map(({ text, content, width, className, style = {}, ...props }, i) => (
				<div
					{...props}
					key={`header${i}`}
					style={{ ...style, width: `${width}%` }}
					className={classnames(st.table_header, className)}
					title={text || null}
				>
					{text && <div className={st['table_header-text']}>{text}</div>}
					{content && content}
				</div>
			))}
		</div>
	)

	const renderRows = () => (
		<div className={st.table_rows} style={{ height: contentHeight }}>
			{rows.map((cells, i) => (
				<div key={`row${i}`} className={st.table_row}>
					{cells.map(
						({ text, content, width, className, textClass, colSpan, style = {}, ...props }, i) => (
							<div
								{...props}
								key={`cell${i}`}
								style={{ ...style, width: `${headers[i] && headers[i].width}%` }}
								className={classnames(st.table_cell, st[className])}
								title={text || null}
							>
								{text ? (
									<div className={classnames(st['table_cell-text'], st[textClass])}>{text}</div>
								) : null}
								{content && content}
							</div>
						)
					)}
				</div>
			))}

			{!rows.length && !isLoading ? (
				<div
					className={classnames(st['table_empty-label'], {
						[`${st['table_empty-label-center']}`]: withoutScroll
					})}
				>
					{emptyMessage || copy.get('noResults')}
				</div>
			) : null}
		</div>
	)

	const renderTitle = () => (
		<>
			{title ? (
				<div className={st.table_head}>
					<div key="title" className={st.table_title} title="table">
						{title}
					</div>
				</div>
			) : null}
		</>
	)

	const heightValue = () => {
		if (typeof height === 'string') {
			return height
		}
		return `${height}px`
	}

	return (
		<div>
			<div
				className={classnames(st.table, st[className], {
					[`${st['table_is-loading']}`]: isLoading
				})}
				style={style}
			>
				{isLoading ? <Spinner size={50} center={50} /> : null}
				{renderTitle()}
				{renderHead()}
				{withoutScroll ? (
					renderRows()
				) : (
						<CustomScroll
							heightRelativeToParent={flex ? undefined : heightValue()}
							allowOuterScroll={!!allowOuterScroll}
						>
							{renderRows()}
						</CustomScroll>
					)}
			</div>
		</div>
	)
}

Table.propTypes = {
	className: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	title: PropTypes.string,
	headers: PropTypes.arrayOf(PropTypes.object),
	rows: PropTypes.arrayOf(PropTypes.object),
	withoutScroll: PropTypes.bool,
	allowOuterScroll: PropTypes.bool,
	isLoading: PropTypes.bool,
	copy: PropTypes.object.isRequired,
	style: PropTypes.object,
	flex: PropTypes.bool,
	emptyMessage: PropTypes.string,
	contentHeight: PropTypes.string
}

Table = Intl({ get: 'shared.table' })(Table)

export { Table }
