import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Intl from 'shared/higher-order-components/Intl'
import { Input, WarningDialog, ValidationList, FormSubmit, Button } from 'shared/components'
import Form, { isRequired, serverMessage, isEmail } from 'shared/higher-order-components/Form'
import imageLogo from 'shared/assets/logo-light.svg'
import * as actions from '../state/RegisterActions'

class Register extends Component {
  state = {
    showVerifyCode: false
  }
  componentDidMount() {
    const {
      requestAccess,
      match: {
        params: { token }
      }
    } = this.props
    requestAccess(token)
  }

  verifyKey = () => {
    const { verifyKey, data } = this.props
    verifyKey(data.id, this.$verification.value)
  }

  onSubmit = async data => {
    const { submit } = this.props

    this.setState(({ showVerifyCode }) => ({
      showVerifyCode: !showVerifyCode
    }))

    const res = await submit(data)
    console.log('onSubmit', res)
  }

  render() {
    const { fields, messages, copy, submit, formStatus } = this.props

    return (
      <div className="splash center-cont" style={{ height: '100%' }}>
        <div className="account__login splash-box center">
          <div className="splash-logo-holder">
            <img className="splash-logo" src={imageLogo} alt="trackmatic" />
            <div className="splash-heading">{copy.get('heading')}</div>
          </div>
          <ValidationList messages={messages} icon="i-exclamation" customIconClass="splash-round" />
          <Input
            {...fields.name}
            id="username"
            styleType="line"
            iconLeft="person_outline"
            placeholder={copy.get('username')}
          />

          <Input
            {...fields.email}
            styleType="line"
            iconLeft="mail_outline"
            placeholder={copy.get('email')}
          />

          <Input
            {...fields.mobile}
            styleType="line"
            iconLeft="mobile"
            placeholder={copy.get('mobile')}
          />

          {this.state.showVerifyCode && (
            <>
              <Input
                reff={el => (this.$verification = el)}
                styleType="line"
                iconLeft="mobile"
                placeholder={copy.get('verifyText')}
              />

              <Button size="large" type="secondary" onClick={this.verifyKey}>
                {copy.get('verifyCode')}
              </Button>
              <Button className="float-right" size="large" type="secondary" onClick={submit}>
                {copy.get('resetCode')}
              </Button>
            </>
          )}

          <FormSubmit
            kind="primary"
            size="large"
            formType="create"
            formStatus={formStatus}
            text={{
              clean: copy.get('genarateKey'),
              touched: copy.get('genarateKey'),
              working: copy.get('generatingKey')
            }}
            icons=""
            onClick={this.onSubmit}
            style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}
          />

          <WarningDialog
            message={copy.get('declineText')}
            onConfirm={() => console.log('decline')}
            modalTrigger={
              <Button
                type="danger"
                size="large"
                style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}
              >
                {copy.get('decline')}
              </Button>
            }
          />
        </div>
      </div>
    )
  }
}

Register.propTypes = {
  fields: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

Register = Form({
  fields: ({ copy }) => ({
    name: [isRequired(copy.get('name'))],
    email: [isEmail(copy.get('email')), isRequired(copy.get('email'))],
    mobile: [isRequired(copy.get('mobile'))]
  }),

  mapPropsToFields: ({ data: { owner }, registerLoaded }) => {
    return {
      name: registerLoaded && owner.name,
      email: registerLoaded && owner.email,
      mobile: registerLoaded && owner.mobile
    }
  },

  submit({ props, fields }) {
    return props.requestKey(fields, props.data.id)
  }
})(Register)

const mapStateToProps = ({ shared, account: { register } }, { params }) => ({
  ...register,
  ...shared.form,
  ...params
})
const mapDispatchToProps = actions

Register = connect(
  mapStateToProps,
  mapDispatchToProps
)(Register)

export default Intl({ get: 'account.auth' })(Register)
