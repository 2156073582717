import React from 'react'
import PropTypes from 'prop-types'
import Intl from 'shared/higher-order-components/Intl'
import { PageNav } from 'shared/components'

const Nav = ({ copy, orgId, userId }) => {
  return (
    <PageNav
      links={[
        {
          to: `/portal/${orgId}/users/edit/${userId}`,
          text: `${copy.get(`Edit user`)}`
        },
        {
          to: `/portal/${orgId}/linked/edit/${userId}/linked-customer`,
          text: `${copy.get(`Linked Customers`)}`
        }
      ]}
    />
  )
}

Nav.propTypes = {
  copy: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired
}

export default Intl({ get: 'portal.users' })(Nav)
