import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { debounce, isNil } from 'lodash'
import colors from 'shared/static/colors'
import * as utils from './utils'
import { isEqual } from 'lodash'

const createOverlay = (props) => {
	const { value, google, map, mode, color } = props
	const overlay = value ? utils.shapeFactoryMethods[mode].createOverlay(google, map, color)(value) : null
	return { overlay }
}

const initShape = { marker: undefined, markers: null, size: 100 }

class MapEditableZone extends Component {

	static propTypes = {
		map: PropTypes.object,
		google: PropTypes.object,
		mode: PropTypes.oneOf(['radius', 'polygon']),
		onChange: PropTypes.func,
		value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		color: PropTypes.string,
	}

	static defaultProps = {
		mode: 'polygon',
		onChange: function (shape) { },
		color: colors.blue
	}

	constructor(props) {
		super(props)
		this.drawingManager = utils.createDrawingManager({
			...props,
			onOverlayComplete: this.onDrawInitialOverlay,
		})
		this.dispatchChange = debounce(this.dispatchChange, 300)
		this.$overlay = createOverlay(props)

		this.setState({ ...props.value })
	}

	componentDidMount() {
		const { overlay } = this.$overlay

		if (!overlay) {
			this.enableDrawing()
		} else {
			this.bindOverlayEvents(this.$overlay)
			this.disableDrawing()
		}
	}

	componentWillReceiveProps(newProps) {

		const propertiesToCheckForChanges = ['marker', 'markers', 'size']

		const redraw = () => {
			this.destroyOverlay()
			this.enableDrawing(newProps)
		}

		const shapeChanged = () => {
			let shape = this.state
			for (let index = 0; index < propertiesToCheckForChanges.length; index++) {
				const property = propertiesToCheckForChanges[index]
				if (shape && !isEqual(shape[property], newProps.value[property])) {
					if (utils.isCoord(shape[property]) && utils.isCoord(newProps.value[property])) {
						return !utils.coordEqual(shape[property], newProps.value[property], 100000000)
					}
					return true
				}
			}
			return false
		}

		const modeChanged = () => {
			return this.props.mode !== newProps.mode
		}

		const hasShape = () => {
			return newProps.value.entrance && (newProps.value.markers || newProps.value.marker)
		}

		if (modeChanged()) {
			this.destroyOverlay()
			if (hasShape()) {
				this.$overlay = createOverlay(newProps)
				this.disableDrawing()
			} else {
				this.enableDrawing(newProps)
			}
			let bShapeChanged = shapeChanged()
			let bHasShape = hasShape()
			this.setState({ ...newProps.value }, () => {				
				if (bShapeChanged && bHasShape) {
					this.bindOverlayEvents(this.$overlay)
					this.disableDrawing()
				}
			})
		} else if (shapeChanged()) {
			this.destroyOverlay()
			this.$overlay = createOverlay(newProps)
			this.bindOverlayEvents(this.$overlay)
			this.setState({ ...newProps.value }, () => {
			})
		}
	}

	componentWillUnmount() {
		const { google } = this.props
		this.destroyOverlay()
		utils.whenNotNull(this.drawingManager, google.maps.event.clearInstanceListeners)
		this.drawingManager.setMap(null)
	}

	destroyOverlay = () => {
		const { google } = this.props
		const { overlay } = this.$overlay
		const clear = (overlay) => {
			overlay.setMap(null)
			google.maps.event.clearInstanceListeners(overlay)
		}
		utils.whenNotNull(overlay, clear)
	}

	bindOverlayEvents = ({ overlay }) => {
		const { mode } = this.props
		overlay && utils.shapeFactoryMethods[mode].bind(overlay, this.onOverlayChanged)
		overlay && overlay.fitBounds && overlay.fitBounds()
	}

	enableDrawing = (newProps) => this.drawingManager.setDrawingMode(utils.translateDrawingMode(newProps ? newProps.mode : this.props.mode))

	disableDrawing = () => this.drawingManager.setDrawingMode(null)

	onDrawInitialOverlay = (e) => {
		const { google, map, mode } = this.props
		this.disableDrawing()
		const center = utils.getCenter(google)(mode, e.overlay)
		this.$overlay = { overlay: e.overlay }
		this.bindOverlayEvents(this.$overlay)
	}

	onOverlayChanged = () => {
		this.dispatchChange()
	}

	dispatchChange = () => {
		const { overlay } = this.$overlay
		const { onChange, mode } = this.props
		const factory = utils.shapeFactoryMethods[mode]
		const shape = factory.createShape(overlay)
		onChange({ ...initShape, ...shape })
	}

	render() {
		return null
	}
}

export default MapEditableZone

