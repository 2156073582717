import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Intl from 'shared/higher-order-components/Intl'
import Form from 'shared/higher-order-components/Form'
import { PageNav, Page, FormSubmit, Input, InputFile, ValidationList } from 'shared/components'
import { uploadDocument } from 'account/state/wizard/WizardActions'
import { updateSettings, fetchSettings } from '../../state/settings/settingActions'

let SettingsForm = ({
  orgId,
  formType,
  fields,
  messages,
  submit,
  formStatus,
  copy,
  uploadDocument,
  fetchSettings
}) => {
  const [logourl, updateUrl] = useState('')

  useEffect(() => {
    fetchSettings(orgId)
  }, [])

  const performUpload = async data => {
    const { id } = await uploadDocument(orgId, data)
    updateUrl(id)
  }

  const onSubmit = () => {
    const data = {
      logo_url: logourl,
      primary_color: fields.primary_color.value
    }

    submit(data)
  }

  const theNav = () => (
    <PageNav
      links={[
        {
          to: `/portal/${orgId}/info`,
          text: `${copy.get(`Company Info`)}`
        },
        {
          to: `/portal/${orgId}/settings`,
          text: `${copy.get(`Settings`)}`
        }
      ]}
    />
  )

  return (
    <Page title="Settings" navContent={theNav()}>
      <div className="form form-medium text-left">
        <div className="form_heading">{copy.get('heading')}</div>
        <div className="form_sub-heading">{copy.get('desc')}</div>

        <ValidationList messages={messages} />
        <div className="row">
          <div className="col-12">
            <Input disabled iconLeft="briefcase" {...fields.name} label={copy.get('name')} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              iconLeft="format_color_fill"
              type="color"
              {...fields.primary_color}
              label={copy.get('pColor')}
            />
          </div>
          <div className="col-6">
            <InputFile label onFileSelect={({ data }) => performUpload(data)} />
          </div>
        </div>

        <div className="row">
          <div className="col-6 float-right">{copy.get('note')}</div>
        </div>

        <FormSubmit
          formType={formType}
          formStatus={formStatus}
          onClick={onSubmit}
          icons=""
          text={{
            clean: copy.get('Save'),
            touched: copy.get('Save'),
            working: copy.get('Saving')
          }}
          style={{ width: '50%', textAlign: 'center' }}
        />
      </div>
    </Page>
  )
}

SettingsForm.propTypes = {
  formType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  formItem: PropTypes.object.isRequired,
  formLoaded: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired,
  uploadDocument: PropTypes.func.isRequired
}

SettingsForm = Form({
  fields: () => ({
    name: [],
    primary_color: [],
    logo_url: []
  }),

  isFormReady: ({ props }) => props.formLoaded,

  mapPropsToFields: ({ formItem }) =>
    ({ name: formItem.name, ...formItem.portal_settings } || false),

  submit: ({ props, fields: { primary_color, logo_url } }) => {
    const data = { portal_settings: { primary_color, logo_url } }
    props.updateSettings(props.orgId, data)
  }
})(SettingsForm)

const mapStateToProps = ({ shared }, { match }) => ({
  ...match.params,
  ...shared.form
})

const mapDispatchToProps = { uploadDocument, updateSettings, fetchSettings }

SettingsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsForm)

export default Intl({
  get: 'portal.settings'
})(SettingsForm)
