import React from 'react'
import PropTypes from 'prop-types'
import Intl from 'shared/higher-order-components/Intl'
import imageLogo from 'shared/assets/logo-light.svg'

const WizardModule = ({ children, copy }) => {
  return (
    <div className="splash center-cont">
      <div className="splash-box" style={{ width: 'inherit' }}>
        <div className="splash-logo-holder" style={{ width: '480px' }}>
          <img className="splash-logo" src={imageLogo} alt="trackmatic" />
          <div className="splash-heading">{copy.get('heading')}</div>
        </div>
        {React.cloneElement(children, { copy })}
      </div>
    </div>
  )
}

WizardModule.propTypes = {
  children: PropTypes.element.isRequired,
  copy: PropTypes.object.isRequired
}

export default Intl({ get: 'account.wizard' })(WizardModule)
