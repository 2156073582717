export default {
  item: {
    reference: 'Reference',
    description: 'Description',
    starttime: 'Start',
    endtime: 'End',
    duration: 'Duration',
    status: 'Status',
    consignors: 'Consignors',
    consignments: 'Consignments',
    carrier: 'Carrier',
    returns: 'Returns',
    transactionStatus: 'Status',
    entityname: 'Entity',
    transationCount: 'Transactions',
    bookings: 'Bookings',
    bays: 'Bays',
    bay: 'Bay',
    transactionCount: 'Transactions',
    transactionDate: 'Transaction Date',
    expectedDeliveryDate: 'Expected Date',
    transactionType: 'Type',
    addTransactions: 'Please add transactions below',
    custFacility: 'Customer/Facility'
  },
  printGatePass: 'Print Gate Pass',
  emailGatePass: {
    name: 'Email Gate Pass',
    email: 'Email',
    success: 'Gate Pass has been emailed successfully',
    title: 'Email Gate Pass for Booking {reference}'
  },
  deleteWarning: 'Are you sure you want to remove this booking?',
  form: {
    createBooking: 'Create Booking',
    editBooking: 'Edit Booking',
    bays: 'Select Bay',
    start: 'Booking Start Date',
    starttime: 'Start Time',
    end: 'Booking End Date',
    endDate: 'Booking end date cannot be older than start date',
    endtime: 'End Time',
    publishcreate: 'Create and Publish',
    direction: 'Booking Direction',
    deliveryType: 'Type of Delivery',
    hasReturns: 'Booking has Returns',
    palletized: 'Palletized',
    palletizedYes: 'Yes',
    palletizedNo: 'No',
    noOfPallets: 'Number of Pallets',
    publish: 'Publish',
    unpublish: 'UnPublish',
    publishing: 'Publishing',
    assign: {
      validations: {
        booking: ' booking'
      }
    }
  },
  transaction: {
    term: 'Search Transactions',
    search: 'Search',
    addTransactions: 'Allocate Transactions',
    filter: {
      from: 'Date',
      to: 'To',
      datetype: 'Date Filter',
      expecteddate: 'Expected Date',
      entities: 'All Entities',
      transactiondate: 'Transaction Date',
      unassigned: 'UnAllocated',
      assignedall: 'All'
    }
  },
  carrier: {
    term: 'Filter by carrier name',
    desc: 'If you don’t want any custom branding our default theme will be applied.',
    search: 'Search',
    addNewCarrier: 'Add Carrier',
    addToCarriers: 'Add to carriers',
    dateadded: 'Date Added',
    cancel: 'Cancel',
    form: {
      name: 'Carrier Name',
      id: 'Carrier Id',
      reg: 'Reg Number',
      status: 'Status',
      suspend: 'Suspend Account',
      dateSuspended: 'Date Suspended',
      reason: 'Provide Reason'
    }
  },
  customers: {
    tablename: 'Customer Details',
    list: {
      vendorcde: 'Vendor Code',
      customer: 'Customer',
      facilities: 'Facilities',
      dateAdded: 'Date Added',
      status: 'Status'
    },
    form: {
      name: 'Name',
      heading: 'Edit a customer',
      status: 'Status',
      cancel: 'Cancel'
    },
    facilities: {
      tablename: 'Facilities',
      list: {
        id: 'ID',
        name: 'Name'
      }
    },
    contacts: {
      list: {
        id: 'ID',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        contact_no: 'Contact Number',
        designation: 'Designation',
        createNewContact: 'Add a new Contact',
        addNewContact: 'Add New Contact',
        editNewContact: 'Edit Contact',
        deleteWarning: 'Are you sure you want to delete this Contact?'
      },
      form: {
        id: 'ID',
        name: 'Name',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        contact_no: 'Contact Number',
        designation: 'Designation',
        no_change: 'Save',
        save_change: 'Save',
        saving_change: 'Saving...',
        cancel: 'Cancel',
        editContact: 'Edit Contact',
        createContact: 'Add Contact'
      }
    },
    nav: {
      editCustomer: 'Edit Customer',
      all: 'All Customers',
      general: 'General',
      contacts: 'Contacts'
    },
    search: {
      buttonText: 'Search',
      term: 'Term'
    }
  },

  bookings: {
    allStatuses: 'All Statuses',
    bookings: 'Bookings',
    term: 'Term',
    from: 'Start Date',
    bays: 'All Bays',
    status: 'All Statuses',
    entities: 'All Entities',
    ActualOrActual: 'Planned Data',
    addNewBooking: 'Add New Booking',
    search: 'Search',
    list: {
      reference: 'Booking Reference',
      status: 'Status',
      consignments: 'Consignments',
      bay: 'Bay',
      custFacility: 'Customer/Facility',
      starttime: 'Start Time',
      duration: 'Duration',
      carrier: 'Carrier',
      customer: 'Customer',
      facility: 'Facility'
    },
    statuses: {
      in_facility: 'In Facility',
      in_bay: 'In Bay',
      out_bay: 'Out Bay',
      out_facility: 'Out Facility',
      planned: 'Planned',
      planning: 'Planning',
      cancelled: 'Cancelled',
      in_sorting: 'In Sorting',
      executing: 'Executing',
      out_sorting: 'Out Sorting',
      in_busy: 'Busy',
      in_returns: 'In Returns',
      out_returns: 'Out Returns',
      complete: 'Complete',
      closed: 'Closed'
    },
    printGatePass: 'Print Gate Pass',
    email: {
      name: 'Email Gate Pass',
      email: 'Email',
      success: 'Gate Pass has been emailed successfully',
      title: 'Email Gate Pass for Booking {reference}'
    }
  },
  users: {
    search: 'Search',
    heading: 'System Settings',
    term: 'Filter by ID number, name etc.',
    desc: `If you don’t want any custom branding our default theme will be applied. (You can update
        these settings at any time)`,
    pColor: 'Primary Color',
    sColor: 'Secondary Color',
    note: 'For best results please update a transparent .png file 250x100 pixels in size.',
    suspendWarning: 'Are you sure you want to suspend this users`s access',
    deleteWarning: 'Are you sure you want to remove linked customer',
    form: {
      username: 'Username',
      createUser: 'Add a new user',
      editUser: 'Edit a user',
      identity: 'Identity Number',
      fName: 'First Name',
      lName: 'Last Name',
      fullnames: 'Fullname',
      email: 'Email Address',
      mobile: 'Mobile Number',
      landline: 'Landline Number',
      jobTitle: 'Designation / Job Title',
      officeNum: 'Office Number',
      permission: 'Permissions',
      admin: 'Admin',
      user: 'User',
      view: 'View Only',
      AddNewUser: 'Add New User'
    }
  },
  settings: {
    heading: 'System Settings',
    desc: `If you don’t want any custom branding our default theme will be applied. (You can update
        these settings at any time)`,
    pColor: 'Primary Color',
    sColor: 'Secondary Color',
    note: 'For best results please update a transparent .png file 250x75 pixels in size.',
    name: 'Company / Display Name'
  },
  company: {
    heading: 'Company Info',
    regName: 'Registered Name',
    name: 'Company / Display Name',
    vat: 'Company VAT No.',
    regNo: 'Company Registration No.',
    address: 'Address'
  }
}
