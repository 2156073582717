import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Input, ValidationList, Button } from 'shared/components'
import Form, { isRequired } from 'shared/higher-order-components/Form'
import * as actions from '../../state/wizard/WizardActions'

// eslint-disable-next-line import/no-mutable-exports
let VerifyDetails = ({ fields, messages, submit, copy }) => {
  return (
    <div className="form form-widget text-left">
      <div className="form_heading">Lets get started…</div>
      <div className="form_sub-heading">{copy.get('details.verify')}</div>

      <ValidationList messages={messages} />
      <div className="row">
        <div className="col-12">
          <Input
            iconLeft="briefcase"
            {...fields.name}
            label={copy.get('details.carrierName')}
            autoFocus
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Input
            iconLeft="briefcase"
            {...fields.registered_name}
            label={copy.get('details.carrierId')}
            disabled
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Input
            iconLeft="file-archive-o"
            {...fields.vat_no}
            label={copy.get('details.vatNo')}
            disabled
          />
        </div>
        <div className="col-6">
          <Input iconLeft="file-archive-o" {...fields.reg_no} label={copy.get('details.regNo')} />
        </div>
      </div>

      <Button
        onClick={submit}
        className="form_cancel"
        style={{ float: 'right', width: '50%' }}
        size="large"
        type="primary"
      >
        Next
      </Button>
    </div>
  )
}

VerifyDetails.propTypes = {
  fields: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  formStatus: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
}

VerifyDetails = Form({
  fields: ({ copy }) => ({
    name: [isRequired(copy.get('details.displayName'))],
    registered_name: [isRequired(copy.get('details.registeredName'))],
    vat_no: [isRequired(copy.get('details.vatNo'))],
    reg_no: [isRequired(copy.get('details.regNo'))]
  }),

  mapPropsToFields: ({ data, user }) =>
    data ? { ...data, registered_name: user && user.name } : false,

  submit: ({ props, fields }) => {
    props.storeWidgetData(fields, false, 'address')
  }
})(VerifyDetails)

const mapStateToProps = ({ account: { wizard }, app: { user } }) => ({
  ...wizard,
  user: user.master_entity
})
const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyDetails)
