// For core form component
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { transform, values, flatMap, mapValues, isArray, isNil, isFinite, union } from 'lodash'
// For validations
import { isValidDateFormat, getDateFormat } from 'shared/lib/dateTime'
import { goToCopy } from 'shared/lib/intl'

const FormHoc = ({
  fields: fieldValidations = () => ({}),
  mapPropsToFields = () => false,
  shouldReceiveFields = ({ fields }) => !values(fields).find(field => field.touched),
  shouldUpdateFormItem = () => false,
  shouldComponentUpdate = () => true,
  isFormReady = () => true,
  onFormChanged = () => false,
  submitOnEnter = () => true,
  submit = ({ props, fields }) => {
    props.onSubmit(fields)
  },
  onSubmitError = () => {},
  onSubmitSuccess = () => {}
}) => Child => {
  const getFieldsFromProps = props => {
    const fieldValues = mapPropsToFields(props) || {}
    return transform(fieldValidations(props), (ret, val, field) => {
      ret[field] = {
        name: field,
        value: fieldValues[field],
        touched: false,
        messages: []
      }
    })
  }

  const fieldsWithMessages = (props, fields, serverMessages) =>
    transform(fieldValidations(props), (ret, validations, field) => {
      ret[field] = {
        ...fields[field],
        messages: validations
          .map(validate =>
            validate({
              field: fields[field],
              fields,
              serverMessages
            })
          )
          .filter(msg => !!msg)
      }
    })

  const touchAllFields = fields =>
    transform(fields, (ret, val, field) => {
      ret[field] = { ...val, touched: true }
    })

  const propTypes = {
    messages: PropTypes.array,
    submitting: PropTypes.bool,
    copy: PropTypes.object
  }

  class Form extends Component {
    constructor(props) {
      super(props)
      this.state = {
        fields: getFieldsFromProps(props),
        serverMessages: [],
        formStatus: 'loading'
      }
      this.isFormDisabled = this.isFormDisabled.bind(this)
      this.shouldFormSubmit = this.shouldFormSubmit.bind(this)
      this.updateField = this.updateField.bind(this)
      this.bulkUpdateFields = this.bulkUpdateFields.bind(this)
      this.submit = this.submit.bind(this)
      this.resetForm = this.resetForm.bind(this)
    }

    componentDidMount() {
      this.setState({
        formStatus: isFormReady({ props: this.props }) ? 'clean' : 'loading'
      })
    }

    componentWillReceiveProps({ messages, ...props }) {
      const { fields } = this.state
      if (
        (shouldUpdateFormItem(this.props, props) || shouldReceiveFields({ fields, props })) &&
        !!mapPropsToFields(props)
      ) {
        const newFields = getFieldsFromProps(props)
        this.setState(prevState => {
          return {
            fields: newFields,
            serverMessages: []
          }
        })
      }

      if (!isFormReady({ props })) {
        return this.setState({ formStatus: 'loading' })
      }

      if (props.submitting) {
        return this.setState({ formStatus: 'working' })
      }

      if (this.props.submitting) {
        if (!messages.length) {
          onSubmitSuccess({ props })
          return this.setState({
            fields: fieldsWithMessages(props, fields, []),
            serverMessages: [],
            formStatus: 'clean'
          })
        } else {
          onSubmitError({ props })
          return this.setState({
            fields: fieldsWithMessages(props, fields, messages),
            serverMessages: messages,
            formStatus: 'touched'
          })
        }
      }

      if (this.state.formStatus === 'loading') {
        return this.setState({ formStatus: 'clean' })
      }
    }

    shouldComponentUpdate(nextProps) {
      return shouldComponentUpdate(this.props, nextProps)
    }

    isFormDisabled() {
      return /loading|working/.test(this.state.formStatus)
    }

    resetForm() {
      this.setState(prevState => {
        return {
          fields: getFieldsFromProps(this.props),
          serverMessages: [],
          formStatus: 'clean'
        }
      })
    }

    updateField(field, value, callback) {
      if (this.isFormDisabled()) return
      const updatedFields = {
        ...this.state.fields,
        [field]: {
          ...this.state.fields[field],
          value,
          touched: true
        }
      }

      return new Promise(resolve => {
        this.setState(prevState => {
          const newState = {
            fields: fieldsWithMessages(this.props, updatedFields, this.state.serverMessages),
            formStatus: 'touched'
          }
          resolve(newState)

          onFormChanged({ props: this.props, fields: mapValues(newState.fields, 'value') })

          return newState
        }, callback)
      })
    }

    bulkUpdateFields(fields) {
      if (this.isFormDisabled()) return

      const updatedFields = {
        ...this.state.fields,
        ...transform(fields, (ret, value, field) => {
          ret[field] = {
            ...this.state.fields[field],
            value,
            touched: true
          }
        })
      }
      this.setState(prevState => {
        const newState = {
          fields: fieldsWithMessages(this.props, updatedFields, this.state.serverMessages),
          formStatus: 'touched'
        }

        onFormChanged({ props: this.props, fields: mapValues(newState.fields, 'value') })
        return newState
      })
    }

    shouldFormSubmit() {
      const fields = fieldsWithMessages(
        this.props,
        touchAllFields(this.state.fields),
        this.state.serverMessages
      )
      return !values(fields).find(field => field.messages.find(msg => msg.preventSubmit))
    }

    trimValue = value => value.replace(/^\s+|\s+$/gm, '')

    getFormItem = () => mapValues(this.state.fields, 'value')

    submit() {
      if (this.isFormDisabled()) return
      let { fields, serverMessages } = this.state
      fields = fieldsWithMessages(this.props, touchAllFields(fields), serverMessages)

      this.setState({ fields })

      if (this.shouldFormSubmit()) {
        this.setState({ formStatus: 'working' })
        submit({
          props: this.props,
          fields: mapValues(fields, f => {
            if (typeof f.value === 'string') {
              return this.trimValue(f.value)
            } else {
              return f.value
            }
          })
        })
      }
    }

    setValidationMessages = messages => {
      this.setState({ serverMessages: messages })
    }

    render() {
      const { fields, serverMessages, formStatus } = this.state
      return (
        <Child
          {...this.props}
          messages={union(
            flatMap(values(fields), field => field.messages.map(msg => msg.message)),
            // Some server messages might be missing keys
            serverMessages.map(msg => msg.message)
          )}
          fields={transform(fields, (ret, val, field) => {
            ret[field] = {
              name: field,
              value: isNil(val.value) ? '' : val.value,
              invalid: !!val.messages.length,
              onChange: value => this.updateField(field, value),
              messages: val.messages
            }
            if (submitOnEnter()) {
              ret[field].onKeyDown = e => {
                if (e.keyCode === 13 && e.target.nodeName === 'INPUT') {
                  this.submit()
                }
              }
            }
          })}
          formStatus={formStatus}
          submit={this.submit}
          updateField={this.updateField}
          //setFieldInvalid={this.setFieldInvalid}
          bulkUpdateFields={this.bulkUpdateFields}
          shouldFormSubmit={this.shouldFormSubmit}
          getFormItem={this.getFormItem}
          validateFields={() =>
            this.setState({
              fields: fieldsWithMessages(this.props, touchAllFields(fields), serverMessages)
            })
          }
          resetForm={this.resetForm}
          setValidationMessages={this.setValidationMessages}
        />
      )
    }
  }

  Form.propTypes = propTypes

  return Form
}

const copy = goToCopy('shared.validations')

export const isRequired = (label, test = () => false, options = {}) => ({ field }) =>
  field.touched &&
  (test(field) ||
    ((!field.value && field.value !== 0 && field.value !== false) ||
      (isArray(field.value) && !field.value.length)))
    ? {
        message: options.customMessage ? label : copy('isRequired', { label }),
        preventSubmit: true
      }
    : false

export const isValid = (label, test = () => false, options = {}) => ({ field }) =>
  !test(field)
    ? {
        message: options.customMessage ? label : copy('isRequired', { label }),
        preventSubmit: true
      }
    : false

export const isRequiredAssignment = label => ({ field }) =>
  field.touched && !(field.value || []).length
    ? {
        message: copy('isRequiredAssignment', { label }),
        preventSubmit: true
      }
    : false

export const isEmail = label => ({ field }) =>
  field.touched && field.value && !field.value.match(/.+@.+/i)
    ? {
        message: copy('isEmail', { label }),
        preventSubmit: true
      }
    : false

export const isNumber = label => ({ field }) =>
  field.touched && field.value && !isFinite(+field.value)
    ? {
        message: copy('isNumber', { label }),
        preventSubmit: true
      }
    : false

export const isFormattedDate = label => ({ field }) =>
  field.touched && field.value && !isValidDateFormat(field.value)
    ? {
        message: copy('isFormattedDate', { label, dateFormat: getDateFormat() }),
        preventSubmit: true
      }
    : false

export const serverMessage = (key, exact) => ({ field, serverMessages }) => {
  const message = serverMessages.find(err =>
    exact ? err.key === key : err.key && err.key.includes(key)
  )
  return message
    ? {
        message: message.message,
        preventSubmit: false
      }
    : false
}

export default FormHoc
