import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import pubsub from 'sweet-pubsub'
import ListPage from 'shared/higher-order-components/ListPage'
import Intl from 'shared/higher-order-components/Intl'
import {
  Page,
  Table,
  Input,
  Select,
  Button,
  OrderChevron,
  Pagination,
  TableActionIcon
} from 'shared/components'
import EmailGatePassModal from './modals/EmailGatePassModal'
import BookingsDocumentModal from './modals/BookingsDocumentModal'
import * as actions from '../../state/bookings/bookingActions'
import { bookingStatuses } from '../../state/bookings/resource'

class BookingsList extends PureComponent {
  componentDidMount() {
    const { searchBookingsByEntity, orgId } = this.props
    searchBookingsByEntity(orgId, { perPage: this.perPage })
  }

  publishEmailGatePass = booking => {
    pubsub.emit('portal/bookings/email/gatepass/open', booking)
  }

  get perPage() {
    return 25
  }

  search(options = {}) {
    const { searchBookingsByEntity, orgId } = this.props
    searchBookingsByEntity(orgId, {
      perPage: this.perPage,
      term: this.$termInput.value || '',
      order: this.$order || '',
      type: this.$dateTypeSelect.value || '',
      ...options
    })
  }

  render() {
    const {
      copy,
      orgId,
      listItems,
      searchBookingsByEntity,
      printGatePass,
      pagination,
      query,
      fetching
    } = this.props

    const chevron = field => (
      <OrderChevron
        field={field}
        current={query.order}
        update={order => searchBookingsByEntity(orgId, { order })}
      />
    )

    const headers = [
      { text: copy.get('list.customer') },
      { text: copy.get('list.facility') },
      { text: copy.get('list.reference') },
      { text: copy.get('list.transactions') },
      { text: copy.get('list.starttime'), content: chevron('start') },
      { text: copy.get('list.duration') },
      { text: copy.get('list.status') },
      { width: 2 },
      { width: 2 },
      { width: 2 }
    ]

    const rows = listItems.map((booking, index) => [
      { text: booking.customer },
      { text: booking.facility_name },
      { text: booking.reference },
      { text: booking.consignmentsCount },
      { text: booking.begin },
      {
        text: booking.display.duration(),
        textClass:
          // eslint-disable-next-line no-nested-ternary
          booking.status === 'out_facility'
            ? booking.display.isActualGreaterThanPlanned()
              ? 'danger'
              : 'success'
            : null
      },
      { text: copy.get(`statuses.${booking.status}`) },
      {
        className: 'is-single-icon',
        content: ['out_facility'].includes(booking.status) ? <BookingsDocumentModal /> : null
      },
      {
        className: 'is-single-icon',
        content: (
          <TableActionIcon
            icon="print"
            rowId={`print_${index}`}
            onClick={() => printGatePass(booking.customer_id, booking.facility_id, booking.id)}
            dataTip={copy.get('printGatePass')}
          />
        )
      },
      {
        className: 'is-single-icon',
        content: (
          <TableActionIcon
            icon="mail_outline"
            rowId={`mail_${index}`}
            onClick={() => this.publishEmailGatePass(booking)}
            dataTip={copy.get('email.name')}
          />
        )
      }
    ])

    const filter = () =>
      searchBookingsByEntity(orgId, {
        perPage: this.perPage,
        term: this.$termInput.value || '',
        status: (this.$filterStatus && this.$filterStatus.value) || ''
      })

    const renderSearchOpt = () => {
      return (
        <>
          <Input
            reff={el => (this.$termInput = el)}
            styleType="box"
            name="filter"
            placeholder={copy.get('term')}
            defaultValue={query.term}
            style={{ width: 300 }}
          />

          <Select
            reff={el => (this.$filterStatus = el)}
            styleType="box"
            placeholder={copy.get('allStatuses')}
            options={bookingStatuses}
            defaultValue={query.status}
            style={{ width: 170 }}
          />

          <Button
            className="listTable_top-bar-filter"
            onClick={filter}
            style={{ marginRight: '0px', width: 140 }}
          >
            {copy.get('search')}
          </Button>

          <Button className="listTable_top-bar-create" iconLeft="plus" disabled>
            {copy.get('addNewBooking')}
          </Button>
        </>
      )
    }

    return (
      <Page title={copy.get('bookings')}>
        <div className="listTable">
          <div className="listTable_top-bar">{renderSearchOpt()}</div>
          <Table headers={headers} rows={rows} isLoading={fetching} />
          <Pagination
            update={page => searchBookingsByEntity(orgId, { page })}
            page={query.page}
            total={pagination.total}
            perPage={this.perPage}
          />
        </div>
        <EmailGatePassModal orgId={orgId} />
      </Page>
    )
  }
}

BookingsList.propTypes = {
  query: PropTypes.object.isRequired,
  copy: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  searchBookingsByEntity: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  listItems: PropTypes.array.isRequired
}

BookingsList = ListPage()(BookingsList)

const mapStateToProps = ({ shared }, { params, location, match }) => ({
  query: location.search,
  ...params,
  ...shared.list,
  ...match.params
})
const mapDispatchToProps = actions

BookingsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingsList)

export default Intl({
  get: 'portal.bookings'
})(BookingsList)
