import React from 'react'
import PropTypes from 'prop-types'

class LoadingDots extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = { frame: 1 }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        frame: this.state.frame + 1
      })
    }, this.props.interval)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { dots, interval, ...props } = this.props

    let d = this.state.frame % (dots + 1)
    let text = ''
    while (d > 0) {
      text += '.'
      d--
    }
    return <span {...props}> {text}</span>
  }
}

LoadingDots.defaultProps = {
  interval: 300,
  dots: 3
}

LoadingDots.propTypes = {
  interval: PropTypes.number,
  dots: PropTypes.number
}

export { LoadingDots }
