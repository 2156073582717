/* global google */

import React, { Component, PropTypes } from 'react'
import { withGoogleMap, GoogleMap, withScriptjs } from 'react-google-maps'
import GoogleMapsApiLoader from 'shared/higher-order-components/GoogleMapsApiLoader'
import Spinner from 'shared/components/Spinner'
import { compose, withProps } from 'recompose'
import pubsub from 'sweet-pubsub'
import { isArray, isFunction } from 'lodash'
import { GOOGLE_MAP_URL, ZA_DEFAULT_CENTER } from './MapConstants'

window.gmClientCb = () => pubsub.emit('gmClientLoaded')

const DEFERRED_PROPS = ['animation', 'defaultAnimation', 'controlPosition']

const cloneElement = element => {
  if (!element) return
  const { props } = element
  return React.cloneElement(
    element,
    {
      ...props,
      ...unwrapDeferredProps(props)
    },
    element.props.children
  )
}
const mapChildren = children => React.Children.map(children, element => cloneElement(element))

const unwrap = func => (isFunction(func) ? func() : func)
const unwrapDeferredProps = props =>
  DEFERRED_PROPS.reduce((ret, value, index) => {
    if (props[value]) ret[value] = unwrap(props[value])
    return ret
  }, {})

export default compose(
  withProps({
    //googleMapURL: GOOGLE_MAP_URL,
    //loadingElement: <div style={{ height: `100%` }}><Spinner size={50} center={50} /></div>
    mapElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />
  }),
  GoogleMapsApiLoader,
  //withScriptjs,
  withGoogleMap
)(
  ({
    children,
    onMapLoad,
    center = ZA_DEFAULT_CENTER,
    defaultCenter = ZA_DEFAULT_CENTER,
    defaultZoom = 4,
    searchBox = false,
    tilt = 0,
    defaultTilt = 0,
    options = {},
    ...mapProps
  }) => {
    return (
      <GoogleMap
        ref={onMapLoad}
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
        defaultTilt={defaultTilt}
        tilt={tilt}
        options={{
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
          },
          ...options
        }}
        {...mapProps}
      >
        {mapChildren(children)}
      </GoogleMap>
    )
  }
)
