import { flattenDeep } from 'lodash'

export const toApi = item => ({
  ...item
})

export const toClient = item => ({
  ...item.data
})

export const userApi = {
  user(item) {
    return {
      username: item.username,
      identity_number: item.identity_number,
      email: item.email,
      numbers: {
        mobile: item.mobile_number,
        tel: '',
        work: ''
      },
      names: {
        first: item.first_name,
        last: item.last_name,
        middle: '',
        preferred: '',
        user_name: item.email
      }
    }
  },
  userFacilities(facilities) {
    const ids = flattenDeep(Object.keys(facilities).map(item => facilities[item].map(i => i.id)))

    return { facility_ids: ids }
  }
}
