import { combineReducers } from 'redux'

export const portal = (
  state = {
    portalLoaded: false
  },
  action
) => {
  switch (action.type) {
    case 'app/FETCHED_PORTAL_CONFIG':
      return {
        ...state,
        ...action.portal,
        portalLoaded: true
      }
    default:
      return state
  }
}

export const user = (state = {}, action) => {
  switch (action.type) {
    case 'app/FETCHED_USER':
      return action.user
    default:
      return state
  }
}

export const meta = (
  state = {
    languages: [],
    titles: [],
    genders: [],
    countries: [],
    currencies: [],
    mobilePrefixes: [],
    timeZones: []
  },
  action
) => {
  switch (action.type) {
    case 'app/FETCHED_META':
      return {
        ...action.meta,
        mobilePrefixes: action.meta.mobile_prefixes,
        timeZones: action.meta.time_zones
      }
    default:
      return state
  }
}

export const page = (
  state = {
    title: 'Trackmatic Supplier Portal'
  },
  action
) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return { ...state, title: 'Trackmatic' }
    case 'SET_PAGE_TITLE':
      return { ...state, title: `Trackmatic :: ${action.title}` }
    default:
      return state
  }
}

export default combineReducers({
  portal,
  user,
  meta,
  page
})
