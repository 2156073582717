import React, { Component } from 'react'
import { throttle } from 'lodash'

export default () => Child =>
  class ListPage extends Component {
    constructor(props) {
      super(props)
      this.state = { tableHeight: 400 }
      this.updateTableHeight = this.updateTableHeight.bind(this)
    }

    componentDidMount() {
      this.updateTableHeight()
      this.updateTableHeight = throttle(this.updateTableHeight, 200)
      window.addEventListener('resize', this.updateTableHeight)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateTableHeight)
    }

    updateTableHeight() {
      const getHeight = el => (document.querySelector(el) || {}).offsetHeight || 0
      this.setState({
        // 'padding' at the bottom of the page
        tableHeight:
          document.documentElement.clientHeight -
          getHeight('.Nav_nav__1RpOE') -
          getHeight('.Page_page_nav__1PUBi') -
          getHeight('.listTable_top-bar') -
          getHeight('.table_head') -
          getHeight('.pagination') -
          20
      })
    }

    render() {
      const { tableHeight } = this.state
      return <Child {...this.props} tableHeight={tableHeight} />
    }
  }
