import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Intl from 'shared/higher-order-components/Intl'
import { orderBy, sortBy, some, uniqueId, find, remove, merge, get } from 'lodash'

const propTypes = {
  list: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

class OrderedList extends Component {
  componentWillReceiveProps(props) {
    const { placeholder, list } = props

    this.setState(
      prev => ({
        list: list,
        listBefore: [],
        listAfter: [],
        placeholder: placeholder,
        isSingleList: !placeholder,
        isDoubleList: !!placeholder
      }),
      () => {
        this.indexList(this.state.list)
        this.split()
      }
    )
  }

  indexList = list => {
    // ensure unique keys exist
    some(list) &&
      list.map(item => {
        if (!item['uid']) {
          item.uid = uniqueId()
        }
      })
  }

  split = () => {
    const { list, isDoubleList } = this.state
    if (isDoubleList) {
      //split list into before/after
      this.setState(prevState => ({
        listBefore: this.reposition(list.filter(x => x.pos < 0), '-')
      }))
      this.setState(prevState => ({ listAfter: this.reposition(list.filter(x => x.pos > 0), '+') }))
    } else {
      this.setState(prevState => ({ list: this.reposition(list, '+') }))
    }
  }
  reposition = (list, sign) => {
    if (sign === '+') {
      return orderBy(list, 'pos', 'asc').map((item, id) => {
        return { ...item, pos: id + 1 }
      })
    } else {
      return orderBy(
        orderBy(list, 'pos', 'desc').map((item, id) => {
          return { ...item, pos: id * -1 - 1 }
        }),
        'pos',
        'asc'
      )
    }
  }

  up = (list, pos) => {
    let prevItem = find(list, o => {
      return o.pos === pos - 1
    })
    let currItem = find(list, o => {
      return o.pos === pos
    })
    prevItem.pos++
    currItem.pos--
  }

  down = (list, pos) => {
    let nextItem = find(list, o => {
      return o.pos === pos + 1
    })
    let currItem = find(list, o => {
      return o.pos === pos
    })
    nextItem.pos--
    currItem.pos++
  }

  moveItemUp = item => {
    const { isSingleList } = this.state

    if (isSingleList) {
      const { list } = this.state
      let pos = item.pos
      if (pos > 1) {
        this.up(list, pos)
      }
      this.setState(
        prev => ({ list }),
        () => {
          this.onUpdate()
        }
      )
    } else {
      let pos = item.pos
      const { listBefore, listAfter } = this.state

      if (pos > 1) {
        this.up(listAfter, pos)
      } else if (pos === 1) {
        //shift other -'s further away from 0
        listBefore.map(o => o.pos--)
        //move item into before list (into -1)
        item.pos = -1
        listBefore.push(item)
        remove(listAfter, o => o.uid === item.uid)
        //shift positives -1
        listAfter.map(o => o.pos--)
      } else {
        if (pos > this.minPosition(listBefore)) {
          this.up(listBefore, pos)
        }
      }

      this.reposition(listBefore, '-')
      this.reposition(listAfter, '+')
      this.setState(
        prev => ({ listBefore, listAfter }),
        () => {
          this.onUpdate()
        }
      )
    }
  }

  moveItemDown = item => {
    const { isSingleList } = this.state

    if (isSingleList) {
      let list = this.state.list
      let pos = item.pos
      let last = this.maxPosition(list)
      if (pos < last) {
        let nextItem = find(list, o => {
          return o.pos === pos + 1
        })
        let currItem = find(list, o => {
          return o.pos === pos
        })
        nextItem.pos--
        currItem.pos++
      }
      this.setState(
        prev => ({ list }),
        () => {
          this.onUpdate()
        }
      )
    } else {
      let pos = item.pos
      const { listBefore, listAfter } = this.state
      if (pos < -1) {
        this.down(listBefore, pos)
      } else if (pos === -1) {
        //shift other +'s further away from 0
        listAfter.map(o => o.pos++)
        //move item into after list (into +1)
        item.pos = +1
        listAfter.push(item)
        remove(listBefore, o => o.uid === item.uid)
        //shift negatives -1
        listBefore.map(o => o.pos++)
      } else {
        if (pos < this.maxPosition(listAfter)) {
          this.down(listAfter, pos)
        }
      }

      this.reposition(listBefore, '-')
      this.reposition(listAfter, '+')
      this.setState(
        prev => ({ listBefore, listAfter }),
        () => {
          this.onUpdate()
        }
      )
    }
  }

  deleteItem = item => {
    const { isSingleList } = this.state
    if (isSingleList) {
      let list = this.state.list
      remove(list, o => o.uid === item.uid)
      list = this.reposition(list, '+')
      this.setState(
        prev => ({ list }),
        () => {
          this.onUpdate()
        }
      )
    } else {
      let isBeforeList = item.pos < 0
      let list = isBeforeList ? this.state.listBefore : this.state.listAfter
      remove(list, o => o.uid === item.uid)
      list = this.reposition(list, isBeforeList ? '-' : '+')
      this.setState(
        prev => ({ [isBeforeList ? 'listBefore' : 'listAfter']: list }),
        () => {
          this.onUpdate()
        }
      )
    }
  }

  minPosition(list) {
    return list.reduce((prev, cur) => (prev < cur.pos ? prev : cur.pos), 100)
  }
  maxPosition(list) {
    return list.reduce((prev, cur) => (prev > cur.pos ? prev : cur.pos), -100)
  }

  onUpdate = () => {
    const { onChange, group } = this.props

    const { isSingleList, listBefore, listAfter } = this.state
    let { list } = this.state

    if (isSingleList) {
      onChange(list, group)
    } else {
      list = listBefore.concat(listAfter)
      this.setState(
        prev => ({ list }),
        () => {
          onChange(list, group)
        }
      )
    }
  }

  renderPlaceholder = () => {
    return (
      <div className="ordered-list">
        <ul key className="ordered-list_container">
          <li className="ordered-list_container_placeholder">
            <div className="ordered-list_container_placeholder_name">{this.state.placeholder}</div>
          </li>
        </ul>
      </div>
    )
  }

  renderListItems = list => {
    return sortBy(list, ['pos']).map(item => (
      <li key={item.uid} className="ordered-list_container_item">
        <div className="ordered-list_container_order">
          <i className="i-chevron-up" onClick={() => this.moveItemUp(item)} />
          <i className="i-chevron-down" onClick={() => this.moveItemDown(item)} />
        </div>
        <div className="ordered-list_container_item_name">{item.value}</div>
        <div className="ordered-list_container_controls">
          <i className="i-trash" onClick={() => this.deleteItem(item)} />
        </div>
      </li>
    ))
  }

  renderList = (list, title) => {
    return some(list) ? (
      <div className="ordered-list">
        <ul key={title + 'Column'} className="ordered-list_container">
          {title && <li className="ordered-list_container_title">{title}</li>}
          {this.renderListItems(list)}
        </ul>
      </div>
    ) : null
  }

  render() {
    const { placeholder } = this.props
    const { list, listBefore, listAfter, isSingleList, isDoubleList } = this.state || []
    return some(list) ? (
      <div>
        {isSingleList ? this.renderList(list, '') : null}
        {isDoubleList ? this.renderList(listBefore, 'Before') : null}
        {placeholder && this.renderPlaceholder()}
        {isDoubleList ? this.renderList(listAfter, 'After') : null}
      </div>
    ) : null
  }
}

OrderedList.propTypes = propTypes

OrderedList = Intl()(OrderedList)

export { OrderedList }
