import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { emit, forOwn } from 'lodash'

const propTypes = {
  google: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  center: PropTypes.array,
  radius: PropTypes.number,
  onClick: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseOver: PropTypes.func
}

const defaultProps = {
  style: {
    strokeColor: '#FF0000',
    strokeOpacity: 1.0,
    strokeWeight: 2
  },
  onClick: function () { },
  onMouseOut: function () { },
  onMouseOver: function () { }
}

const eventMap = {
  onClick: "click",
  onMouseOut: "mouseout",
  onMouseOver: "mouseover",
  onContextMenu: "rightclick"
}

class MapCircle extends Component {

  constructor(props) {
    super(props)

    const {
      google,
      map,
      style,
      radius
    } = props

    const marker = new google.maps.Circle({
      map,
      center: this.getPositions(),
      radius: radius,
      ...style
    })

    this.$marker = marker
  }

  componentDidMount() {
    forOwn(eventMap, (value, key) => {
      this.$marker.addListener(value, this.props[key])
    })
  }

  componentDidUpdate = () => {
    this.$marker.setOptions(this.props.style)
    this.$marker.setCenter(this.getPositions())
    this.$marker.setRadius(this.props.radius)
  }

  componentWillUnmount = () => {
    if (!this.$marker) {
      return
    }

    const {
      google
    } = this.props

    forOwn(eventMap, (value, key) => {
      google.maps.event.clearListeners(this.$marker, value)
    })

    this.$marker.setMap(null)
    this.$marker = null
  }

  getPositions = () => {
    const {
      center
    } = this.props
    return { lng: center[0], lat: center[1] }
  }

  render() {
    return null
  }

}

MapCircle.propTypes = propTypes
MapCircle.defaultProps = defaultProps

export default MapCircle