/* eslint-disable camelcase */
import api from 'shared/api/index'
import { push } from 'connected-react-router'
import { storeAccessRequestData } from 'shared/state/actions'
import { toClient, toApi } from './resource'

const path = 'link_accounts/suppliers/access_request'

export const requestAccess = token => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  api.get(`${path}/onboard?tokenId=${token}`).then(
    ({ data }) => {
      dispatch({
        type: 'shared/form/SUBMITTED'
      })

      if (!data) {
        return dispatch(push('/no-memberships'))
      }

      const accessData = data
      const isVerified =
        accessData.status === 'completed' &&
        accessData.username &&
        accessData.password &&
        accessData.key_exists &&
        accessData.key_verified

      if (accessData.status === 'cancelled') {
        return dispatch(push('/no-memberships'))
      }

      if (
        accessData.status === 'completed' &&
        accessData.key_exists &&
        accessData.key_verified &&
        !accessData.username &&
        !accessData.password
      ) {
        dispatch(push('/complete'))
      }

      if (isVerified) {
        dispatch(push('/login'))
      }

      dispatch({
        type: 'account/access/REGISTER_FETCH_SUCCESS',
        data: accessData
      })
    },
    ({ errors }) => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: errors
      })
    }
  )
}

export const requestKey = (fields, id) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  return api.put(`${path}/${id}/request_key`, toApi(fields)).then(
    data => {
      const clientData = toClient(data)
      dispatch(storeAccessRequestData(clientData, `/register/${clientData.token}`))
      dispatch({
        type: 'shared/form/SUBMITTED'
      })
      return clientData
    },
    ({ errors }) => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: errors
      })
    }
  )
}

export const verifyKey = (id, mobile) => dispatch => {
  dispatch({ type: 'shared/form/SUBMITTING' })
  api.get(`${path}/${id}/verify_key?key=${mobile}`).then(
    () => {
      dispatch(push('/complete'))
    },
    ({ errors }) => {
      dispatch({ type: 'shared/form/SUBMITTED', messages: errors })
    }
  )
}

export const complete = (
  { verifyPassword, ...fields },
  { id, token, key_exists, key_verified, ...rest }
) => dispatch => {
  dispatch({
    type: 'shared/form/SUBMITTING'
  })

  const temp = { ...rest, ...fields }

  api.put(`${path}/complete/${id}`, temp).then(
    data => {
      console.log('complete call', data)
      dispatch(storeAccessRequestData(toClient(data), '/login'))
    },
    ({ errors }) => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: errors
      })
    }
  )
}
