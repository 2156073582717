import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const propTypes = {
  className: PropTypes.string,
  trueLabel: PropTypes.string.isRequired,
  falseLabel: PropTypes.string.isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  reff: PropTypes.func,
  onChange: PropTypes.func
}

class Toggle extends Component {
  constructor(props) {
    super(props)
    this.state = { value: !!(props.value || props.defaultValue) }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    const { value: val } = this.state
    const { value = val, onChange } = this.props
    this.setState({ value: !value })
    if (onChange) onChange(!value)
  }

  render() {
    const { value: val } = this.state
    const { className, trueLabel, falseLabel, value = val, reff } = this.props

    return (
      <div
        onClick={this.toggle}
        className={classnames('toggle', className, `is-toggled-${!!value}`)}
        ref={el => {
          el = el || {}
          el.value = value
          if (reff) reff(el)
        }}
      >
        <div className="toggle_circle" />
        <div className="toggle_label">{value ? trueLabel : falseLabel}</div>
      </div>
    )
  }
}

Toggle.propTypes = propTypes

export { Toggle }
