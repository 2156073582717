import React from 'react'
import PropTypes from 'prop-types'
import Intl from 'shared/higher-order-components/Intl'
import { Nav } from 'shared/components'
import App from 'app/containers/App'

const AccountModule = ({ children, ...props }) => {
  return (
    <App {...props}>
      <Nav currentModule="supplier-portal" />
      <span className="module-portal">{children}</span>
    </App>
  )
}

AccountModule.propTypes = {
  children: PropTypes.element.isRequired
}

export default Intl({ get: 'supplierPortal' })(AccountModule)
