/* eslint-disable no-underscore-dangle */
import { get } from 'lodash'
import storage from 'shared/lib/storage'
import api from './index'

let __token__ = null

export const getTokens = then => {
  api
    .get('account/auth')
    .then(({ data, res: { body: { invitations } } }) => then({ tokens: [data], invitations }))
    .catch(({ res: { status, body: { data, invitations } } }) => {
      if (status === 300) then({ tokens: data, invitations })
    })
}

export const getTokenForOrganisation = organisationId => {
  return api.get(`account/auth/${organisationId}`).then(({ res }) => {
    return res
  })
}

export const decodeTokenString = token => {
  const data = JSON.parse(new Buffer(token.split('.')[1], 'base64').toString()).identity

  const gt = (path, defaultVal) => get(data, path, defaultVal)

  return {
    id: gt('id'),
    identity: {
      id: gt('iden.id'),
      names: {
        first: gt('iden.nms.fi'),
        last: gt('iden.nms.la'),
        full: `${gt('iden.nms.fi')} ${gt('iden.nms.la')}`
      },
      username: gt('iden.un'),
      person_id: gt('iden.pid'),
      is_elevated: gt('iden.elv')
    },
    organisation: {
      id: gt('org.id'),
      name: gt('org.nm')
    },
    preferences: {
      regional_settings: {
        country: gt('pref.regs.co'),
        timezone: gt('pref.regs.tz'),
        currency_symbol: gt('pref.regs.cur'),
        date_format: gt('pref.regs.df'),
        time_format: gt('pref.regs.tf'),
        mobile_prefix: gt('pref.regs.mp'),
        language: gt('pref.regs.lng')
      }
    },
    modules: gt('mod', []).map(module => ({
      id: module.id,
      roles: module.ro.map(index => gt('ro', [])[index]),
      sites: module.si.map(index => gt('si', [])[index])
    })),
    roles: gt('ro', []),
    sites: gt('si', [])
  }
}

/**
 * Stores the token in local storage and sets the in memory pointer to the token.
 * The token is stored in local storage so that the system can re-populate the token if the page is refreshed.
 * @param {The JWT token string} data
 */
export const storeToken = data => {
  storage.local.set('tokenString', data)
  __token__ = data
}

/**
 * Used when logging out to remove references to the current token
 */
export const deleteToken = () => {
  storage.local.remove('tokenString')
  __token__ = null
}

/**
 * If the in memory pointer is null then it needs to retrieved from local storage.
 * If the token in local storage does not exist then it needs to request a new token from the api.
 * @param {The callback which receives the token} callback
 */
export const getToken = callback => {
  if (!__token__) {
    __token__ = storage.local.get('tokenString')
  }
  return callback(__token__)
}
