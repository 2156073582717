import React, { Component } from 'react'
import { isFunction } from 'lodash'

const DefaultWhen = ({ useDefaultWhen = () => false, defaultValue = () => null }) => Child => {
  return class Default extends Component {
    renderDefaultValue = () => {
      return isFunction(defaultValue) ? defaultValue(this.props) : defaultValue
    }

    render() {
      return useDefaultWhen(this.props) ? this.renderDefaultValue() : <Child {...this.props} />
    }
  }
}

export default DefaultWhen
